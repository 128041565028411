export const locale = {
    lang: 'en',
    data: {
        'FIELDS': {
            'number': 'Number',
            'text': 'Text',
            'm2200': '0-200 m2',
            'm2201': '201-500 m2',
            'm2500': '> 500 m2',
            'appendix': 'Appendix',
            'm2': 'M2',
            'lb15CmH': '15 Cm H',
            'lb30CmI': '30 Cm I',
            'lb40CmK': '40 Cm K',
            'lb60CmL': '60 Cm L',
            'stk10': '0-10 stk.',
            'stk20': '11-20 stk.',
            'stk21': '> 21 stk.',
            'TreatmentType':'Treatment Type',
            'Min': 'Min',
            'indoorOutdoor': 'Interior / Exterior',
            'lbM2A': 'M2 A',
            'lbM2B': 'M2 B',
            'lbM2C': 'M2 C',
            'lbRammerD': '0-30 Cm D',
            'lbRuderE': '31-60 Cm E',
            'lbFalsF': 'Fals F',
            'lbKantG': 'Kant G ',
            'lbIndVangeM': 'Ind. vange + stødtr på udv.',
        },
       'BUTTONS':{
            'Add':'Add',
            'BTNSAVE': 'Save',
            'BACK':'Back'
        },
        'MESSAGES': {
            'NORECORD': 'No Record Found',
            'NO_REMINDER_RECORD': 'No Reminder Found',
            'NO_PROJECT_RECORD': 'No Project Found',
            'Created': 'Created successfully.',
            'Updated': 'Updated successfully.',
            'Deleted': 'Deleted successfully.',
            'Failed': 'Something went wrong !',
        },
    }
};