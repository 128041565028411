export const locale = {
    lang: 'ds',
    data: {
        'FILTERS': {
            'Scan': 'Scan',
            'All': 'Alle',
            "Materialtype": 'Vare type',
        },
        'FIELDS': {
            'Supplier': 'Leverandør',
            'None': 'Ingen',
            'Material': 'Materialer',
            'Name': 'Navn',
            'SupplierName': 'Leverandør navn',
            'ColorCode':'Farve kode',
            'HoldingsQuantity': 'Beholdning Antal',
            'SRNO': 'Sr nr.',
            "Delete": 'Slet',
            'Remark': 'Remark',
            'Not-Found-Material': 'Ingen materiale fundet',
            "Materialtype": 'Vare type',
            'All': 'Alle',
            'Scan': 'Scan stregkode',
        },
        'BUTTONS': {
            'Add': 'Tilføj',
            'BACK': 'Tilbage',
            'Ok': 'Gem',
        },
        'MESSAGES': {
            'Barcode': 'Stregkode findes allerede.',
            'NORECORD': 'Ingen materiale fundet',
            'NoBarcodeAttachedToMaterial': 'Ingen stregkode knyttet til noget materiale.',
        },
    }
};