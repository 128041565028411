import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { CustomersService } from "../../customer.service"
import { CustomerRoom } from 'app/main/model/ProjectOffer';
import { RequestCustomerProperty } from 'app/main/model/RequestBase';
import { NotificationsService } from 'angular2-notifications';
import { CommonService } from 'app/core/services/common.service';
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
    selector: 'vibe-customerProperties',
    templateUrl: './customerProperties.component.html',
})
export class CustomerPropertiesComponent implements OnInit {
    @Input() CustomerInput: any;

    showSpinner = false;
    CustomerID: string = '';
    CustomerProperties: CustomerRoom[] = [];
    requestCustomerProperty: RequestCustomerProperty = new RequestCustomerProperty();
    RemoveRoomByIndex: number;
    displayPagination: boolean;
    pagination: any;
    UpdateCustomerPropertyObj: any = null;
    ShowPropertyList: boolean;
    selectedIndex: number = 0;

    //Initiators
    constructor(
        private translationLoader: FuseTranslationLoaderService,
        @Optional() public dialogRef: MatDialogRef<CustomerPropertiesComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
        this.CustomerID = this.CustomerInput;
        if (this.data && this.data.customerId) 
            this.CustomerID = this.data.customerId;
        if(this.CustomerID) {
            this.ShowPropertyList=true;
        }
    }

    onGoPropertyList(){
        this.ShowPropertyList = !this.ShowPropertyList;
        this.UpdateCustomerPropertyObj = null;
    }

    UpdateCustomerProperty(obj) {
        this.ShowPropertyList = !this.ShowPropertyList;
        this.UpdateCustomerPropertyObj = obj;
    }

    TabChange(event) {
        this.selectedIndex = event.index;
    }

    close(){
        this.dialogRef.close(true);
    }

}
