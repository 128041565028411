import { Component, Input, OnInit } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { SettingsService } from '../../settings.service';
import { ResponseBase } from "app/main/model/ResponseBase";
import { Settings } from "app/main/model/Settings";

@Component({
    selector: "settings-project-awaited-status-aconto",
    templateUrl: "./project-awaited-status-aconto.component.html",
    styleUrls: ["./project-awaited-status-aconto.component.scss"]
})

export class ProjectAwaitedStatusAcontoSettingsComponent implements OnInit {
    showSpinner: boolean = false;
    @Input() projectSettings: any;
    Price: number = 0;
    PriceObj: Settings;
    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private settingsService: SettingsService) {
        this.translationLoaderService.loadTranslations(danish, english);
    }

    ngOnInit() {
        if (this.projectSettings && this.projectSettings.length > 0) {
            var tempSettingObject = this.projectSettings.find(s => s.key1 == "Project" && s.key2 == "ProjectStatusAwaitedAmountBigAskForAconto");
            if (tempSettingObject) {
                this.PriceObj = tempSettingObject;
                this.Price = Number(tempSettingObject.value);
            }
        }
        else
            this.getSettingsForCoworkerEconomyDetails();
    }

    getSettingsForCoworkerEconomyDetails() {
        this.showSpinner = true;
        this.settingsService.GetSettingsValue<ResponseBase>("Project", "ProjectStatusAwaitedAmountBigAskForAconto",null).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.result && response.result.myResponse.length > 0) {
                    this.PriceObj = response.result.myResponse[0];
                    this.Price = Number(response.result.myResponse[0].value);
                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    changeSettingsForCoworkerEconomyDetails() {
        this.showSpinner = true;
        this.PriceObj.value = this.Price ? this.Price.toString() : "";
        this.settingsService.UpdateSettingValue<ResponseBase>(this.PriceObj).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.result) 
                    this.PriceObj = response.result;
            },
            error: err => {
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }
}