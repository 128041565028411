import { Component, OnInit, Input, ViewChild, } from "@angular/core";
import { MatSort, MatTableDataSource } from "@angular/material";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { RequestCoWorker, RequestForSoldMaterialStock } from 'app/main/model/RequestBase';
import { MaterialService } from "app/main/pages/material/material.service";
import { CoWorkersService } from "app/main/pages/coWorker/coWorkers.service";
import { ResponseBase } from 'app/main/model/ResponseBase';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { ActivatedRoute } from "@angular/router";
import * as moment from "moment";

@Component({
    selector: 'vibe-materials-sold-stock',
    templateUrl: './materials-sold-stock.component.html',
    styleUrls: ['./materials-sold-stock.component.scss']
})

export class MaterialsSoldStockComponent implements OnInit {
    @Input() MaterialNumber: string;
    totalPrice: number = 0;
    totalQTY: number = 0;
    selectedCoworkerID: string = "0";
    searchStartDate: Date = new Date(new Date().getFullYear() + "-01-01");
    searchEndDate: Date = new Date(new Date().getFullYear() + "-12-31");
    reqModel: RequestForSoldMaterialStock = new RequestForSoldMaterialStock();
    showSpinner = false;
    soldMaterialStockList = [];
    coWokersList = [];
    displayedColumns: string[] = ['index','materialNumber', 'materialPrice', 'materialQTY'];
    dataSource: MatTableDataSource<any>;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    emptyData = new MatTableDataSource([{ empty: "row" }]);
    constructor(private translationLoader: FuseTranslationLoaderService,
        private materialService: MaterialService,
        public coWorkersService: CoWorkersService,
        private _route: ActivatedRoute
    ) {
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
        this.GetProjectsMaterialHistory();
    }
    ngAfterViewInit() {
    }

    GetProjectsMaterialHistory() {
        this.showSpinner = true;
        this.reqModel.IsActive = true;
        this.reqModel.IsDeleted = false;
        this.reqModel.Index = 1;
        this.reqModel.Limit = 100;
        this.reqModel.StartDate = moment(this.searchStartDate).format("YYYY-MM-DD");
        this.reqModel.EndDate = moment(this.searchEndDate).format("YYYY-MM-DD");
        this.reqModel.OrderBy = "";
        this.materialService.GetMaterialSoldStock<ResponseBase>(this.reqModel)
            .subscribe((response: ResponseBase) => {
                if (response != null) {
                    this.soldMaterialStockList = response.result;
                    this.dataSource = new MatTableDataSource(this.soldMaterialStockList);
                    setTimeout(() => {
                        this.dataSource.sort = this.sort;
                    });
                }
            },
                error => (err) => {
                },
                () => {
                    this.showSpinner = false;
                });
    }
    onStartDateSelect() {
    }
}