export const locale = {
    lang: 'ds',
    data: {
        'PROJECT': {
            'TITLE': 'Projekt',
            'DESC': 'Hej Projekt!',
            'NewProject': 'Nyt Projekt',
        },
        'TITLE': {
            'ProjectPagination': 'Antal projekter pr. side',
        },
        'BUTTONS': {
            'BtnCancel': 'Gå tilbage',
            'BtnSave': 'Gem',
            'BtnRoute': 'Ruteplan',
            'BtnAdd': 'Tilføj',
            'BtnAddToAll': 'Tilføj til alle',
            'BtnAddNew': 'Ny opgave',
            'Back': 'Gå tilbage',
            'CREATE_FOLDER': 'Opret mappe',
            'DOWNLOAD': 'Hent',
            'DELETE': 'Slet',
            'CHANGESTATUS': 'Skift status',
            'MOVETOFINISHFOLDER': 'Gå til mappen færdig',
            'LowInvoice': 'Lav Faktura',
            'BtnGoToPlanning': 'Gå til planlægning',
            'MoveMaterials': 'Flyt materialer',
            'CreateInvoice': 'Lav faktura',
            'AdvanceInvoice':'Aconto faktura'
        },
        'FIELDS': {
            'PickupLift': 'LEV. Lift',
            'DeliveryLift': 'AFH. Lift',
            'Caseno': 'Sag nummer',
            'Co-workerOnTheCase': 'Kolleger på sagen',
            'Status': 'Status',
            'Responsibleforsecurity': 'Ansvarlig for sikkerheden',
            'IndoorOutDoor': 'Ind, ud, Sprøjt, Reklamation *',
            'CustName': 'Kundenavn',
            'Material': 'Materiale',
            'Add': 'Adresse',
            'Address': 'Adresse',
            'Picture': 'Billede',
            'Zipcode': 'Postnummer',
            'Zipcode1': 'Post.',
            'Document/File': 'Upload Dokument / Fil',
            'City': 'By',
            'City2': 'By',
            'Price-included': 'Pris incl. moms',
            'Price-excluded': 'Pris excl. Moms',
            'Offer-Price-included': 'Tilbuds pris incl. moms',
            'Offer-Price-excluded': 'Tilbuds pris excl. moms',
            'Add2': 'Adresse 2',
            'VAT': 'Moms',
            'Text': 'Tekst*',
            'MaterialText': 'Vare Tekst',
            'MaterialTextColor': 'Tekst',
            'OpgaverText': 'Opgave Tekst',
            'OpgaveText': 'Rekvisitions Tekst',
            'InvoiceText': 'Faktura Tekst',
            'Price/Included': 'Pris incl. Moms',
            'Requisition': 'Rekvisition Nr.',
            'Estimate': 'Overslagspris',
            'Initialar': 'Initialer *',
            'startDate': 'Start dato ',
            'Size': 'Kvadratmeter',
            'EndDate': 'Slut dato ',
            'UploadFile': 'Upload Fil',
            'ChooseFile': 'Vælg fil',
            'ChoosePicture': 'Vælg billede',
            'Quantity': 'Antal',
            'PhoneNumber': 'Telefonnummer',
            'Selectcustomer': 'Vælg kunde påkrævet',
            'Active': 'Indstil som inaktiv',
            'PhoneNo': 'Telefon',
            'Not-Found-Worker': 'Medarbejder ikke fundet',
            'Not-Found-MATERIAL': 'Materiale ikke fundet',
            'Not-Found-CUSTOMER': 'Kunde ikke fundet',
            'Not-Found-QUANTITY': 'Mængde ikke fundet',
            'ResponsibleCoworker': 'Ansvarlig kollega',
            'Coworker': 'Medarbejdere',
            'Lift': 'Lift',
            'HowManyDaysleft': 'Hvor mange dage lift',
            'Stigemand': 'Stigemand',
            'HowManyDaysStigemand': 'Hvor mange dage stigemand',
            'CaseComingDate': 'Sag modtaget',
            'hoursOnCase': 'Timer til sagen',
            'noOfDays': 'Hvor mange dage',
            'Number': 'Sr.',
            'OpgaveNumber': 'Rekvisition Nr.',
            'Total': 'I alt',
            'DepartmentNumber': 'Afdelings nr',
            'DepartmentName': 'Navn',
            'DepartmentCity': 'By',
            'DepartmentZipCode': 'Post nr',
            'DepartmentAddress': 'Adresse',
            'DepartmentPhone': 'Telefon',
            'RequisitionNumber': 'Indtast Opgaver Nr',
            'RequisitionTask': 'Indtast Opgaver.',
            'RequisitionText': 'Indsæt Rekvisition tekst',
            'EnterAddress': 'Indtast Adresse',
            'EnterPhone': 'Indtast Telefon Nr.',
            'EnterZipCode': 'Indtast Postnummer',
            'EnterEmail': 'Indtast Email',
            'EnterCity': 'Indtast City',
            'SelectEndDate': 'Vælg Slutdato',
            'SelectStartDate': 'Vælg Startdato',
            'SelectIndoorOrOutdoor': 'Vælg en hvilken som helst påkrævet',
            'SelectCustomer': 'Vælg Kunde',
            'Week': 'Uge',
            'WorkingHours': 'Arbejdstid',
            'SattisticQuestion1': 'Årsag til sagen er droppet',
            'SattisticQuestion2': 'Hvor kommer kunden fra',
            'SattisticQuestion3': 'Hvem har sagen',
            'Email': 'Email',
            'UrgentOrNot': 'Haster/Haster Ikke',
            'StrictlySpeaking': 'Beset',
            'OfferNumber': 'Tilbuds nummer',
            'SelectYear': 'Vælg År',
            'visit': 'Besøg',
            'SecurityOfficer': 'Sikkerheds Ansvarlig',
            'EmployeeResponsible': 'Medarbejder Ansvarlig',
            'Unit': 'Enhed',
            'IncludeActive': 'Inkluder alle inaktive',
            'Timer': 'Timer',
            'HoursLeft': 'Timer tilbage',
            'RetailPrice': 'Udsalgs pris pr. stk',
            'CalculatePrice': 'Beregn pris',
            'TotalPrice': 'Pris i alt',
            'received': 'Modtaget',
            'TotalIncludedVAT': 'Pris incl. moms',
            'colorCode': 'Farve kode',
            'MaterialTotalPrice': 'Materiale I alt',
            'MaterialTotalPriceIncludeVAT': 'Materiale I alt incl. Moms',
            'PersonalPrice': 'Personale pris',
            'PersonalTotalPrice': 'Personal pris i alt',
            'MaterialPersonalTotalPrice': 'Materialer Personale pris i alt',
            'MaterialNo': 'Materiale nr.',
            'DoNotUpdateTimer': 'Opdatere Ikke Timer',
            'AddInActiveMaterials': 'Tilføj inaktive materialer',
            'ProfitSubProfit': 'Overskud / UnderSkud',
            'DateonCreate': 'Dato',
            'DeleteEveryone': 'Slet medarbejder på alle opgaver',
            'DeleteEmployee': 'Slet medarbejder på opgaver',
            'messagehoursOnCase': 'Indtast timer til sagen',
            'SuggestedHours': 'Forslag til timer',
            'IsHourlyWage': 'Er sag på timeløn',
            'HourlyWage': 'Timeløn',
            'RequiredLiftStartDate': 'Vælg StartDato',
            'StartDateRequired': 'Startdato krævet',
            'StartTimeRequired': 'Starttid krævet',
            'VistRequired': 'Besøg krævet',
            'VistTimeRequired': 'Besøgtid krævet',
            'PaymentMode': 'Betalingsmåde',
            'Discount': 'Rabat',
            'PriceExclVAT': 'Pris excl. moms',
            'Name': 'Navn',
            'MobileNo': 'Mobil nr.',
            'DonePercentage': 'Udført %',
            'Not-Found-Status': 'Status ikke fundet',
            'AmountIncludeVat': 'Pris incl. moms',
            'NoOfWorkflows': 'Antal arbejdsgange',
            'M2Walls': 'M2 Vægge',
            'M2Ceiling': 'M2 Loft',
            'Billed': 'Faktureret dato',
            'DoNotInvoice': 'Må ikke faktureres',
            'PurchasePrice': 'Indkøbs pris',
            'ShowMoreDetail': 'Vis flere detaljer',
            'User': 'Bruger',
            'MaterialCreation': 'Materialer Udgør',
            'CoworkerWorkingRate': 'Medarbejder arbejder sats',
            'Vat': 'Excl. Moms',
            'HourlyPayment': 'Sag på timeløn',
            'Price': 'Pris',
            'ValidEmail':'Indsæt gyldig e-mail adresse',
            'RequisitionNo': 'Indtast rekvisitionsnr',
            'IsSendInvoiceBeforeFinished': 'Sender faktura inden færdig',
            'AmountToGenerateInvoice': 'Aconto faktura skal være på kr.(Excl. moms)',
            'MoreAmountToGenerateInvoice':'Send aconto faktura når sag er over kr.(Excl. moms)',
        },
        'WeekDays': {
            'Monday': 'Man.',
            'Tuesday': 'Tirs.',
            'Wednesday': 'Ons.',
            'Thursday': 'Tors.',
            'Friday': 'Fre.',
            'Saturday': 'Lør.',
            'Sunday': 'Søn.'
        },
        'Headers': {
            'NewProject': 'Nyt projekt',
            'NoPictureSelected': 'Intet billede valgt',
            'NoFileSelected': 'Ingen fil valgt',
            'MaterialList': 'Materialeliste',
            'AddNewMaterial': 'Tilføj nyt materiale',
            'AddNewCo-worker': 'Tilføj ny medarbejder',
            'AddResponsibleCo-worker': 'Tilføj medarbejder',
            'Co-workerlist': 'Medarbejderliste',
            'ProjectfileList': 'Uploadede filer',
            'Noco-workeradded': 'Ingen medarbejder tilføjet',
            'Nomaterialadded': 'Ingen materialer',
            'DeleteAlert': 'Er du sikker du på vil slette?',
            'NoRequsitionAddedYet': 'Ingen opgaver tilføjet endnu',
            'RequisitionList': 'Opgaveliste',
            'NoCoworkerAddedYet': 'Ingen medarbejder tilføjet endnu',
            'Requisition': 'Opgave',
            'LogTabSRNO': 'SR NO',
            'LogTabRequisitionnumber': 'Opgavenummer',
            'LogTabChangedon': 'Dato',
            'LogTabChangedtime': 'Tid',
            'LogTabChangedfrom': 'Fra',
            'LogTabChangedto': 'Til',
            'LogTabChangedby': 'Ændret af',
            'LogTabCreatedby': 'Lavet af',
            'ChooseFile': 'Vælg fil',
            'FOLDEREMPTY': 'Denne folder er tom',
            'NoLogAvailable': 'Ingen log tilgængelig',
            'Desc': 'Tekst',
            'EconomyTabMessage': 'Fyld økonomi detaljer er dette tilfældet på timeløn eller fast pris? du flytter til økonomi detaljer?',
            'StatusAlertMessage': 'Are you shaur you want to change status',
            'DeleteMaterial': 'Vil du slette "@MaterialName"?',
            'DeleteRequisitionMessage': 'Vil du slette denne opgave?',
            'DeleteProject': 'Vil du slette denne Projekte?',
            'DeleteSelectedMaterial': 'Vil du slette udvalgte materialer?',
            'CanSendAcontoInvoice': 'Vil du sende en aconta faktura på dette project ?',
        },
        'Tabs': {
            'BasicDetail': 'Oplysninger',
            'Material': 'Materialer',
            'Co-worker': 'Medarbejdere',
            'Pricing': 'Pris på sagen',
            'Files': 'Filer',
            'SpecialInfo': 'Speciel info',
            'Status': 'Status',
            'Requisition': 'Opgave info',
            'Log': 'Log',
            'Statistic': 'Statistik',
            'Work-hourse': 'Arbejdstimer',
            'WeekWiseReport': 'Ugentlig raport',
            'YearWiseReport': 'Årsrapport',
            'Economy': 'Økonomi',
            'MtoM': 'M til M',
            'Note': 'Bemærk',
            'Offers': 'Tilbud',
            'MessageLog': 'Meddelelses log',
            'Rooms': 'Rum / Bygning',
            'QualityTest': 'Kvalitetstest',
            'Location':'Bookmark'
        },
        // 'selectedValueStatus': {
        //     //'value0': 'Anbefalet',
        //     'value0': '-',
        //     'value1': 'Process',
        //     'value2': 'Planlagt besøg',
        //     'value3': 'Klar til opstart',
        //     'value4': 'Tilbud sendt',
        //     'value5': 'Tilbud godkendt',
        //     'value6': 'Planlagt',
        //     'value7': 'Tilbud afvist',
        //     'value8': 'Startet',
        //     'value9': 'Udført',
        //     'value10': 'Faktureret',
        //     'value11': 'Afventer',
        //     'value12': 'Klar fakturering',
        // },
        'selectedValueIndoorOutdoorStatus': {
            'value1': 'Indendørs ',
            'value2': 'Udendørs',
            'value3': 'Sprøjtemaling',
            'value4': 'Reklamation',
            'value5': 'Personale køb', //drawing of painter
            'value6': 'Butiks køb'     //Nordsjø logo
        },
        'selectedOption': {
            'value1': 'Nej',
            'value2': 'Ja',
        },
        'reasonForProjectDropped': {
            'value0': 'Vælg venligst',
            //'value1': 'For dyrt',
            //'value2': 'For langsom til at svare',
            'value1': 'valgfri tekst',
            'value2': 'For dyr',
            'value3': 'Ikke svaret hurtigt nok',
            'value4': 'manglende tid'
        },
        'whereDoesTheCustomerComeFrom': {
            'value1': 'WWW (Internet)',
            'value2': 'Hjemmeside',
            'value3': 'Facebook',
            'value4': 'Anbefaling',
            'value5': 'Fast kunde',
            'value6': 'Manuel',
            'value7': 'Telefon',
        },
        'UrgentOrNot': {
            'value0': 'Vælg',
            'value1': 'Haster',
            'value2': 'Haster Ikke',
        },
        'MESSAGES': {
            'DropBoxApiNotResponding': 'Dropbox reagerer ikke, prøv efter lidt tid',
            'FileConflict': 'Filen eksisterer allerede',
            'FolderConflict': 'Mappenavn eksisterer allerede',
            'Created': 'Oprettet med succes',
            'Updated': 'Opdateret med succes',
            'Deleted': 'Slettet med succes',
            'Failed': 'Operation mislykket, prøv senere',
            'EmailSent': 'Email sendt med succes',
            'PersonalPurchaseMessage': 'Dette er et personale køb - tryk gem for at indtaste materialer',
            'MaterialPriseMoreOne': 'Gå ind Udsalgs pris',
            'MaterialQTYRequired': 'Indtast Antal.',
            'EnterText': 'Gå ind tekst.',
            'FeildUnderFinishFolder': '*** Denne projektmappe er under færdigmappe.',
            'SpecialInfoStartDate': 'Rekvisition status er planlagt og startdato er tom',
            'InactiveRequisitionPlaniingMessage': 'Sæt ikke inaktiv rekvisition, før du har planlagt timer afsluttet.',
            'ExcludePriceNotification': 'Indsæt Pris excl. Moms eller Timeløn i sag på timeløn !',
            'RequisitionNumber': 'Rekvisitionsnummer kræves',
            'MaterialInOrderProcess': '**Materialer i en bestilt proces',
            'CustomerRequisitionNumber': 'Valgte kunde skal har indsætte Rekvisition nummer.',
        },
        'monthList': {
            'value1': 'Jan',
            'value2': 'Feb',
            'value3': 'Mar',
            'value4': 'Apr',
            'value5': 'Maj',
            'value6': 'Jun',
            'value7': 'Jul',
            'value8': 'Aug',
            'value9': 'Sep',
            'value10': 'Okt',
            'value11': 'Nov',
            'value12': 'Dec',
        },
        'StatusLogCases': {
            'value0': 'Projekt oprettet',
            'value1': 'Status opdateret',
            'value2': 'Medarbejder slettet',
            'value3': 'Statick Data Opdateret',
            'value4': 'Prisdata ændret',
            'value5': 'Medarbejder Tilføjet',
            'value6': 'Vare Tilføjet',
            'value7': 'Vare Opdateret',
            'value8': 'Vare Slettet',
            'value9': 'Email Sendt Fra Projekt',
            'value10': 'Email Sendt Fra Kunde'
        },
        'paymentModeList': {
            'value1': 'Dankort',
            'value2': 'Mastercard',
            'value3': 'kontant',
            'value4': 'Mobilpay',
            'value5': 'Mobilepay KK',
            'value6': 'Kredit',
            'value7': 'Andet',
        },
        'selectedOptionForLoftAndRiseMan': {
            'value1': 'Nej',
            'value2': 'Ja',
        },
        'TOOTIPORTITLE':{
            'AddCustomer':'Tilføj kunde',
            'Edit':'Redigere',
            'Delete':'Slet',
            'DeleteAll':'Slet Alt',
            'IsDelete':'Er Slet',
            'IsActive':'Er Aktiv',
            'Copy':'Kopi',
            'SendSMS':'Send SMS',
            'SendEmail':'Send E-mail',
            'DeleteProject':'Slet projekt',
            'GoToInquiry':'Gå Til Forespørgsel',
            'UpdateStatisticInfo':'Opdatering Statistik Info',
            'EditRequisition':'Redigere Rekvisition',
            'EditMaterial':'Rediger Materiale',
            'DeleteMaterial':'Slet Materiale',
            'RefreshMaterial':'Opdater Materialer'


        },
        'VALIDATIONS':{
            'AddressRequired':'Adresse er påkrævet',
            'CityRequired':'By er påkrævet',
            'PhoneRequired':'Telefonnummer er påkrævet',
            'ValidPhoneRequired': 'Indtast gyldig telefon',
            'ZipcodeRequired':'Postnummer er påkrævet',
            'EmailRequired':'E-mail er påkrævet',
            'ValidEmail':'Indtast gyldig e-mail adresse',
            'WhiteSpaceNotAllowed':'Kun hvidt mellemrum er ikke tilladt'
        }
    }
};
