import { Component, OnInit, ElementRef, NgZone, ViewChild, OnDestroy } from '@angular/core';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';

import { Router, ActivatedRoute } from '@angular/router';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { InquiryCustomer } from 'app/main/model/Customer';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'app/core/services/auth.service';
import { TitleService } from 'app/core/services/title.service';
import { MatDialog } from '@angular/material';
import { NotificationsService } from 'angular2-notifications';
import { NewCustomerComponent } from 'app/main/pages/customer/new-customer/new-customer.component';
import { DeleteAlertModalComponent } from 'app/main/pages/delete-alert-modal/delete-alert-modal.component';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';
import { InquiryService } from '../inquiry.service';
import { ReminderService } from 'app/main/pages/reminder/reminder.service';
import { CoWorkersService } from 'app/main/pages/coWorker/coWorkers.service';
import { FormService } from 'app/core/services/form.service';
import { TranslateService } from '@ngx-translate/core';
import { WhereDoesTheInquiryComeFrom, ProjectIndoorOutDoorStatus } from 'app/main/model/ProjectStatus';
import { ProjectStatus } from 'app/constant/common.constant';
import { RequestCoWorker, RequestStatusProject, RequestRequisition, SMSModules, RequestCustomer } from 'app/main/model/RequestBase';
import { Project, projectRequisition } from 'app/main/model/Project';
import { EmailSendingParam } from 'app/main/model/common';
import { TextEditorEmailSendComponent } from 'app/main/shared-component/text-editor-email-send/text-editor-email-send.component';
import { ProjectService } from 'app/main/pages/project/project.service';
import { ProjectRequisitionService } from 'app/main/pages/project/project.requisition.service';
import { MatOption } from '@angular/material';
import * as moment from 'moment';
import { StartAndVisitDateComponent } from 'app/main/pages/inquiry/component/startAndVisitDate/startAndVisitDate.component';
import { CustomersService } from 'app/main/pages/customer/customer.service';
import { CkeditorConfig } from 'app/constant/common.constant';
import { SMSModuleEnum } from 'app/core/enum/sms-module.enum';
import { SendSMSComponent } from 'app/main/shared-component/send-sms/send-sms.component';
import { FolderListNavigationDTO } from 'app/main/model/DropBox';
import { ApiConstant } from 'app/core/constant/ApiConstant';
import { CustomErrorStateMatcher, spaceOnlyValidator } from 'app/core/Validators/validation';
import { CommonService } from 'app/core/services/common.service';
import { DropboxFilesService } from 'app/main/shared-component/dropbox-files/dropbox-files.service';
import { DriveModuleEnum } from 'app/core/enum/drive-module.enum';

@Component({
    selector: 'app-new-inquiry',
    templateUrl: './new-inquiry.component.html',
    styleUrls: ['./new-inquiry.component.scss'],
})

export class NewInquiryComponent implements OnInit, OnDestroy {
    inquiryObj = new InquiryCustomer();
    // roleName: string;
    inquiryForm: FormGroup;
    showSpinner: boolean = false;
    showExistingCustomerList: boolean = false;
    InquiryCustomerList: any = [];
    objAddressEdit: any = {};
    showCustomerAddFeild: boolean = false;
    showProjectAddFeild: boolean = false;
    showErrorOnComment: boolean = false;
    assignedCustomerId: string;
    //whereDoesTheInquiryComeFrom = WhereDoesTheInquiryComeFrom;
    selectedIndex: number = 0;
    inquiryCount: number = 0;
    projectStatus = ProjectStatus;
    selectIndoorOutDoorStatus = ProjectIndoorOutDoorStatus;
    previousStatus: number;
    coWokers: any = [];
    ObjProject: Project;
    //#region "project Requisition"
    Requisition: projectRequisition;
    RequisitionList: any = [];
    InquiryLogId: string = "";
    customerEmailTemplate: any;
    UserName: string = "";
    config = CkeditorConfig;
    NoteDescription: any;
    comments: any;
    inquiryID: string = "";
    isFile: boolean = false;
    fileFolderPathData: Array<FolderListNavigationDTO> = [];
    Customers: any[];
    showAddressBar: boolean = true;
    DisplayVal: any[] = ['number', 'name'];
    IsGettingCustomers: boolean = false;
    //#endregion "project Requisition"
    @ViewChild('allIndoorOutdoorStatusSelected', { static: false }) private allIndoorOutdoorStatusSelected: MatOption;
    public editorOptions = {
        placeholder: "Comments [Optional]"
    };
    inquiries: any[];

    isSubmitted: boolean = false;
    esMatcher = new CustomErrorStateMatcher();
    roleClaims: any;

    constructor(private translationLoader: FuseTranslationLoaderService, private elem: ElementRef,
        private _route: ActivatedRoute,
        private inquiryService: InquiryService,
        private reminderService: ReminderService,
        private projectService: ProjectService,
        private projectRequisitionService: ProjectRequisitionService,
        private coWorkersService: CoWorkersService,
        private formBuilder: FormBuilder,
        private _router: Router,
        private authService: AuthService,
        private titleService: TitleService,
        private customerService: CustomersService,
        private commonService: CommonService,
        public dialog: MatDialog,
        public _ngZone: NgZone,
        private notificationsService: NotificationsService,
        private translate: TranslateService,
        private formService: FormService,
        private customersService: CustomersService,
        private dropboxFilesService: DropboxFilesService) {
        this.titleService.SetTitle("TITLE_INQUIRY");
        this.translationLoader.loadTranslations(english, danish);
        window["customerComponent"] = { component: this, zone: this._ngZone };
        this.roleClaims = this.authService.getRoleClaimsList();
        try {
            var tempData = this._router.getCurrentNavigation().extras.state;
            if (tempData && tempData.moduleDataList)
                this.inquiries = tempData.moduleDataList;
        } catch (error) {

        }
    }

    ngOnInit() {
        this.config.height = 100;
        this.config.width = 1030;
        // this.roleName = this.authService.getUserRole();
        // if (this.roleName == "Admin") {
        // }
        // else if (this.roleName == "Kontor") {
        // }
        // else if (this.roleName == "Maler") {
        // }
        // else if (this.roleName == "Gaest") {
        // }
        // else {
        // }
        this.inquiryForm = this.formBuilder.group({
            Name: [null, Validators.compose([Validators.required, spaceOnlyValidator.bind(this), Validators.maxLength(40)])],
            //Phone: [null, Validators.compose([Validators.required, Validators.maxLength(8)])],
            Phone: [null],
            //Email: [null, Validators.compose([Validators.required, Validators.pattern("^[ÆØÅæøåA-Za-z0-9._%+-]+@(?:[ÆØÅæøåA-Za-z0-9-]+\.)+[A-Za-z]{2,6}$"), Validators.maxLength(80)])],
            Email: [null, Validators.compose([Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$"), Validators.maxLength(80)])],
            PostNo: [null, Validators.compose([Validators.maxLength(15)])],
            City: [null, Validators.compose([Validators.maxLength(15)])],
            Address: [null, Validators.compose([Validators.maxLength(150)])],
            Question: [null, Validators.compose([Validators.maxLength(5000)])],
            //CoworkerComment: [null, Validators.compose([Validators.required, Validators.maxLength(500)])],Z
            CoworkerComment: [null, Validators.compose([Validators.maxLength(500)])],
            //Source: [null],
            Number: [null],
            received: [null, ''],
            receivedTime: [null, ''],
            //EmailInvoice: [null, Validators.compose([Validators.required, Validators.pattern("^[ÆØÅæøåA-Za-z0-9._%+-]+@(?:[ÆØÅæøåA-Za-z0-9-]+\.)+[A-Za-z]{2,6}$"), Validators.maxLength(80)])],
            EmailInvoice: [null, Validators.compose([Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$"), Validators.maxLength(80)])],
            IndoorOutdoorStatusIds: [null, [Validators.required]],
            CountryCode: [null],
            searchName: [null],
            customerName: [null]
        });
        this._route.params.subscribe(params => {
            var id = params['id'];
            if (id) {
                this.GetInquiry(id);
                this.showCustomerAddFeild = true;
                this.InquiryLogId = id;
                this.inquiryID = id;
            }
            else {
                this.inquiryObj.received = new Date();
                this.inquiryObj.receivedTime = moment.utc(this.inquiryObj.received).format("HH:mm") == "00:00" ? "" : moment.utc(this.inquiryObj.received).format("HH:mm");
                this.inquiryObj.received = moment.utc(this.inquiryObj.received).format("YYYY-MM-DD");
                //this.inquiryObj.source = 6;
            }
        });
        let coWorker = new RequestCoWorker();
        coWorker.IsDeleted = false;
        this.getCoWorkers(coWorker);

        this.GetCustomer(new RequestCustomer(), '');

    }


    onCustomerChange(selectedID) {
        if (selectedID) {
            this.onChangeCustomer(selectedID);
        }
        //else {
        //  this.inquiryObj = new InquiryCustomer();
        //  this.inquiryObj.received = new Date();
        //  this.inquiryObj.receivedTime = moment.utc(this.inquiryObj.received).format("HH:mm") == "00:00" ? "" : moment.utc(this.inquiryObj.received).format("HH:mm");
        //  this.inquiryObj.received = moment.utc(this.inquiryObj.received).format("YYYY-MM-DD");
        //  this.inquiryObj.source = 6;

        //  this.showAddressBar = false;
        //  this.objAddressEdit = {};
        //  setTimeout(() => {
        //    this.showAddressBar = true;
        //  });
        //}
    }

    GetInquiry(id) {
        this.showSpinner = true;
        this.inquiryService.GetSingle<ResponseBase>(id)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.showSpinner = false;
                    this.UpdateMarkAsReadInquiry(id);
                    this.inquiryObj = response.result;
                    this.previousStatus = this.inquiryObj.status;
                    this.assignedCustomerId = this.inquiryObj.customerId;
                    if (!this.inquiryObj.received) {
                        this.inquiryObj.received = new Date();
                    }
                    if (this.inquiryObj.received) {
                        this.inquiryObj.receivedTime = moment.utc(this.inquiryObj.received).format("HH:mm") == "00:00" ? "" : moment.utc(this.inquiryObj.received).format("HH:mm");
                        this.inquiryObj.received = moment.utc(this.inquiryObj.received).format("YYYY-MM-DD");
                    }
                    if (this.inquiryObj.customerId) {
                        this.showCustomerAddFeild = false;
                        this.showProjectAddFeild = true;
                    }
                    if (this.inquiryObj.projectId)
                        this.getProjectDetail(this.inquiryObj.projectId)
                    this.setIndoorOutDoorDropDown()
                    this.objAddressEdit = {
                        'latitude': "",
                        'longitude': "",
                        'address': this.inquiryObj.address,
                        'city': this.inquiryObj.city,
                        'pinCode': this.inquiryObj.postNo,
                        'mapLocation': "",
                        'IsEdit': true
                    }
                    var model = new RequestStatusProject();
                    model.Index = 1;
                    this.getCoWorkers(model);
                    if (this.inquiryObj.projectId)
                        this.getNotetabData();
                    else
                        this.GetNotesDetailsFromInquiry();
                    this.storeFileFolderPathData();
                    //this.GetCommnetsDetail();
                },
                error: err => {
                    this.showSpinner = false;
                },
                complete: () => {

                }
            });
    }

    UpdateMarkAsReadInquiry(id) {
        this.inquiryService.UpdateMarkAsReadInquiry<ResponseBase>(id)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.GetInquiryCount();
                },
                error: err => {
                },
                complete: () => {

                }
            });
    }

    GetInquiryCount() {
        this.reminderService.GetInquiryCountapi<ResponseBase>()
            .subscribe({
                next: (response: ResponseBase) => {
                    if (response != null) {
                        this.inquiryCount = response.result;
                        //this.reminderService.setInquiryCounts(response.result);
                        this.authService.setInquiryCount(response.result);
                        //this.authService.sendMessage(this.inquiryCount);
                    }
                },
                error: err => {
                },
                complete: (
                ) => {
                }
            })
    }

    save() {
        this.showErrorOnComment = false;
        this.isSubmitted = true;
        this.esMatcher.isSubmitted = this.isSubmitted;
        //this.removeRequiredValidation();
        this.formService.markFormGroupTouched(this.inquiryForm);
        if (this.inquiryForm.valid && this.esMatcher.isValid) {
            this.inquiryObj.indoorOutdoorStatusId = "";
            this.inquiryObj.indoorOutdoorStatusIds.filter(x => {
                this.inquiryObj.indoorOutdoorStatusId = (this.inquiryObj.indoorOutdoorStatusId ?
                    this.inquiryObj.indoorOutdoorStatusId + "," : "") + x;
            });
            if (this.inquiryObj.id) {
                this.Update(true);
            }
            else {
                this.Add();
            }
        }
    }

    TabChange(event) {
        this.selectedIndex = event.index;
        if (this.selectedIndex == 0 && this.InquiryLogId)
            this.GetNotesDetailsFromInquiry();
        else if (this.selectedIndex == 1) {
            this.InquiryLogId = this.inquiryID;
        }
        else if (this.selectedIndex == 2) {
            this.storeFileFolderPathData();
        }
    }

    getContent(content) {
        this.inquiryObj.isNote = false;
        if (content) {
            this.inquiryObj.isNote = true;
        }
    }

    Add() {
        this.showSpinner = true;
        if (this.inquiryObj.received) {
            if (this.inquiryObj.receivedTime)
                this.inquiryObj.received = moment.utc(this.inquiryObj.received + " " + this.inquiryObj.receivedTime).format("YYYY-MM-DD HH:mm");
            else
                this.inquiryObj.received = moment.utc(this.inquiryObj.received).format("YYYY-MM-DD");
        }
        this.inquiryService.Add<ResponseBase>(this.inquiryObj)
            .subscribe({
                next: (response: ResponseBase) => {
                    if (response.result) {
                        this.inquiryObj = response.result;
                        this.inquiryID = response.result.id;
                        //this.UpdateDetailsFromInquiry();
                        this.GetNotesDetailsFromInquiry();
                        //this.GetCommnetsDetail();
                        this.assignedCustomerId = this.inquiryObj.customerId;
                        this.showCustomerAddFeild = true;
                        if (this.inquiryObj.received) {
                            this.inquiryObj.receivedTime = moment.utc(this.inquiryObj.received).format("HH:mm") == "00:00" ? "" : moment.utc(this.inquiryObj.received).format("HH:mm");
                            this.inquiryObj.received = moment.utc(this.inquiryObj.received).format("YYYY-MM-DD");
                        }
                        this.setIndoorOutDoorDropDown();
                        this.updateInquiryCount();
                    }
                    this.storeFileFolderPathData();
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.Created").subscribe({
                        next: (res) => {
                            this.notificationsService.create(res);
                        },
                        error: err => {
                        }
                    });
                    this._router.navigate(['/inquiry/edit', this.inquiryID]);
                },
                error: err => {
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });
                }
            });
    }

    Update(showMessage) {
        this.showSpinner = true;
        this.inquiryObj.customerId = this.assignedCustomerId;
        if (this.inquiryObj.received) {
            if (this.inquiryObj.receivedTime)
                this.inquiryObj.received = moment.utc(this.inquiryObj.received + " " + this.inquiryObj.receivedTime).format("YYYY-MM-DD HH:mm");
            else
                this.inquiryObj.received = moment.utc(this.inquiryObj.received).format("YYYY-MM-DD");
        }
        this.inquiryService.Update<ResponseBase>(this.inquiryObj.id, this.inquiryObj)
            .subscribe({
                next: (response: ResponseBase) => {
                    if (response.result) {
                        //this.UpdateDetailsFromInquiry();
                        this.GetNotesDetailsFromInquiry();
                        //this.GetCommnetsDetail();
                        this.showExistingCustomerList = false;
                        this.inquiryObj = response.result;
                        if (this.inquiryObj.received) {
                            this.inquiryObj.receivedTime = moment.utc(this.inquiryObj.received).format("HH:mm") == "00:00" ? "" : moment.utc(this.inquiryObj.received).format("HH:mm");
                            this.inquiryObj.received = moment.utc(this.inquiryObj.received).format("YYYY-MM-DD");
                        }
                        if (this.inquiryObj.customerId) {
                            this.showCustomerAddFeild = false;
                            this.showProjectAddFeild = true;
                        }
                        else {
                            this.showCustomerAddFeild = true;
                            this.showProjectAddFeild = false;
                        }
                        this.setIndoorOutDoorDropDown();
                        this.objAddressEdit = {
                            'latitude': "",
                            'longitude': "",
                            'address': this.inquiryObj.address,
                            'city': this.inquiryObj.city,
                            'pinCode': this.inquiryObj.postNo,
                            'mapLocation': "",
                            'IsEdit': true
                        }
                        this.updateInquiryCount();
                    }
                    this.showSpinner = false;
                    this.storeFileFolderPathData();
                    if (showMessage) {
                        this.translate.get("MESSAGES.Updated").subscribe({
                            next: (res) => {
                                this.notificationsService.create(res);
                            },
                            error: err => {
                            }
                        });
                    }
                },
                error: err => {
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });
                }
            });
    }

    removeInquiry(id) {
        const dialogRef = this.dialog.open(DeleteAlertModalComponent, {
            height: '200px',
            data: this.inquiryObj.id
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.deleteInquiry(this.inquiryObj.id);
            }
        });
    }

    deleteInquiry(Id) {
        this.showSpinner = true;
        this.inquiryService.Delete<ResponseBase>(Id).subscribe({
            next: (response: ResponseBase) => {
                this.translate.get("MESSAGES.Deleted").subscribe({
                    next: (res) => {
                        this.notificationsService.create(res);
                    },
                    error: err => {
                    }
                });
                this.updateInquiryCount();
            },
            error: err => {
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            },
            complete: () => {
                this.showSpinner = false;
                this._router.navigate(['/inquiry']);
            }
        });
    }

    unassignCustomerToInquiry() {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: '200px',
            data: { Header: 'Headers.UnassignCustomerFromInquiry' }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.assignedCustomerId = null;
                this.Update(true);
            }
        });

    }

    setInactive(value) {
        //this.addRequiredValidation();
        this.formService.markFormGroupTouched(this.inquiryForm);
        //if (this.inquiryForm.valid && this.inquiryObj.coworkerComment) {
        this.inquiryObj.isActive = value;
        this.Update(true);
        //setTimeout(() => {
        //  this._router.navigate(['/inquiry']);
        //}, 1000);
        //}
    }

    ngOnBackClick() {
        this._router.navigate(['/inquiry']);
    }

    removeRequiredValidation() {
        this.inquiryForm.controls['CoworkerComment'].disable();
    }

    addRequiredValidation() {
        this.inquiryForm.controls['CoworkerComment'].enable();
    }

    receiveAddressObject($event) {
        this.inquiryObj.address = $event.address
        this.inquiryObj.city = $event.city
        this.inquiryObj.postNo = $event.pinCode
    }

    ngOnChangeCustomerSelected(id) {
        this.InquiryCustomerList.filter(x => {
            if (x.id != id) {
                x.isSelected = false;
            }
            else {
                if (this.assignedCustomerId && this.assignedCustomerId == id) {
                    this.assignedCustomerId = null;
                }
                else {
                    this.assignedCustomerId = id;
                    this.onChangeCustomer(id);
                }
            }
        });
    }

    showExistingCustomer() {
        this.InquiryCustomerList = [];
        this.showExistingCustomerList = !this.showExistingCustomerList;
        this.projectService.GetMatchCustomersOnInquiry<ResponseBase>(this.inquiryObj.id)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.showSpinner = false;
                    this.InquiryCustomerList = response.result.myResponse;
                },
                error: err => {
                    this.showSpinner = false;
                },
                complete: () => {
                    this.showSpinner = false;
                }
            });

    }

    ShowCustomerModelPopUp(id) {
        const dialogRef = this.dialog.open(NewCustomerComponent, {
            height: '80%',
            width: '80%',
            data: { isRedirectToInquiry: true, isAdd: false, id: id },
        });

        dialogRef.afterClosed().subscribe(result => {
            this.titleService.SetTitle("TITLE_INQUIRY");
        });
    }

    AddCustomer() {
        const dialogRef = this.dialog.open(NewCustomerComponent, {
            height: '80%',
            width: '90%',
            disableClose: true,
            data: { isRedirectToInquiry: true, isAdd: true, id: this.inquiryObj.id },
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.titleService.SetTitle("TITLE_INQUIRY");
                this.assignedCustomerId = result;
                this.Update(false);
            } else {
                this.titleService.SetTitle("TITLE_INQUIRY");
            }
        });
    }


    emailToCustomer() {
        var emailSendingParam = new EmailSendingParam();
        emailSendingParam.fromName = this.authService.getUserName();
        // emailSendingParam.from = "post@vibesmalerfirma.dk";
        emailSendingParam.to = this.inquiryObj.customerEmail ? this.inquiryObj.customerEmail : this.inquiryObj.email;
        emailSendingParam.moduleName = "Inquiry";
        emailSendingParam.moduleID = this.inquiryID;
        var addressDetail = {
            address: this.inquiryObj.address ? this.inquiryObj.address : "",
            zipCode: this.inquiryObj.postNo ? this.inquiryObj.postNo : "",
            city: this.inquiryObj.city ? this.inquiryObj.city : "",
        }
        emailSendingParam.subject = this.inquiryObj.number + " " + this.inquiryObj.name + " " + addressDetail.address + " " + addressDetail.zipCode + " " + addressDetail.city; //this.inquiryObj.address + " " + this.inquiryObj.postNo + " " + this.inquiryObj.city;
        //emailSendingParam.body = this.customerEmailTemplate;
        emailSendingParam.coworkerList = this.coWokers;
        emailSendingParam.isLoadDefaultEmailTemplate = true;
        var questions = this.inquiryObj.question ? this.inquiryObj.question : " ";
        emailSendingParam.text = "Tusind tak for din hendvendelse vedr. <br/><br/>" + questions
        const dialogRef = this.dialog.open(TextEditorEmailSendComponent, {
            height: '90%',
            width: '90%',
            data: emailSendingParam
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result != null && result != '') {
                this.showSpinner = true;
                this.projectService.SendEmail<ResponseBase>(result)
                    .subscribe({
                        next: (response: ResponseBase) => {
                            this.showSpinner = false;
                            this.translate.get("MESSAGES.EmailSent").subscribe({
                                next: (res) => {
                                    this.notificationsService.create(res);
                                },
                                error: err => {
                                }
                            });
                            this.setInquiryInactivePopup();
                            this.commonService.checkEmailIsToBeSent();
                        },
                        error: err => {
                            this.showSpinner = false;
                            this.translate.get("MESSAGES.Failed").subscribe({
                                next: (res) => {
                                    this.notificationsService.error(res);
                                },
                                error: err => {
                                }
                            });

                        },
                        complete: () => {
                            this.showSpinner = false;
                        }
                    });
            }
        });
    }

    onChangeEmail() {
        if (this.inquiryObj.emailInvoice == undefined || this.inquiryObj.emailInvoice == "")
            setTimeout(() => {
                this.inquiryObj.emailInvoice = this.inquiryObj.email;
            }, 100);
    }
    updateInquiryCount() {
        this.GetInquiryCount();
    }

    toggleAllStatusIndoorOutDoorSelection() {
        this.inquiryObj.indoorOutdoorStatusIds = [];
        if (this.allIndoorOutdoorStatusSelected.selected) {
            //this.selectIndoorOutDoorStatus.filter(y => {
            //    this.inquiryObj.indoorOutdoorStatusIds.push(y.id);
            //})
            for (var i = 0; i < 13; i++) {
                this.inquiryObj.indoorOutdoorStatusIds.push(i);
            }
        }
    }

    setIndoorOutDoorDropDown() {
        if (this.inquiryObj.indoorOutdoorStatusId) {
            this.inquiryObj.indoorOutdoorStatusIds = new Array();
            var SplitIndoorOutdoorStatusId = this.inquiryObj.indoorOutdoorStatusId.split(',');
            SplitIndoorOutdoorStatusId.filter(y => {
                this.inquiryObj.indoorOutdoorStatusIds.push(parseInt(y));
            })
        }
    }

    onChangeStatus() {
        if (this.inquiryObj.status == 6 || this.inquiryObj.status == 2) {
            this.validateRequisitionField();
        }
    }

    validateRequisitionField() {
        if (!this.inquiryObj.customerId) {
            this.openAlertMessage("Headers.CreateCustomer", true)
            return
        }
        if (!this.inquiryObj.projectId) {
            this.openAlertMessage("Headers.CreateProject", false)
            return
        }
        var planning = false; var scheduledVisit = false;
        if (this.inquiryObj.status == 6) {
            var planning = true
        }
        if (this.inquiryObj.status == 2) {
            var scheduledVisit = true
        }
        var flag = {
            coworkerList: this.coWokers,
            isPlanning: planning,
            isScheduledVisit: scheduledVisit,
            startDate: this.ObjProject.startDate,
            startTime: this.ObjProject.startTime,
            visit: this.ObjProject.visit,
            visitTime: this.ObjProject.visitTime,
            visitCoworkerIds: this.Requisition.visitCoworkerId ? this.Requisition.visitCoworkerId.split(',') : []
        }
        const dialogRef = this.dialog.open(StartAndVisitDateComponent, {
            height: '55%',
            width: '50%',
            data: flag,
            disableClose: true,
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != null) {
                if (planning) {
                    this.ObjProject.startDate = result.startDate;
                    this.ObjProject.startTime = result.startTime;
                }
                if (scheduledVisit) {
                    this.ObjProject.visit = result.visit;
                    this.ObjProject.visitTime = result.visitTime;
                }
                this.Requisition.visitCoworkerId = result.visitCoworkerIds.join().toString();;
                this.UpdateProject();
                planning = false;
                scheduledVisit = false;
            }
        });
    }

    openAlertMessage(message, isCustomer) {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: '200px',
            data: { Header: message }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                if (isCustomer)
                    this.AddCustomer();
                else
                    this._router.navigate(['/projects/add/inquiry', this.inquiryObj.customerId, this.inquiryObj.id]);
            }
            this.inquiryObj.status = this.previousStatus;
        });
    }

    getCoWorkers(model) {
        model.Limit = 400;
        model.OrderBy = "Name";
        this.coWorkersService.Get<ResponseBase>(model)
            .subscribe((response: ResponseBase) => {
                if (response) {
                    this.getNextRequestForCoWorker(response.pagination, model, response.result);
                }
                else {
                    this.coWokers = [];

                }
            },
                error => (err) => {
                    this.coWokers = [];
                },
                () => {
                });
    }

    getNextRequestForCoWorker(pagination, model, response) {
        if (pagination.index == 1) {
            this.coWokers = response;
            //this.AllCoworkers = response;
        }
        else {
            this.coWokers.push(...response);
            //this.AllCoworkers.push(...response);
        }
        model.Index = pagination.index + 1;
        model.Limit = pagination.limit;
        model.TotalRows = pagination.totalCount;
        if (pagination.totalCount > pagination.index * pagination.limit)
            this.getCoWorkers(model);
    }

    getProjectDetail(id) {
        this.showSpinner = true;
        this.projectService.GetSingle<ResponseBase>(id)
            .subscribe({
                next: (response: ResponseBase) => {
                    if (response.result) {
                        this.ObjProject = response.result;
                        if (this.ObjProject.startDate) {
                            this.ObjProject.startTime = moment(this.ObjProject.startDate).format("HH:mm") == "00:00" ? "" : moment(this.ObjProject.startDate).format("HH:mm");
                            this.ObjProject.startDate = this.ObjProject.startDate;
                        }
                        if (this.ObjProject.endDate) {
                            this.ObjProject.endTime = moment(this.ObjProject.endDate).format("HH:mm") == "00:00" ? "" : moment(this.ObjProject.endDate).format("HH:mm");
                            this.ObjProject.endDate = this.ObjProject.endDate;
                        }
                        if (this.ObjProject.visit) {
                            this.ObjProject.visitTime = moment(this.ObjProject.visit).format("HH:mm") == "00:00" ? "" : moment(this.ObjProject.visit).format("HH:mm");
                            this.ObjProject.visit = this.ObjProject.visit;
                        }
                        if (this.ObjProject.received) {
                            this.ObjProject.receivedTime = moment(this.ObjProject.received).format("HH:mm") == "00:00" ? "" : moment(this.ObjProject.received).format("HH:mm");
                            this.ObjProject.received = this.ObjProject.received;
                        }
                    }
                    this.ObjProject.fileAssignmentId = response.result.sagOpgaveID;
                },
                error: err => { },
                complete: () => {
                    this.showSpinner = false;
                    var modelRequisition = new RequestRequisition();
                    modelRequisition.ProjectId = id;
                    this.getRequisitionList(modelRequisition);
                }
            });
    }

    UpdateProject() {
        this.showSpinner = true;
        if (this.ObjProject.startDate) {
            if (this.ObjProject.startTime)
                this.ObjProject.startDate = moment(moment(this.ObjProject.startDate).format("YYYY-MM-DD") + " " + this.ObjProject.startTime).format("YYYY-MM-DD HH:mm");
            else
                this.ObjProject.startDate = moment(this.ObjProject.startDate).format("YYYY-MM-DD");
        }
        if (this.ObjProject.visit) {
            if (this.ObjProject.visitTime)
                this.ObjProject.visit = moment(moment(this.ObjProject.visit).format("YYYY-MM-DD") + " " + this.ObjProject.visitTime).format("YYYY-MM-DD HH:mm");
            else
                this.ObjProject.visit = moment(this.ObjProject.visit).format("YYYY-MM-DD");
        }
        this.ObjProject.createdBy = this.authService.getCoWorkerId();
        this.projectService.Update<ResponseBase>(this.ObjProject.sagId, this.ObjProject)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.ObjProject = response.result;
                    this.save();
                    this.ObjProject.sagId = response.result.sagId;
                    if (this.ObjProject.startDate) {
                        this.ObjProject.startTime = moment(this.ObjProject.startDate).format("HH:mm") == "00:00" ? "" : moment(this.ObjProject.startDate).format("HH:mm");
                        this.ObjProject.startDate = this.ObjProject.startDate;
                    }
                    if (this.ObjProject.visit) {
                        this.ObjProject.visitTime = moment(this.ObjProject.visit).format("HH:mm") == "00:00" ? "" : moment(this.ObjProject.visit).format("HH:mm");
                        this.ObjProject.visit = this.ObjProject.visit;
                    }
                    this.ObjProject.fileAssignmentId = response.result.sagOpgaveID;
                    this.showSpinner = false;
                    setTimeout(() => {
                        this.translate.get("MESSAGES.Updated").subscribe({
                            next: (res) => {
                                this.UpdateRequisition(this.Requisition);
                                this.notificationsService.create(res);
                            },
                            error: err => {
                            }
                        });
                    }, 50);
                },
                error: err => {
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });
                },
                complete: () => {
                }
            });
    }

    getRequisitionList(model: RequestRequisition) {
        this.projectRequisitionService.Get<ResponseBase>(model.ProjectId, model).subscribe({
            next: (response: ResponseBase) => {
                if (response) {
                    this.RequisitionList = response.result;
                    this.RequisitionList = this.RequisitionList.sort((a, b) => {
                        return a.number - b.number;
                    });
                    this.EditRequisition(this.RequisitionList[0].id, this.RequisitionList[0]);
                }
                else
                    this.RequisitionList = [];
            },
            error: err => {
                this.RequisitionList = [];
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    EditRequisition(Id: any, objEditingReq) {
        this.projectRequisitionService.GetSingle(this.ObjProject.sagId, Id).subscribe({
            next: (response: ResponseBase) => {
                if (response) {
                    setTimeout(() => {
                        this.Requisition = response.result;
                    }, 1000);
                }
            },
            error: err => { },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    UpdateRequisition(model: projectRequisition) {
        this.showSpinner = true;
        model.CreatedBy = this.authService.getCoWorkerId();
        this.projectRequisitionService.Update(model.projectId, model.id, model).subscribe({
            next: (response: ResponseBase) => {
                if (response) {
                    var index = this.RequisitionList.findIndex(f => f.id == model.id);
                    this.RequisitionList.splice(index, 1);
                    this.RequisitionList.splice(index, 0, response.result);
                }
            },
            error: err => {
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    GetNotesDetailsFromInquiry() {
        this.inquiryService.GetNoteDetail<any>(this.inquiryObj.id).subscribe({
            next: (response) => {
                this.showSpinner = false;
                this.NoteDescription = response.result.content;
            },
            error: err => {
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            }
        });
    }

    GetCommnetsDetail() {
        this.inquiryService.GetCommentsDetail<any>(this.inquiryObj.id).subscribe({
            next: (response) => {
                this.inquiryObj.comments = response.result.content;
            },
            error: err => {
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            }
        });
    }

    UpdateDetailsFromInquiry() {
        this.showSpinner = true;
        this.inquiryService.UpdateComments<ResponseBase>(this.inquiryObj.id, this.inquiryObj.comments)
            .subscribe({
                next: (response: ResponseBase) => {
                    //this.GetCommnetsDetail()
                    this.showSpinner = false;
                },
                error: err => {
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });
                },
                complete: () => {
                    this.showSpinner = false;
                }
            });
    }

    sendSMS() {
        var moduleObj = new SMSModules()
        moduleObj.name = this.inquiryObj.name;
        moduleObj.moduleID = this.inquiryObj.id;
        moduleObj.mobile = this.inquiryObj.phoneNo;
        moduleObj.flag = SMSModuleEnum.Inquiry;
        moduleObj.countryCode = this.inquiryObj.countryCode;
        const dialogRef = this.dialog.open(SendSMSComponent, {
            height: '350px',
            width: '800px',
            data: moduleObj
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {

            }
        });
    }

    setInquiryInactivePopup() {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: '20%',
            width: '40%',
            data: { Header: 'Headers.InquiryInactiveMessage' }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.inquiryObj.isActive = false;
                this.Update(true);
            }
        });
    }

    doesFilesIsExist(event) {

    }

    IsFileExist(flag) {
        this.isFile = flag;
    }

    //get customers
    GetCustomer(model, searchId) {
        model.Limit = 2000;
        model.OrderBy = "Text";
        this.IsGettingCustomers = true;
        this.customersService.Get<ResponseBase>(model)
            .subscribe((response: ResponseBase) => {
                if (response) {
                    //this.Customers = response.result;
                    this.getNextRequestForCustomer(response.pagination, model, response.result, searchId);
                }
                else {
                    this.Customers = [];
                    this.IsGettingCustomers = false;
                }
            },
                error => (err) => {
                    this.Customers = [];
                });
    }

    getNextRequestForCustomer(pagination, model, response, searchId) {
        if (pagination.index == 1) {
            this.Customers = response;
        }
        else {
            this.Customers.push(...response);
        }
        this.IsGettingCustomers = false;
        model.Index = pagination.index + 1;
        model.Limit = pagination.limit;
        model.TotalRows = pagination.totalCount;
        if (pagination.totalCount > pagination.index * pagination.limit)
            this.GetCustomer(model, searchId);
        else if (searchId != '') {
            this.onChangeCustomer(searchId);
        }
    }

    DropDownSearch(value) {
        if (value) {
            var request = new RequestCustomer();
            request.Name = value;
            try {
                if (!isNaN(Number(value.toString()))) {
                    request.Name = "";
                    request.Name = value;
                }
            } catch (e) {

            }
            this.GetCustomer(request, '');
        } else {
            this.GetCustomer(new RequestCustomer(), '');
        }
    }

    onChangeCustomer(selectedCustomer) {
        if (this.Customers) {
            var data = this.Customers.find(c => c.id == selectedCustomer);
            const dialogRef = this.dialog.open(AlertMessageModelComponent, {
                height: '20%',
                width: '40%',
                data: { Header: 'Headers.CustomerDetail' }
            });

            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    if (data) {
                        this.inquiryObj.customerId = data.id;
                        this.inquiryObj.customerNumber = data.number;
                        this.inquiryObj.name = data.name;
                        this.inquiryObj.email = data.email;
                        this.inquiryObj.emailInvoice = data.emailInvoice;
                        this.inquiryObj.phoneNo = data.phone;
                        this.inquiryObj.customerName = data.name;
                        this.inquiryObj.customerAddress = data.address;
                        this.inquiryObj.countryCode = data.countryCode;
                        // if (data.comingFrom && data.comingFrom != 0) {
                        //     this.inquiryObj.source = data.comingFrom;
                        // } else {
                        //     this.inquiryObj.source = 6;
                        // }
                        this.inquiryObj.indoorOutdoorStatusId = data.indoorOutdoorStatusId;
                        this.setIndoorOutDoorDropDown()
                        this.inquiryObj.address = data.address ? data.address : '';
                        this.inquiryObj.city = data.city ? data.city : '';
                        this.inquiryObj.postNo = data.pinCode ? data.pinCode : '';
                        this.inquiryObj.comments = data.comment ? data.comment : '';

                        this.showAddressBar = false;
                        this.objAddressEdit = {
                            'latitude': "",
                            'longitude': "",
                            'address': data.address,
                            'city': data.city,
                            'pinCode': data.pinCode,
                            'position': data.position,
                            'mapLocation': data.address,
                            'IsEdit': true
                        }

                        setTimeout(() => {
                            this.showAddressBar = true;
                        });
                    }
                } else {
                    this.inquiryObj.customerId = data.id;
                    this.inquiryObj.customerName = data.name;
                    this.inquiryObj.customerAddress = data.address;
                }
            });
            if (this.inquiryObj.indoorOutdoorStatusId) {
                this.setIndoorOutDoorDropDown();
            }
        }
    }

    storeFileFolderPathData() {
        var rootPath = this.dropboxFilesService.getSettingMasterValueDocumentPath();
        var paths = "";
        if (rootPath.DocumentDriveType == DriveModuleEnum.Onedrive) {
            paths = rootPath.Onedrive.Path;
        } else {
            paths = rootPath.Dropbox.Path;
        }
        this.fileFolderPathData = paths.split("/").map((path, i) => ({
            name: path,
            isDisplay: false
        }));
        if (this.inquiryObj.projectId && this.inquiryObj.projectNumber)
            this.fileFolderPathData.push(
                { name: 'Project', isDisplay: false },
                { name: (this.inquiryObj.projectNumber).toString(), isDisplay: true }
            );
        else
            this.fileFolderPathData.push(
                { name: 'Inquiries', isDisplay: false },
                { name: this.inquiryObj.number, isDisplay: true }
            );
    }

    getNotetabData() {
        this.projectService.GetProjectRequisition<any>(this.inquiryObj.projectId, ApiConstant.RequisitionNote).subscribe({
            next: (response) => {
                this.showSpinner = false;
                this.NoteDescription = response.result.description;
            },
            error: err => {
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });
            }
        });
    }
    gotoModule($event) {
        this.inquiryObj = new InquiryCustomer();;
        this._router.navigate(['/inquiry/edit', $event]);
    }


    ngOnDestroy() {
    }
}






