import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { MatDialog, MatSlideToggleChange } from '@angular/material';
import { AuthService } from 'app/core/services/auth.service';
import { SettingsService } from '../../settings.service';
import { ResponseBase } from "app/main/model/ResponseBase";
import { Settings, SettingValue } from "app/main/model/Settings";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CoWorker } from "app/main/model/CoWorker";
import { RequestCoWorker } from "app/main/model/RequestBase";
import { CoWorkersService } from "app/main/pages/coWorker/coWorkers.service";
import { NotificationsService } from "angular2-notifications";
import { TranslateService } from "@ngx-translate/core";
import { SettingEmailTempalteEditorComponent } from "../setting-email-tempalte-editor/setting-email-tempalte-editor.component";

@Component({
    selector: "vibe-vehicle-battery-notifications-settings",
    templateUrl: "./vehicle-battery-notifications.component.html",
    styleUrls: ["./vehicle-battery-notifications.component.scss"]
})

export class VehicleBatteryNotificationsSettingsComponent implements OnInit {
    @Input("SendBatteryPercentageReminderSetting") SendBatteryPercentageReminderSetting: Settings;
    @Input("CoWokers") CoWokers: CoWorker[];

    showSpinner: boolean = false;
    notificationForm: FormGroup;
    isSubmitted = false;
    coWokers: CoWorker[] = [];
    IsGettingCoWokers: boolean = false;
    PlanningNotificationsObj: Settings;
    settingMasterValues: any[] = [];
    settingValue: SettingValue = new SettingValue();

    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private fb: FormBuilder,
        private coWorkersService: CoWorkersService,
        private authService: AuthService,
        private settingsService: SettingsService,
        private translate: TranslateService,
        private notificationsService: NotificationsService,
        private dialog: MatDialog) {
        this.translationLoaderService.loadTranslations(danish, english);
    }

    ngOnInit() {
        this.settingMasterValues = Object.assign([], JSON.parse(localStorage.getItem("settingMasterValues")));

        // var coworkerOBJ = new RequestCoWorker();
        // coworkerOBJ.Name = "";
        // this.getCoWorkers(coworkerOBJ);

        this.notificationForm = this.fb.group({
            CoworkersID: [null, Validators.required],
            PercentageToSend: [null, Validators.compose([Validators.required, Validators.min(1), Validators.max(365)])],
            VoltageToSend: [null, Validators.compose([Validators.required, Validators.min(1), Validators.max(365)])],
            ExecuteTime: [null, Validators.required],
            IsCreateReminder: [false],
            IsSendSMS: [false],
            IsSendNotification: [false],
            IsSendEmail: [false],
            SMSText: [null],
            NotificationText: [null],
            IsEnable: [true],
            NumberofDays:[null]
        });

        this.notificationForm.get('IsSendSMS').valueChanges
            .subscribe(value => {
                if (value) {
                    this.notificationForm.controls['SMSText'].setValidators(Validators.required)
                } else {
                    this.notificationForm.controls['SMSText'].setValidators(null)
                }
                this.notificationForm.controls['SMSText'].updateValueAndValidity()
            });

        this.notificationForm.get('IsSendNotification').valueChanges
            .subscribe(value => {
                if (value) {
                    this.notificationForm.controls['NotificationText'].setValidators(Validators.required)
                } else {
                    this.notificationForm.controls['NotificationText'].setValidators(null)
                }
                this.notificationForm.controls['NotificationText'].updateValueAndValidity()
            });

        this.notificationForm.controls['CoworkersID'].valueChanges
            .subscribe(selectedCoWorkers => {
            });
        this.setSettingsForVehiclePlanningNotifications();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.coWokers = this.CoWokers;
    }

    getCoWorkers(model: RequestCoWorker) {
        model.OrderBy = "Name";
        this.IsGettingCoWokers = true;
        this.coWorkersService.Get<ResponseBase>(model)
            .subscribe((response: ResponseBase) => {
                if (response) {
                    this.coWokers = Object.assign([], response.result);
                    this.getNextRequest(response.pagination, model, response.result);
                }
                else {
                    this.coWokers = [];
                    this.IsGettingCoWokers = false;
                }
            },
                error => (err) => {
                    this.coWokers = [];
                    this.IsGettingCoWokers = false;
                },
                () => {

                });
    }

    getNextRequest(pagination, model, response) {
        if (pagination.index == 1) {
            this.coWokers = Object.assign([], response);
            this.IsGettingCoWokers = false;
        }
        else {
            this.coWokers.push(...response);
            this.IsGettingCoWokers = false;
        }
        model.Index = pagination.index + 1;
        model.Limit = pagination.limit;
        model.TotalRows = pagination.totalCount;
        if (pagination.totalCount > pagination.index * pagination.limit)
            this.getCoWorkers(model);
    }

    DropDownSearchCoWorker(value) {
        if (value != null && value != "") {
            var request = new RequestCoWorker();
            request.Name = value;
            this.getCoWorkers(request);
        } else {
            let coWorker = new RequestCoWorker();
            coWorker.IsDeleted = false;
            this.getCoWorkers(coWorker);
        }
    }

    onSubmit() {
        this.notificationForm.markAllAsTouched();
        this.isSubmitted = true;
        if (this.notificationForm.valid) {
            this.changeSettingsForPlanningNotifications()
        }
    }

    setSettingsForVehiclePlanningNotifications() {
        if (this.SendBatteryPercentageReminderSetting && this.SendBatteryPercentageReminderSetting != null) {
            this.PlanningNotificationsObj = this.SendBatteryPercentageReminderSetting;
            this.settingValue = JSON.parse(this.PlanningNotificationsObj.value)
            this.notificationForm.patchValue(this.settingValue);
            this.notificationForm.controls['CoworkersID'].setValue(this.notificationForm.controls['CoworkersID'].value.split(','))
            this.changeInLocalStorage(this.PlanningNotificationsObj.id, this.PlanningNotificationsObj)
        }

    }

    IsEnableVehicleBatteryReminder(event: MatSlideToggleChange) {
        this.settingValue.IsEnable = event.checked;
        this.changeSettingsForPlanningNotifications();
    }

    setSettingsAfterUpdate() {
        this.settingsService.GetSettingsWithTemplateValue<ResponseBase>("Notification", 'SendBatteryPercentageReminder', null).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.result && response.result.myResponse.length > 0) {
                    this.PlanningNotificationsObj = response.result.myResponse[0];
                    this.settingValue = JSON.parse(this.PlanningNotificationsObj.value)
                    this.notificationForm.patchValue(this.settingValue);
                    this.notificationForm.controls['CoworkersID'].setValue(this.notificationForm.controls['CoworkersID'].value.split(','))
                    this.changeInLocalStorage(this.PlanningNotificationsObj.id, this.PlanningNotificationsObj)
                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    changeSettingsForPlanningNotifications() {
        this.showSpinner = true;
        this.settingValue.CoworkersID = this.notificationForm.value.CoworkersID.toString();
        this.settingValue.PercentageToSend = this.notificationForm.value.PercentageToSend;
        this.settingValue.VoltageToSend = this.notificationForm.value.VoltageToSend;
        this.settingValue.IsCreateReminder = this.notificationForm.value.IsCreateReminder;
        this.settingValue.IsSendSMS = this.notificationForm.value.IsSendSMS;
        this.settingValue.IsSendNotification = this.notificationForm.value.IsSendNotification;
        this.settingValue.IsSendEmail = this.notificationForm.value.IsSendEmail;
        this.settingValue.SMSText = this.notificationForm.value.SMSText || '';
        this.settingValue.NotificationText = this.notificationForm.value.NotificationText || '';
        this.settingValue.ExecuteTime = this.notificationForm.value.ExecuteTime || '';
        this.settingValue.IsEnable = this.notificationForm.value.IsEnable;
        this.settingValue.NumberofDays = this.notificationForm.value.NumberofDays;
        this.PlanningNotificationsObj.value = JSON.stringify(this.settingValue);

        this.settingsService.UpdateSettingWithTemplate<ResponseBase>(this.PlanningNotificationsObj).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.result) {
                    this.PlanningNotificationsObj = response.result;
                    if (this.settingValue.IsEnable)
                        this.setSettingsAfterUpdate();
                    this.translate.get("MESSAGES.Saved").subscribe({
                        next: (res) => {
                            this.notificationsService.create(res);
                        },
                        error: err => { }
                    });
                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinner = false;
                this.isSubmitted = false;
            }
        });
    }

    changeInLocalStorage(ID, new_obj) {
        if (this.settingMasterValues.find(obj => obj.id == ID)) {
            this.settingMasterValues.forEach((old_obj, index) => {
                if (old_obj.id == ID) {
                    this.settingMasterValues[index] = new_obj
                }
            })
        } else {
            this.settingMasterValues.push(new_obj)
        }
        localStorage.setItem("settingMasterValues", JSON.stringify(this.settingMasterValues));
    }

    EmailTemplateDialog() {
        const dialogRef = this.dialog.open(SettingEmailTempalteEditorComponent, {
            height: '90%',
            width: '90%',
            data: { settingValue: this.settingValue, IsOther: false, IsBday: false, IsSameDay: false,Note: 'Note.BatterynotificationEmailTemplateNote' }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result != null && result != '') {
                this.settingValue = result;
                this.onSubmit();
            }
        });
    }
}