export const locale = {
    lang: 'ds',
    data: {
        'TITLE': {
            'HeaderForBettery': 'Notifikation ved manglende kontrol',
        },
        'FIELDS': {
            'HoursToSend': 'Send kl.',
            'PercentageToSend': 'Send x procent før',
            'VoltageToSend': 'Send x volt før',
            'SMSText': 'SMS tekst',
            'NotificationText': 'Notifikations tekst',
            'EmailText': 'E-mail tekst',
            'ExecuteTime':'Udfør tid',
            'IsCreateReminder':'Opret påmindelse?',
            'BetteryNoteLabel':'Fjern venligst ikke @BatteryLowDeviceList fra Email skabelon tekst.',
            'NumberofDays':'Antal dage',
        },
        'VALIDATIONS': {
            'HoursToSend': 'Timer at sende er påkrævet',
            'PercentageToSend': 'procent at sende er påkrævet',
            'VoltageToSend': 'volt at sende er påkrævet',
            'SMSText': 'SMS-tekst er påkrævet',
            'NotificationText': 'Notifikations tekst er påkrævet',
            'EmailText': 'E-mail-tekst er påkrævet',
            'ExecuteTime':'Udførelse tid er påkrævet'
        },
        'MESSAGES': {
            'Saved': 'Gemt succesfuldt.',
        },
        'BUTTONS': {
            'Save': 'Gem',
        }
    }
};
