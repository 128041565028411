import { Component, OnInit, ElementRef, NgZone, Input, Inject, Optional } from '@angular/core';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { CustomersService } from 'app/main/pages/customer/customer.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Customer } from 'app/main/model/Customer';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ErrorService } from 'app/core/services/error.service';
import { AuthService } from 'app/core/services/auth.service';
import { ProjectRequisitionStatusLog, ProjectRequisitionStatusLogEnum } from 'app/main/model/Project';
import { ProjectIndoorOutDoorStatus, WhereDoesTheCustomerComeFrom } from 'app/main/model/ProjectStatus';
import { RequestProject, RequestCoWorker, SMSModules } from 'app/main/model/RequestBase';
import { TitleService } from 'app/core/services/title.service';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormService } from 'app/core/services/form.service';
import { CustomerFile } from 'app/main/model/Customer';
import { ProjectService } from 'app/main/pages/project/project.service';
import { CreateFolder, SearchFolder, ListFolder, FilesObj, FolderListNavigationDTO } from 'app/main/model/DropBox';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { TextEditorEmailSendComponent } from 'app/main/shared-component/text-editor-email-send/text-editor-email-send.component';
import { EmailSendingParam } from 'app/main/model/common';
import { LogStatusModuleName } from 'app/core/enum/logFile.enum';
import * as moment from 'moment';
import { CoWorkersService } from 'app/main/pages/coWorker/coWorkers.service';
import { CommonService } from 'app/core/services/common.service';
import { SendSMSComponent } from 'app/main/shared-component/send-sms/send-sms.component';
import { SMSModuleEnum } from 'app/core/enum/sms-module.enum';
import { CountryJson } from 'app/constant/flag.constant';
import { CustomErrorStateMatcher, spaceOnlyValidator } from 'app/core/Validators/validation';
import { DropboxFilesService } from 'app/main/shared-component/dropbox-files/dropbox-files.service';
import { DriveModuleEnum } from 'app/core/enum/drive-module.enum';

@Component({
    selector: 'app-new-customer',
    templateUrl: './new-customer.component.html',
    styleUrls: ['./new-customer.component.scss'],
})

export class NewCustomerComponent implements OnInit {
    customerIdForLog: string = "";
    @Input() InvoiceCustomerId: string;
    indoorOutdoorStatusIds: string;
    selectIndoorOutDoorStatus = ProjectIndoorOutDoorStatus;
    customerOrders: any
    Message: string;
    //isSavedata: any;
    id: number;
    name: string;
    all: boolean = true;
    //IsDisplay: boolean = false;
    host: string = '';
    // roleName: string;
    // savedata: any;
    CustomerObject: Customer;
    customerFormTab1: FormGroup;
    customerFormTab2: FormGroup;
    selectedIndex: number = 0;
    showSpinner = false;
    autocomplete: any;
    IsEditCall: boolean = false;
    setData: any = {};
    isDataSave: boolean = false;
    objAddressEdit: any = {};
    projects = [];
    IsAdd: boolean = true;
    public latitude: number;
    public longitude: number;
    public searchControl: FormControl;
    public zoom: number;
    //IsAdmin: boolean = false;
    files: CustomerFile[] = [];
    IsRedirectToPopup: boolean = false;
    customerId: string = '';
    CustomerNumber: string = '';
    projectRequisitionStatusLogList: ProjectRequisitionStatusLog[] = [];
    //dropbox declaration start
    res: any;
    createFolder: CreateFolder;
    searchFolder: SearchFolder;
    listFolder: ListFolder
    renterForm: FormGroup;
    fileObjList: Array<FilesObj>;
    checkedList: any;
    fileFolderPathData: Array<FolderListNavigationDTO> = [];
    displayPagination: boolean;
    logListPagination: any;
    whereDoesTheCustomerComeFrom = WhereDoesTheCustomerComeFrom;
    After5DayDate: string = "";
    TodayDate: string = "";
    Befor60DaysDate: string = "";
    customerEmailTemplate: any;
    coWokers = [];
    showMessageForCoworker: boolean = false;
    AllCoworkers: any = [];
    UserName: string = "";
    countryList = CountryJson;
    tempCountryList = CountryJson;
    CountryName: string = "";
    isActive: boolean = false;
    //dropbox declaration end
    isFile: boolean = false;
    isCommingFrom = "Materials";
    isProjectActiveInactive: boolean = false;
    pagination: any;
    public editorOptions = {
        placeholder: "Kommentarer  [ Valgfri ]"
    };
    IsCustomerNumberAlreadyExist: boolean = false;
    CustomerId: string = "";
    reqTabIndex: number = 0;
    customers: any[];
    roleClaims: any;

    @Input() IsValidateAddressForm: boolean = true;
    isSubmitted: boolean = false;
    esMatcher = new CustomErrorStateMatcher();
    hasCustomerProjectTabPermision: boolean = false;
    hasCustomerFilesTabPermision: boolean = false;
    hasCustomerLogTabPermision: boolean = false;
    hasCustomeColorCodeTabPermision: boolean = false;
    hasCustomerMessageLogTabPermision: boolean = false;
    hasCustomerPropertiesTabTabPermision: boolean = false;
    hasCustomerMaterialsTabPermision: boolean = false;
    hasCustomerProjectRecuringSettingsTabPermision: boolean = false;

    // requestModel: RequestCustomer = new RequestCustomer();
    constructor(private translationLoader: FuseTranslationLoaderService, private elem: ElementRef,
        private customerService: CustomersService,
        private _route: ActivatedRoute,
        private projectService: ProjectService,
        private _router: Router,
        private formBuilder: FormBuilder,
        private errorService: ErrorService,
        private authService: AuthService,
        private titleService: TitleService,
        public dialog: MatDialog,
        public _ngZone: NgZone,
        private notificationsService: NotificationsService,
        private translate: TranslateService,
        private coWorkersService: CoWorkersService,
        private commonService: CommonService,
        @Optional() public dialogRef: MatDialogRef<NewCustomerComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
        private formService: FormService,
        private dropboxFilesService: DropboxFilesService) {
        this.translationLoader.loadTranslations(english, danish);
        this.roleClaims = this.authService.getRoleClaimsList();
        this.CustomerObject = new Customer();
        window["customerComponent"] = { component: this, zone: this._ngZone };
        if (!data)
            this.titleService.SetTitle("TITLE_CUSTOMERS");
        try {
            var tempData = this._router.getCurrentNavigation().extras.state;
            if (tempData && tempData.moduleDataList)
                this.customers = tempData.moduleDataList;
        } catch (error) { }
    }

    ngOnInit() {
        this.authService.hasClaim(this.roleClaims.Customer_Project_Tab).subscribe(hasClaim => {
            if (hasClaim) 
                this.hasCustomerProjectTabPermision = hasClaim
             else 
                this.hasCustomerProjectTabPermision = false;
        });
        this.authService.hasClaim(this.roleClaims.Customer_Files_Tab).subscribe(hasClaim => {
            if (hasClaim) 
                this.hasCustomerFilesTabPermision = hasClaim
             else 
                this.hasCustomerFilesTabPermision = false;
        });
        this.authService.hasClaim(this.roleClaims.Customer_Log_Tab).subscribe(hasClaim => {
            if (hasClaim) 
                this.hasCustomerLogTabPermision = hasClaim
             else 
                this.hasCustomerLogTabPermision = false;
        });
        this.authService.hasClaim(this.roleClaims.Customer_ColorCode_Tab).subscribe(hasClaim => {
            if (hasClaim) 
                this.hasCustomeColorCodeTabPermision = hasClaim
             else 
                this.hasCustomeColorCodeTabPermision = false;
        });
        this.authService.hasClaim(this.roleClaims.Customer_Message_Log_Tab).subscribe(hasClaim => {
            if (hasClaim) 
                this.hasCustomerMessageLogTabPermision = hasClaim
             else 
                this.hasCustomerMessageLogTabPermision = false;
        });
        this.authService.hasClaim(this.roleClaims.Customer_Properties_Tab).subscribe(hasClaim => {
            if (hasClaim) 
                this.hasCustomerPropertiesTabTabPermision = hasClaim
             else 
                this.hasCustomerPropertiesTabTabPermision = false;
        });
        this.authService.hasClaim(this.roleClaims.Customer_Materials_Tab).subscribe(hasClaim => {
            if (hasClaim) 
                this.hasCustomerMaterialsTabPermision = hasClaim
             else 
                this.hasCustomerMaterialsTabPermision = false;
        });
        this.authService.hasClaim(this.roleClaims.Customer_Project_Recuring_Settings_Tab).subscribe(hasClaim => {
            if (hasClaim) 
                this.hasCustomerProjectRecuringSettingsTabPermision = hasClaim
             else 
                this.hasCustomerProjectRecuringSettingsTabPermision = false;
        });
        this.UserName = this.authService.getUserName();
        this.fileObjList = Array<FilesObj>();
        this.checkedList = new Array();
        this.zoom = 15;
        this.latitude = 55.528090;
        this.longitude = 11.495900;
        // this.roleName = this.authService.getUserRole();
        // if (this.roleName == "Admin") {
        //     this.savedata = true;
        //     this.IsAdmin = true;
        // }
        // else if (this.roleName == "Kontor") {
        //     this.savedata = true;
        // }
        // else if (this.roleName == "Maler") {
        //     this.savedata = false;
        // }
        // else if (this.roleName == "Gaest") {
        //     this.savedata = false;
        // }
        // else {
        //     this.savedata = true;
        // }
        this._route.data.subscribe(v => { this.IsAdd = v.IsAdd ? true : false; });
        this._route.params.subscribe(params => {
            var id = params['id'];
            this.CustomerId = id;
            if (id === undefined) {
                //this.IsRedirectToPopup = true;
                id = this.InvoiceCustomerId;
            }
            if (id === undefined) {
                this.CustomerObject = new Customer();
                //this.IsDisplay = true;
                this.customerFormTab1 = this.formBuilder.group({
                    Text: [null, Validators.compose([Validators.required, Validators.maxLength(40), spaceOnlyValidator.bind(this)])],
                    // Email: [null, Validators.compose([Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"), Validators.maxLength(80),IsEmailExistValidationForCustomer(this.customerService)])],
                    // Email: [null, Validators.compose([Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"), Validators.maxLength(80)])],
                    EmailInvoice: [null, Validators.compose([Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$"), Validators.maxLength(80)])],
                    // ContactNumber: [null, Validators.compose([Validators.required, Validators.maxLength(8)])],
                    ComingFrom: [null,[Validators.required]],
                    colorCode: [null],
                    indoorOutdoorStatus: [null],
                    comment: [null],
                    discountPercent: [0],
                    RequisitionNumber: [null],
                    CountryCode: [null],
                    doNotCalculatePriceForPriceDiscount: [false],
                    doNotCalculatePriceForPostNumber: [false],
                    CustomerNumber: [null],
                    isSendInvoiceBeforeFinished:[false],
                });

                // if (this.IsValidateAddressForm) {
                //     //this.customerFormTab1 = this.formBuilder.group({
                //     //mapLocation: [null, Validators.compose([Validators.required, spaceOnlyValidator.bind(this)])],
                //     this.customerFormTab1.addControl('mapLocation', new FormControl(null));
                //     this.customerFormTab1.addControl('address', new FormControl(null, Validators.compose([Validators.required, spaceOnlyValidator.bind(this)])));
                //     this.customerFormTab1.addControl('pinCode', new FormControl(null, Validators.compose([Validators.required, spaceOnlyValidator.bind(this)])));
                //     this.customerFormTab1.addControl('city', new FormControl(null, Validators.compose([Validators.required, spaceOnlyValidator.bind(this)])));
                //     this.customerFormTab1.addControl('position', new FormControl(null));
                //     //});
                // }
                // else {
                //     // this.customerFormTab1 = this.formBuilder.group({
                //     this.customerFormTab1.addControl('mapLocation', new FormControl(null));
                //     this.customerFormTab1.addControl('address', new FormControl(null));
                //     this.customerFormTab1.addControl('pinCode', new FormControl(null));
                //     this.customerFormTab1.addControl('city', new FormControl(null));
                //     this.customerFormTab1.addControl('position', new FormControl(null));
                //     // });
                // }
            }
            else {
                this.customerIdForLog = id;
                this.customerId = id;
                //this.IsDisplay = false;
                this.Edit(id, false);
                this.GetProjectsByCustomer(id, new RequestProject());
                //this.GetCustomerFiles(id);
                this.customerFormTab1 = this.formBuilder.group({
                    Text: [null, Validators.compose([Validators.required, Validators.maxLength(40), spaceOnlyValidator.bind(this)])],
                    // Email: [null, Validators.compose([Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"), Validators.maxLength(80)])],
                    EmailInvoice: [null, Validators.compose([Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$"), Validators.maxLength(80)])],
                    // ContactNumber: [null, Validators.compose([Validators.required, Validators.maxLength(8)])],
                    ComingFrom: [null,[Validators.required]],
                    colorCode: [null],
                    indoorOutdoorStatus: [null],
                    comment: [null],
                    discountPercent: [0],
                    RequisitionNumber: [null],
                    CountryCode: [null],
                    doNotCalculatePriceForPriceDiscount: [false],
                    doNotCalculatePriceForPostNumber: [false],
                    CustomerNumber: [null],
                    isSendInvoiceBeforeFinished: [false],
                });
            }
        });
        this._route.queryParams.subscribe(params => {
            this.selectedIndex = params['tabIndex'] ? params['tabIndex'] : this.selectedIndex;
            this.reqTabIndex = params['reqTabIndex'] ? parseInt(params['reqTabIndex']) : this.selectedIndex;
        });
        this._route.params.subscribe(params => {
            var id = params['id'];
            this.CustomerId = id;
            if (id === undefined) {
                id = this.InvoiceCustomerId;
            }
            if (id === undefined) {
                this.CustomerObject = new Customer();
                // this.customerFormTab1.addControl('ContactNumber', new FormControl('', [Validators.required, Validators.maxLength(8)], [IsPhoneNoValidationForCustomer(this.customerService)])),
                // this.customerFormTab1.addControl('Email', new FormControl('', [Validators.required, spaceOnlyValidator.bind(this), Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)], [IsEmailExistValidationForCustomer(this.customerService)]))
                this.customerFormTab1.addControl('ContactNumber', new FormControl('', [Validators.required, Validators.maxLength(8)])),
                this.customerFormTab1.addControl('Email', new FormControl('', [Validators.required, spaceOnlyValidator.bind(this), Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]))
            }
            else {
                this.customerFormTab1.addControl('ContactNumber', new FormControl('', [Validators.required, Validators.maxLength(8)])),
                this.customerFormTab1.addControl('Email', new FormControl('', [Validators.required, spaceOnlyValidator.bind(this), Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]))
            }

        });
        if (this.data) {
            this.IsRedirectToPopup = true;
            if (this.data.isRedirectToInquiry && !this.data.isAdd) {
                //this.savedata = false;
                this.Edit(this.data.id, false);
            }
            else if (this.data.isRedirectToInquiry && this.data.isAdd) {
                this.GetInquiryDetail(this.data.id);
            }
            else {
                this.CustomerObject.phone = this.data.phone;
                this.CustomerObject.name = this.data.name;
                this.CustomerObject.email = this.data.email;
                this.CustomerObject.emailInvoice = this.data.email;
                this.CustomerObject.address = this.data.address;
                this.CustomerObject.city = this.data.city;
                this.CustomerObject.pinCode = this.data.pinCode;
                this.objAddressEdit = {
                    'latitude': "",
                    'longitude': "",
                    'address': this.data.address,
                    'city': this.data.city,
                    'pinCode': this.data.pinCode,
                    'mapLocation': "",
                    'IsEdit': true
                }
            }
            if (this.data.isRedirectToOffer == false) {
                this.titleService.SetTitle("TITLE_CUSTOMER");
            }
        }
        this.TodayDate = moment(new Date()).format('YYYY-MM-DD');
        this.After5DayDate = moment(this.TodayDate, "YYYY-MM-DD").add(5, 'days').format('YYYY-MM-DD');
        this.Befor60DaysDate = moment(this.TodayDate, "YYYY-MM-DD").add(-60, 'days').format('YYYY-MM-DD');

        let coWorker = new RequestCoWorker();
        coWorker.IsDeleted = false;
        this.getCoWorkers(coWorker);
        if (this.reqTabIndex > 0)
            this.selectedIndex = this.reqTabIndex;
        // this.requestModel = this.customerService.getRequestParameter();
        // this.isActive = this.requestModel.IsActive;
        // this.GetCustomer();
    }

    numberPress() {
        this.CustomerObject.number = this.CustomerNumber;
    }

    checkAccess(event) {
        this.EditCustomer(event.sagId);
        this.isActive = event.isActive;
    }
    EditCustomer(id) {
        this._router.navigate(['/projects/edit', id], { queryParams: { isActive: this.isActive } }); //navigationExtras
    }

    ngAfterViewInit() {
        //setTimeout(() => {

        //this.Handle();
        //}, 2500);
    }

    save() {
        this.isSubmitted = true;
        this.esMatcher.isSubmitted = this.isSubmitted;
        setTimeout(() => {
            this.formService.markFormGroupTouched(this.customerFormTab1);
            if (this.isSubmitted && this.customerFormTab1.valid && this.esMatcher.isValid) {
                if (this.CustomerObject.id === '') {
                    this.Add();
                }
                else {
                    this.Update();
                }
            }
        }, 500);
    }

    Add() {
        this.showSpinner = true;
        this.CustomerObject.indoorOutdoorStatusId = "";
        if (this.CustomerObject.indoorOutdoorStatusIds) {
            this.CustomerObject.indoorOutdoorStatusIds.filter(x => {
                this.CustomerObject.indoorOutdoorStatusId = (this.CustomerObject.indoorOutdoorStatusId ? this.CustomerObject.indoorOutdoorStatusId + "," : "") + x.id;
            });
        }
        if (this.CustomerObject.isCustomerNumberAuto) {
            this.CustomerObject.number = null;
        }
        this.customerService.Add<ResponseBase>(this.CustomerObject)
            .subscribe({
                next: (response: ResponseBase) => {
                    if (response.result) {
                        this.CustomerObject = response.result;
                        this.customerId = this.CustomerObject.id;
                        this.CustomerNumber = this.CustomerObject.number;
                        this.CustomerObject.mapLocation = this.CustomerObject.address ? this.CustomerObject.address : "" + " " + this.CustomerObject.city ? this.CustomerObject.city : "" + " " + this.CustomerObject.pinCode ? this.CustomerObject.pinCode : "";
                        if (this.CustomerObject.position) {
                            let latLongString = this.CustomerObject.position.split(',')
                            this.latitude = parseFloat(latLongString[0].replace("latitude=", '').trim())
                            if (latLongString.length > 1)
                                this.longitude = parseFloat(latLongString[1].replace("longitude=", '').trim())
                        }
                        this.objAddressEdit = {
                            'latitude': this.latitude,
                            'longitude': this.longitude,
                            'address': this.CustomerObject.address,
                            'city': this.CustomerObject.city,
                            'pinCode': this.CustomerObject.pinCode,
                            'position': this.CustomerObject.position,
                            'mapLocation': this.CustomerObject.mapLocation,
                            'IsEdit': true
                        }
                        this.GetProjectsByCustomer(response.result.id, new RequestProject());
                        this.setIndoorOutDoorDropDown();
                        this.storeFileFolderPathData();
                    }
                    this.showSpinner = false;
                    this.isDataSave = true;
                    //this.IsDisplay = false;
                    this.translate.get("MESSAGES.Created").subscribe({
                        next: (res) => {
                            this.notificationsService.create(res);
                            if (this.IsRedirectToPopup) {
                                this.dialogRef.close(this.CustomerObject.id);
                            }
                        },
                        error: err => {
                        }
                    });
                },
                error: err => {
                    this.showSpinner = false;
                    if (err.status != 409)
                        this.translate.get("MESSAGES.Failed").subscribe({
                            next: (res) => {
                                this.notificationsService.error(res);
                            },
                            error: err => {
                            }
                        });

                },
                complete: () => {
                    this.isSubmitted = false;
                    if (this.data && this.data.isRedirectToInquiry) {
                        this.dialogRef.close(this.CustomerObject.id);
                        return;
                    }
                    if (this.data && this.data.isRedirectToOffer) {
                        this.dialogRef.close(this.CustomerObject.id);
                        return;
                    }
                    if (this.selectedIndex == 1) {
                        if (!this.IsRedirectToPopup) {
                            this._router.navigateByUrl('customers');
                            return;
                        }
                    }
                    if (this.selectedIndex == 0) {
                        this._router.navigate(['/customers/edit', this.CustomerObject.id]);
                    }

                    //this.selectedIndex = 1;
                    this.isDataSave = true;
                }
            });
    }

    changEmail(newValue: any) {
        if (!this.CustomerObject.id) {
            this.CustomerObject.emailInvoice = this.CustomerObject.email;
        }

    }

    // isSave() {
    //     if (this.savedata == "false") {
    //         return false;
    //     }
    //     else {
    //         return true;
    //     }
    // }

    Update() {
        this.CustomerObject.indoorOutdoorStatusId = "";
        if (this.CustomerObject.indoorOutdoorStatusIds) {
            this.CustomerObject.indoorOutdoorStatusIds.filter(x => {
                this.CustomerObject.indoorOutdoorStatusId = (this.CustomerObject.indoorOutdoorStatusId ? this.CustomerObject.indoorOutdoorStatusId + "," : "") + x.id;
            });
        }
        this.formService.markFormGroupTouched(this.customerFormTab1);
        //this.formService.markFormGroupTouched(this.customerFormTab2);
        if (this.customerFormTab1.valid) {
            this.showSpinner = true;
            this.customerService.Update<ResponseBase>(this.CustomerObject.id, this.CustomerObject)
                .subscribe({
                    next: (response: ResponseBase) => {
                        if (response.result) {
                            this.customerId = this.CustomerObject.id;
                            this.CustomerObject = response.result;
                            this.CustomerNumber = this.CustomerObject.number;
                            this.setIndoorOutDoorDropDown();
                        }
                        this.showSpinner = false;
                        this.storeFileFolderPathData();
                        this.translate.get("MESSAGES.Updated").subscribe({
                            next: (res) => {
                                this.notificationsService.create(res);
                            },
                            error: err => {
                            }
                        });
                    },
                    error: err => {
                        this.showSpinner = false;
                        if (err.status != 409) {
                            this.translate.get("MESSAGES.Failed").subscribe({
                                next: (res) => {
                                    this.notificationsService.error(res);
                                },
                                error: err => {
                                }
                            });
                        }
                    },
                    complete: () => {
                        this.isSubmitted = false;

                        if (this.selectedIndex == 1) {
                            if (this.selectedIndex == 1) {
                                if (this.IsRedirectToPopup) {
                                    this.dialogRef.close(this.CustomerObject.id);
                                }
                                else {
                                    this._router.navigateByUrl('customers');
                                }
                            }
                        }
                        //this.selectedIndex = 1;
                        //this._router.navigateByUrl('customers');
                    }
                });
        }
    }

    Edit(id, isAddFromProject) {
        this.isDataSave = true;
        this.showSpinner = true;
        this.IsEditCall = true;
        this.customerService.GetSingle<ResponseBase>(id)
            .subscribe({
                next: (response: ResponseBase) => {
                    var customerResponse = new Customer();
                    customerResponse = response.result;
                    customerResponse.countryCode = customerResponse.countryCode ? customerResponse.countryCode : '+45';
                    if (isAddFromProject)
                        this.CustomerObject = customerResponse;
                    else
                        this.CustomerObject = customerResponse;
                    if (!this.CustomerObject.emailInvoice) {
                        this.CustomerObject.emailInvoice = this.CustomerObject.email;
                    }

                    this.customerId = this.CustomerObject.id;
                    this.CustomerObject.mapLocation = this.CustomerObject.address ? this.CustomerObject.address : "" + " " + this.CustomerObject.city ? this.CustomerObject.city : "" + " " + this.CustomerObject.pinCode ? this.CustomerObject.pinCode : "";
                    this.CustomerNumber = this.CustomerObject.number;

                    if (this.CustomerObject.position) {
                        let latLongString = this.CustomerObject.position.split(',')
                        this.latitude = parseFloat(latLongString[0].replace("latitude=", '').trim())
                        if (latLongString.length > 1)
                            this.longitude = parseFloat(latLongString[1].replace("longitude=", '').trim())
                    }
                    this.objAddressEdit = {
                        'latitude': this.latitude,
                        'longitude': this.longitude,
                        'address': this.CustomerObject.address,
                        'city': this.CustomerObject.city,
                        'pinCode': this.CustomerObject.pinCode,
                        'position': this.CustomerObject.position,
                        'mapLocation': this.CustomerObject.mapLocation,
                        'IsEdit': true
                    }
                    this.setIndoorOutDoorDropDown();
                    this.storeFileFolderPathData();
                    this.showSpinner = false;
                },
                error: err => {
                    this.showSpinner = false;
                },
                complete: () => {

                }
            });
    }

    GetInquiryDetail(id) {
        this.isDataSave = true;
        this.showSpinner = true;
        this.IsEditCall = true;
        this.customerService.GetInquiryDetail<ResponseBase>(id)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.showSpinner = false;
                    this.CustomerObject = response.result;
                    this.CustomerObject.id = "";
                    this.CustomerObject.number = "";
                    this.CustomerObject.phone = response.result.phoneNo;
                    this.CustomerObject.pinCode = response.result.postNo;
                    //this.CustomerObject.comingFrom = response.result.source;
                    this.CustomerObject.isCustomerNumberAuto = true;
                    this.isDataSave = false;
                    this.CustomerObject.mapLocation = response.result.address ? response.result.address : "" + " " + response.result.city ? response.result.city : "" + " " + response.result.postNo ? response.result.postNo : "";
                    this.setIndoorOutDoorDropDown();
                    this.objAddressEdit = {
                        'latitude': '',
                        'longitude': '',
                        'address': response.result.address,
                        'city': response.result.city,
                        'pinCode': response.result.postNo,
                        'position': '',
                        'mapLocation': '',
                        'IsEdit': true
                    }
                },
                error: err => {
                    this.showSpinner = false;
                },
                complete: () => {

                }
            });
    }

    setIndoorOutDoorDropDown() {
        this.CustomerObject.indoorOutdoorStatusIds = new Array();
        if (this.CustomerObject.indoorOutdoorStatusId) {
            if (this.CustomerObject.indoorOutdoorStatusId.includes(',')) {
                var SplitIndoorOutdoorStatusId = this.CustomerObject.indoorOutdoorStatusId.split(',');
                this.selectIndoorOutDoorStatus.filter(x => {
                    SplitIndoorOutdoorStatusId.filter(y => {
                        if (parseInt(y) == x.id)
                            this.CustomerObject.indoorOutdoorStatusIds.push(x);
                    })
                });
            }
            else {
                this.selectIndoorOutDoorStatus.filter(x => {
                    if (parseInt(this.CustomerObject.indoorOutdoorStatusId) == x.id)
                        this.CustomerObject.indoorOutdoorStatusIds.push(x);
                });
            }
        }
    }

    isNumber(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    autoCompleteCallback1(selectedData: any) {

    }

    TabChange(event) {
        this.selectedIndex = event.index;
        if (event.index == 2) {
            this.storeFileFolderPathData();
        }
    }

    receiveAddressObject($event) {
        this.CustomerObject.address = $event.address
        this.CustomerObject.city = $event.city
        this.CustomerObject.pinCode = $event.pinCode
        this.CustomerObject.position = $event.position
    }

    onSetActive(IsActive) {
        this.CustomerObject.isActive = IsActive;
    }

    ngOnBackClick() {
        if (this.IsRedirectToPopup) {
            this.dialogRef.close(this.CustomerObject.id);
        }
        else {
            this._router.navigate(['customers']);
        }
    }

    GetProjectsByCustomer(id: any, model: RequestProject) {
        this.showSpinner = true;
        model.IsActive = this.isProjectActiveInactive;
        model.IsDeleted = false;
        model.CustomerId = id;
        this.customerService.GetProjectsByCustomer<ResponseBase>(id, model)
            .subscribe((response: ResponseBase) => {
                if (response != null) {
                    this.projects = response.result;
                    this.pagination = response.pagination;
                    if (this.projects.length > 0) {
                        this.projectService.setBGImageForProjectCards(this.projects);
                        for (var i = 0; i < this.projects.length; i++) {
                            var formateDate = moment(this.projects[i].startDate).format('YYYY-MM-DD');
                            if (this.After5DayDate >= formateDate)
                                this.projects[i].displayStartDateRed = true;
                            else
                                this.projects[i].displayStartDateRed = false;
                        }
                    }
                    this.showSpinner = false;
                }
                else {
                    this.projects = [];
                }
            },
                error => (err) => {
                    this.projects = [];
                    this.showSpinner = false;
                },
                () => {
                    this.showSpinner = false;
                });
    }

    EditProject(id) {
        this._router.navigate(['/projects/edit', id]); //navigationExtras
    }

    ShowProject(flag) {
        this.isProjectActiveInactive = flag;
        this.projects = [];
        this.GetProjectsByCustomer(this.customerId, new RequestProject);
    }

    DeleteCustomer(Id) {
        this.showSpinner = true;
        this.customerService.Delete<ResponseBase>(Id).subscribe({
            next: (response: ResponseBase) => {
                this.translate.get("MESSAGES.Deleted").subscribe({
                    next: (res) => {
                        this.notificationsService.create(res);
                    },
                    error: err => {
                    }
                });
            },
            error: err => {
                this.translate.get("MESSAGES.Failed").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                    },
                    error: err => {
                    }
                });

            },
            complete: () => {
                this.showSpinner = false;
                if (!this.IsRedirectToPopup)
                    this._router.navigate(['/customers']);
            }
        });
    }

    removeCustomer(Id) {
        const dialogRef = this.dialog.open(AlertMessageModelComponent, {
            height: '200px',
            data: { Header: 'Headers.DeleteCustomer' }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.DeleteCustomer(Id);
            }
        });
    }


    emailCustomer(id) {
        var emailSendingParam = new EmailSendingParam();
        emailSendingParam.fromName = this.authService.getUserName();
        // emailSendingParam.from = "post@vibesmalerfirma.dk";
        emailSendingParam.to = this.CustomerObject.email;
        emailSendingParam.moduleName = "Customer";
        emailSendingParam.moduleID = this.customerId;
        emailSendingParam.isLoadDefaultEmailTemplate = true;
        // emailSendingParam.body = this.customerEmailTemplate;
        emailSendingParam.coworkerList = this.coWokers;
        const dialogRef = this.dialog.open(TextEditorEmailSendComponent, {
            height: '90%',
            width: '90%',
            data: emailSendingParam
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result != null && result != '') {

                var tempLogStatusToSendEmail = new EmailSendingParam();
                tempLogStatusToSendEmail = result;
                var ProjectRequisitionStatusLogModel = new ProjectRequisitionStatusLog();

                ProjectRequisitionStatusLogModel.createdBy = this.authService.getCoWorkerId();
                ProjectRequisitionStatusLogModel.StatusLogCaseValue = ProjectRequisitionStatusLogEnum.EmailSentFromCustomer;
                ProjectRequisitionStatusLogModel.status = LogStatusModuleName.Customer;
                ProjectRequisitionStatusLogModel.debitorID = this.CustomerObject.id;
                tempLogStatusToSendEmail.ProjectRequisitionStatusLogModel = ProjectRequisitionStatusLogModel;

                this.customerService.SendEmail<ResponseBase>(tempLogStatusToSendEmail)
                    .subscribe({
                        next: (response: ResponseBase) => {
                            this.showSpinner = false;
                            this.translate.get("MESSAGES.EmailSent").subscribe({
                                next: (res) => {
                                    this.notificationsService.create(res);
                                },
                                error: err => {
                                }
                            });
                            this.commonService.checkEmailIsToBeSent();
                        },
                        error: err => {
                            this.showSpinner = false;
                            this.translate.get("MESSAGES.Failed").subscribe({
                                next: (res) => {
                                    this.notificationsService.error(res);
                                },
                                error: err => {
                                }
                            });
                        },
                        complete: () => {
                        }
                    });
            }
        });
    }



    getCoWorkers(model) {
        model.Limit = 400;
        model.OrderBy = "Name";
        this.coWorkersService.Get<ResponseBase>(model)
            .subscribe((response: ResponseBase) => {
                if (response) {
                    this.getNextRequestForCoWorker(response.pagination, model, response.result);
                }
                else {
                    this.coWokers = [];
                }
            },
                error => (err) => {
                    this.coWokers = [];
                },
                () => {
                });
    }

    getNextRequestForCoWorker(pagination, model, response) {
        if (pagination.index == 1) {
            this.coWokers = response;
            //this.AllCoworkers = response;
        }
        else {
            this.coWokers.push(...response);
            //this.AllCoworkers.push(...response);
        }
        model.Index = pagination.index + 1;
        model.Limit = pagination.limit;
        model.TotalRows = pagination.totalCount;
        if (pagination.totalCount > pagination.index * pagination.limit)
            this.getCoWorkers(model);
    }

    sendSMS() {
        var moduleObj = new SMSModules()
        moduleObj.name = this.CustomerObject.name;
        moduleObj.moduleID = this.CustomerObject.id;
        moduleObj.mobile = this.CustomerObject.phone;
        moduleObj.flag = SMSModuleEnum.Customer;
        moduleObj.countryCode = this.CustomerObject.countryCode;
        const dialogRef = this.dialog.open(SendSMSComponent, {
            height: '350px',
            width: '800px',
            data: moduleObj
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {

            }
        });
    }

    saveFileUploadLogs(file, flag) {
        this.CustomerObject.fileName = file;
        this.CustomerObject.flag = flag;
        this.customerService.SaveFileUploadLogs<ResponseBase>(this.CustomerObject)
            .subscribe({
                next: (response: ResponseBase) => {
                    if (response.result) {
                    }
                },
                error: err => {

                },
                complete: () => {

                }
            });
    }
    onSetDoNotSendNotification(IsActive) {
        this.CustomerObject.doNotSendNotification = IsActive;
    }

    setIsRequisitionNumber(flag) {
        this.CustomerObject.requisitionNumber = flag;
    }

    getFlagClass(value) {
        var country = value;
        return country.toLowerCase() + ' flag';
    }

    DropDownSearchCountry(name) {
        if (name == "" || name == undefined)
            this.countryList = this.tempCountryList;
        else
            this.countryList = this.tempCountryList.filter(it => { return it.name.toLowerCase().includes(name.toLowerCase()); });
    }

    storeFileFolderPathData() {
        var rootPath = this.dropboxFilesService.getSettingMasterValueDocumentPath();
        var paths = "";
        if (rootPath.DocumentDriveType == DriveModuleEnum.Onedrive) {
            paths = rootPath.Onedrive.Path;
        } else {
            paths = rootPath.Dropbox.Path;
        }
        this.fileFolderPathData = paths.split("/").map((path, i) => ({
            name: path,
            isDisplay: false
        }));
        this.fileFolderPathData.push(
            { name: 'Costomer', isDisplay: false },
            { name: this.CustomerObject.number, isDisplay: true }
        );
    }

    IsFileExist(flag) {
        this.isFile = flag;
    }

    // isCustomerNumberExist(itemNumber) {
    //     this.customerService.GetCustomerByNumber(itemNumber).subscribe({
    //         next: (response: ResponseBase) => {
    //             if (response) {
    //                 var responseMaterial = response.result;
    //                 if (this.CustomerObject.id != responseMaterial.id) {
    //                     this.IsCustomerNumberAlreadyExist = true;
    //                     this.translate.get("MESSAGES.CustomerNumberExist").subscribe({
    //                         next: (res) => {
    //                             this.notificationsService.error(res);
    //                         },
    //                     });
    //                 } else {
    //                     this.IsCustomerNumberAlreadyExist = false;
    //                 }
    //             }
    //         },
    //         error: err => {
    //             this.IsCustomerNumberAlreadyExist = false;
    //         },
    //     });
    // }

    oncheckCustomerNumAuto() {
        const customerForm = this.customerFormTab1.get("CustomerNumber");
        if (!this.CustomerObject.isCustomerNumberAuto) {
            customerForm.setValidators([Validators.required]);
        } else {
            customerForm.setValidators(null);
        }
        customerForm.updateValueAndValidity();
    }

    gotoCustomer() {
        this._router.navigate(['/offers/add/customer/' + this.CustomerObject.id]);
    }

    gotoModule($event) {
        this.CustomerObject = new Customer();
        this._router.navigate(['/customers/edit', $event]);
    }

    // validateEmail(control: FormControl) {
    //     return new Observable(observer => {
    //         control.valueChanges.pipe(
    //             debounceTime(1000),
    //             distinctUntilChanged(), // Prevents duplicate consecutive values
    //             switchMap(value => {
    //                 if (!value) {
    //                     return of(null); // No need to validate if no value is entered
    //                 } else {
    //                     return this.customerService.IsEmailExist(value);
    //                 }
    //             }),
    //             distinctUntilChanged()
    //         ).subscribe(
    //             (result: boolean) => {
    //                 if (result) {
    //                     observer.next({ emailExists: true }); // Email exists, return an error
    //                 } else {
    //                     observer.next(null); // Email doesn't exist, no error
    //                 }
    //                 observer.complete();
    //             }
    //         );
    //     });
    // }
}