export const locale = {
    lang: 'en',
    data: {
        'TITLE': {
        },
        'FIELDS': {
            'IsSendEmail': 'Is Send Email ?',
            'TabList': 'Tab list setting',
            'UseNanoLink': 'Use Nano Link',
            'NanolinkSetting':'Nano link settings',
            'SMSSignature': 'SMS signature',
        },
        'Message': {

        },
        'FILTERS': {
        },
        'moduleTypeList': {
        },
        'BUTTONS': {
            'Save':'Save',
        }
    }
};