export const locale = {
    lang: 'ds',
    data: {
        'Headers': {
            'NoFileSelected': 'Ingen fil valgt',
            'FOLDEREMPTY': 'Denne folder er tom',
            'UploadFile': 'Upload Fil',
            'ChooseFile': 'Vælg fil',
            'ChoosePicture': 'Vælg billede',
        },
        'BUTTONS': {
            'CREATE_FOLDER': 'Opret mappe',
            'BACK': 'Tilbage',
            'AddScanMaterial': 'Tilføj scanne materiale',
            'SAVE': 'Gem',
            'DELETE': 'Slet',
            'HIDEFOLDERFOREMPLOYEE': 'Skjul mappe til medarbejder',
        },
        'MESSAGES': {
            'Created': 'Oprettet med succes',
            'Updated': 'Opdateret med succes',
            'Deleted': 'Slettet med succes',
            'Failed': 'Operation mislykket, prøv senere',
            'FeildUnderFinishFolder': '*** Denne projektmappe er under færdigmappe.',
            'DropBoxApiNotResponding': '@DriveOptions svarer ikke, prøv venligst efter et stykke tid',
            'FileConflict': 'Filen eksisterer allerede',
            'FolderConflict': 'Mappenavn eksisterer allerede',
        },
        'FIELDS': {
            'TEXTSEARCH': 'Tekst Søg',
            'SORTING': 'Sorter efter',
            'DragAndDropFile': 'Træk og slip filen her',
            'Or': 'Eller',
            'BrowseForFile': 'Søg efter fil',
        },
        'SORTBY':
            [
                {
                    Text: 'Navn (A-Z)',
                    Value: 'Name'
                },
                {
                    Text: 'Navn (Z-A)',
                    Value: 'Name DESC'
                }
            ]
    }
};