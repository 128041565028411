export const locale = {
    lang: 'ds',
    data: {
        'TITLE': {
            'AssetPagination': 'Enheder pr. side',
        },
        'FIELDS': {
            'Designation': 'Betegnelse',
            'ValideFrom': 'Gyldig Fra',
            'SRNO': 'Sr nr.',
            'Labels': 'Kategori',
            'ShowOnlyHomePosition': 'Vis kun hjemmeposition',
            'ShowLastServiceDue': 'Vis kun (>365 dage)',
        },
        'BUTTONS': {
            'Save': 'Gem',
            'BACK': 'Tilbage',
            'Reset': 'Nulstil',
            'AddAssets':'Tilføj enheder'
        },
        'MESSAGES': {
            'Updated': 'Opdateret succesfuldt.',
            'Failed': 'Mislykkedes',
            'Deleted': 'Slettet med succes',
            'NORECORD': 'Intet optage fundet',
            'Created': 'Oprettet med succes',
        },
        'Validation': {
            'DesignationRequired': 'Betegnelse er påkrævet',
            'ValideFromRequired': 'Gyldig fra dato er påkrævet',
            'HourlyRateRequired': 'Pris er påkrævet',
            'WeekdayRequired': 'Ugedag er påkrævet',
            'TypeRequired': 'Type er påkrævet',
        },
        'Headers': {
            'Title':'Enheder',
        },
        'Lable': {
            'SRNO': 'Sr nr.',
            'Image': 'Image',
            'Brand': 'Mærke',
            'Model': 'Model',
            'Created': 'Oprettet',
            'SerialNumber': 'Serienummer',
            'Keywords': 'Nøgleord',
            'Description': 'Beskrivelse',
            'Group': 'Gruppe',
            'Labels': 'Etiketter',
            'VID':'vID'
        },
        'ratesType': {
            'valueA': 'A Timeløn',
            'valueB': 'B Timeløn Overarbejde 1',
            'valueC': 'C Timeløn Overarbejde 2',
            'valueJ': 'J Sygeløn pr. time',

            'valueK': 'Ferie',
            'valueL': 'Feriefridag',
            'valueM': 'Fridag m.løn',
            'valueN': 'Fridag u.løn',
            'valueO': 'Kursus/skole',
            'valueP': 'Andet',
            'valueQ': 'Tilbud',
        },
        'FILTERS': {
            'All': 'Alle',
            'SEARCH': 'Søg',
            'SORTING': 'Sorter efter',
        },
        'selectedValueWeekDays': {
            'value1': 'Mandag',
            'value2': 'Trisdag',
            'value4': 'Onsdag',
            'value8': 'Torsdag',
            'value16': 'Fredag',
            'value32': 'lørdag',
            'value64': 'Søndag'
          },
          'SORTBY':
            [
                {
                    Text: 'Mærke ↑',
                    Value: 'Brand'
                },
                {
                    Text: 'Mærke ↓',
                    Value: 'Brand DESC'
                },
                {
                    Text: 'Model ↑',
                    Value: 'Model'
                },
                {
                    Text: 'Model ↓',
                    Value: 'Model DESC'
                },
                {
                    Text: 'Serienummer ↑',
                    Value: 'Nummer'
                },
                {
                    Text: 'Serienummer ↓',
                    Value: 'Nummer DESC'
                },
                {
                    Text: 'Dato ↑',
                    Value: 'Date'
                },
                {
                    Text: 'Dato ↓',
                    Value: 'Date DESC'
                },
                {
                    Text: 'Gruppe ↑',
                    Value: 'Group'
                },
                {
                    Text: 'Gruppe ↓',
                    Value: 'Group DESC'
                }
            ],
    }
};