export const locale = {
    lang: 'en',
    data: {
        'Headers': {
            'NoFileSelected': 'No File Selected',
            'FOLDEREMPTY': 'This folder is empty',
            'UploadFile': 'Upload File',
            'ChooseFile': 'Choose File',
            'ChoosePicture': 'Choose Picture',
        },
        'BUTTONS': {
            'CREATE_FOLDER': 'Create Folder',
            'BACK': 'Back',
            'SAVE': 'Save',
            'DELETE': 'Delete',
            'HIDEFOLDERFOREMPLOYEE': 'Hide folder for employee.',
        },
        'MESSAGES': {
            'Created': 'Created successfully',
            'Updated': 'Updated successfully',
            'Deleted': 'Deleted successfully',
            'Failed': 'Operation failed , try later',
            'FeildUnderFinishFolder': '*** This Project folder is under finish folder.',
            'DropBoxApiNotResponding': '@DriveOptions not responding please try after some time',
            'FileConflict': 'File already exists',
            'FolderConflict': 'Folder name already exists',
        },
        'FIELDS': {
            'TEXTSEARCH': 'Text Search',
            'SORTING': 'Sort By',
            'DragAndDropFile':'Drag and drop file here',
            'Or':'Or',
            'BrowseForFile':'Browse for file',
        },
        'SORTBY':
            [
                {
                    Text: 'Name (A-Z)',
                    Value: 'Name'
                },
                {
                    Text: 'Name (Z-A)',
                    Value: 'Name DESC'
                }
            ]
    }
};