import { Component, Inject, OnInit, Optional } from '@angular/core';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { TranslateService } from '@ngx-translate/core';
import { OfferMaterialCalculation } from 'app/main/model/Material';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { RequestMaterial, RequestOfferMaterialList } from 'app/main/model/RequestBase';
import { MaterialService } from 'app/main/pages/material/material.service';
import { PdfViewerModelComponent } from 'app/main/pages/pdf-viewer/pdf-viewer.component';
import { OfferAddressModel } from 'app/main/model/ProjectOffer';
import { NotificationsService } from 'angular2-notifications';
import { CompanyInfo } from 'app/main/model/CompanyInfo';
import { OfferMaterialWithPrice, OfferTypeWithRoomPriceDetail } from 'app/main/model/OfferV2';
import { ProjectofferService } from 'app/main/pages/project-offer/project-offer.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';
import { MaterialType } from 'app/constant/common.constant';
import { ChangeDetectorRef } from '@angular/core';
import { AddMaterialToProjectComponent } from 'app/main/pages/project-offer/component/add-material-to-project/add-material-to-project.component';
import { NewMaterialComponent } from 'app/main/pages/material/new-material/new-material.component';
import { ProjectHoursDetail, projectRequisition } from 'app/main/model/Project';
import { AuthService } from 'app/core/services/auth.service';
import { ProjectRequisitionService } from 'app/main/pages/project/project.requisition.service';
import { ProjectService } from 'app/main/pages/project/project.service';
import { ApiConstant } from 'app/core/constant/ApiConstant';

@Component({
  selector: 'app-calculated-view-material-list',
  templateUrl: './calculated-view-material-list.component.html',
  styleUrls: ['./calculated-view-material-list.component.scss']
})
export class CalculatedViewMaterialListComponent implements OnInit {
  showSpinner: boolean = false;
  materialList: any[] = [];
  requestCalculatedOfferMaterialList = new RequestOfferMaterialList();
  materialTotalPrice: number = 0;
  materialPersonalTotalPrice: number = 0;
  materialTotalPriceWithVAT: number = 0;
  purchasePriceTotal: number = 0;
  timerTotal: number = 0;
  minitesToHour: any
  objAddProjectMaterial: OfferMaterialCalculation;
  showVarious: boolean = false;
  OfferNumber: number;
  isShowDriving: boolean = false;
  projectOffer: OfferAddressModel = new OfferAddressModel();
  companyInfoData: CompanyInfo;
  isEditDrivingDays: boolean = false;
  OfferTypeDetail: OfferTypeWithRoomPriceDetail = new OfferTypeWithRoomPriceDetail();
  DistancePerDayValue: number;
  meterAndKmText: string = "km";
  projectsCoworkerPrice: number = 0;
  settingMasterValues:any[] = [];
  address:any = {};
  grandTotal: number = 0;
  calculatedMaterialListForm: FormGroup;
  materialObject: any = {};
  materialType: number = 0;
  Materials: OfferMaterialCalculation[] = [];
  tempMaterials: OfferMaterialCalculation[] = [];
  MaterialName: any = "";
  selectMaterialtype = MaterialType;
  editedOfferMaterialObj: any = {};
  showActionBtn: boolean = false;
  offerTypeList: any;
  selectedOfferName: string;
  isAddUpdateMaterial:boolean = false;
  MaterialObject: OfferMaterialWithPrice[] = [];
  moduleName: string ="";
  requisitionList: any[] = [];
  isSelectedMaterial:boolean = false;
  projectHoursDetail = new ProjectHoursDetail();
  requisition: projectRequisition;
  roleClaims: any;
  hasPermision: boolean = false;
  isViewEconomy: boolean = false;
  purchaseTotalWithoutVat: number = 0;
  isCheckedAll: boolean = false;
  noteDescription: string = "";

  constructor(private translationLoader: FuseTranslationLoaderService,
    private materialService: MaterialService,
    private translate: TranslateService,
    public dialogRef: MatDialogRef<CalculatedViewMaterialListComponent>,
    public dialog: MatDialog,
    private notificationsService: NotificationsService,
    private projectofferService: ProjectofferService,
    private projectRequisitionService: ProjectRequisitionService,
    private projectService: ProjectService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private cdref: ChangeDetectorRef,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.translationLoader.loadTranslations(english, danish);
    this.getOfferMaterialData();
    this.roleClaims = this.authService.getRoleClaimsList();
  }

  ngOnInit() {
    this.authService.hasClaim(this.roleClaims.Project_Modify).subscribe(hasClaim => {
      if (hasClaim)
        this.hasPermision = hasClaim
      else
        this.hasPermision = false;
    });
    this.authService.hasClaim(this.roleClaims.Economy_View).subscribe(hasClaim => {
      if (hasClaim)
        this.isViewEconomy = hasClaim
      else
        this.isViewEconomy = false;
    });
    this.requestCalculatedOfferMaterialList = this.data && this.data.requestCalculatedOfferMaterialList ? this.data.requestCalculatedOfferMaterialList : null;
    this.address = this.data && this.data.address ? this.data.address : null;
    this.moduleName = this.data && this.data.moduleName ? this.data.moduleName : null;
    this.requisitionList = this.data && this.data.requisitionList ? this.data.requisitionList : [];
    this.offerTypeList = this.data && this.data.offerTypeList ? this.data.offerTypeList : null;
    this.settingMasterValues = Object.assign([], JSON.parse(localStorage.getItem("settingMasterValues")));
    var tempSettingObject = this.settingMasterValues.find(s => s.key1 == "Project" && s.key2 == "ProjectsCoworkerPrice").value;
    if (tempSettingObject)
      this.projectsCoworkerPrice = parseFloat(tempSettingObject);
    else
      this.projectsCoworkerPrice = 580;
    this.calculatedMaterialListForm = this.formBuilder.group({
      // offerID: [null],
      // offerTypeID: [null],
      // offerMaterialID: [null],
      // offerMaterialPriceID: [null],
      materialID: [null],
      // offerMaterialNo: [null],
      text: [{ value: null, disabled: true }],
      //quantity: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(9999999999)]],
      colorCode: [null],
      // price: [0],
      // purchasePrice: [null],
      retailPrice: [{ value: null, disabled: true }, [Validators.required, Validators.minLength(1), Validators.maxLength(999999999)]],
      // totalPrice: [0],
      // totalPriceVAT: [0],
      personalPrice: [{ value: null, disabled: true }, [Validators.required, Validators.minLength(1), Validators.maxLength(9999999999)]],
      discountPercent: [{ value: 0, disabled: true }],
      // retailPriceDiscount: [0],
      // personalPriceDiscount: [0],
      // timer: [0],
      // offerRoomOrDiverseID: [null],
      // isDeleted: [null],
      materialName: [],
      // itemNumber: [null],
      unit: [null],
      rows: [null],
      imagePath: [null],
      purchaseQuantity: [null],
      totalQtyBucket:[null, [Validators.required, Validators.minLength(1), Validators.maxLength(9999999999)]]
    });
    //this.GetCompanyInformation();
    this.getTravelingDistancePerDayValue();
    this.showDirection();
    // var reqModel = new RequestMaterial();
    // this.GetMaterial(reqModel, true);    
    //this.GetOfferEstimateMaterialList();
    this.getProjectTimerFromRequisition();    
    this.getNotetabData();
  }

  getTotalPrice(qty, price) {
    var isNegative = false;
    if (qty < 0)
      isNegative = true;
    if (price < 0)
      isNegative = true;
    var total = (qty * price);
    if (isNegative)
      total = -Math.abs(total)
    return total;
  }
  calculateProjMaterialPriceTotal() {
    this.materialTotalPriceWithVAT = 0;
    this.materialPersonalTotalPrice = 0;
    this.materialTotalPrice = 0;
    this.purchasePriceTotal = 0;
    //this.timerTotal = 0;
    this.materialList.forEach((e: any) => {
      if (!e.totalPrice) {
        e.totalPrice = this.getTotalPrice((e.totalQtyBucket ? e.totalQtyBucket : 0), e.material.retailPrice);
      }
      if (!e.personalPriceTotal) {
        e.personalPriceTotal = this.getTotalPrice((e.totalQtyBucket ? e.totalQtyBucket : 0), e.material.personalPrice);
      }
      this.materialTotalPrice += e.totalPrice;
      if (e.material.personalPrice && e.material.personalPrice != 0) {
        this.materialPersonalTotalPrice += e.personalPriceTotal;
      }
      else {
        e.personalPrice = 0;
      }

      if (e.totalPriceVAT) {
        this.materialTotalPriceWithVAT += e.totalPriceVAT;
      } else {
        var vat = (e.totalPrice * 25) / 100;
        this.materialTotalPriceWithVAT += (e.totalPrice + vat);
      }
      if (e.material.purchasePrice) {
        this.purchasePriceTotal += this.getTotalPrice((e.totalQtyBucket ? e.totalQtyBucket : 0), e.material.purchasePrice);
        this.purchaseTotalWithoutVat += e.material.purchasePrice;
      }
      //this.getOfferItemTimer(e);
    });
  }

  showField(completed: boolean) {
    this.showVarious = completed;
  }

  ngOnBackClick() {
    this.dialogRef.close(null);
  }

  viewPDF() {
    this.showSpinner = true;
    var calculatedOfferMaterialList = {
      "OfferID": this.requestCalculatedOfferMaterialList.offerID,
      "OfferTypeIDS": this.requestCalculatedOfferMaterialList.offerTypeIDS,
      "ProjectId": this.requestCalculatedOfferMaterialList.projectID,
      "ShowVarious": this.showVarious
    };

    this.materialService.GetCalculatedOfferMaterialListPDF(calculatedOfferMaterialList).subscribe((response: ResponseBase) => {
      var data = response.result.base64.replace("data:application/pdf;base64,", "");
      this.OfferNumber = JSON.parse(localStorage.getItem("OfferNumber"));
      const dialogRef = this.dialog.open(PdfViewerModelComponent, {
        height: '90%',
        width: '90%',
        data: {
          base64: data,
          filename: response.result.filename ? response.result.filename + ".pdf" : "Tilbud_" + this.requestCalculatedOfferMaterialList.offerID + "_" + this.requestCalculatedOfferMaterialList.offerTypeIDS + ".pdf"
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {

        }
      });
    },
      error => (err) => {
      },
      () => {
        this.showSpinner = false;
      });
  }

  // GetCompanyInformation() {
  //   this.companyInfoService.GetCompanyInformationData<ResponseBase>()
  //     .subscribe((response: ResponseBase) => {
  //       if (response) {
  //         this.companyInfoData = response.result;
  //       }
  //     },
  //       (err: any) => {
  //         this.translate.get("MESSAGES.Failed").subscribe({
  //           next: (res) => {
  //             this.notificationsService.error(res);
  //           }, error: err => { }
  //         });
  //       },
  //       () => {
  //       });
  // }

  
  calculateTotalTime(days) {
    this.OfferTypeDetail.TotalTime = 0;
    if (this.OfferTypeDetail.time) {
      var time = this.OfferTypeDetail.time;
      this.OfferTypeDetail.TotalTime = ((time * 2) * Math.ceil(days ? days : this.OfferTypeDetail.numberOfDays));
    }
    return this.OfferTypeDetail.TotalTime;
  }

  calculateDistanceAmt() {
    var amount = 0;
    amount = this.calculateTotalTime(this.OfferTypeDetail.numberOfDays);
    amount = amount * this.DistancePerDayValue;
    this.OfferTypeDetail.travelingAmount = amount;
    this.OfferTypeDetail.price = ((this.OfferTypeDetail.priceTotal + this.OfferTypeDetail.travelingAmount) - this.OfferTypeDetail.discount);
    this.OfferTypeDetail.vat = ((this.OfferTypeDetail.price) * 25 / 100);
    this.OfferTypeDetail.totalInclVAT = (this.OfferTypeDetail.price + (this.OfferTypeDetail.price * 25 / 100));
    this.OfferTypeDetail.numberOfHours = this.address.numberOfHours;
    this.OfferTypeDetail.numberOfDays = Math.ceil(Math.ceil((this.OfferTypeDetail.numberOfHours / 7.5) / 0.25) * 0.25);
    this.calTravelingAmtOnDrivingDaysChange();
  }

  calTravelingAmtOnDrivingDaysChange() {
    var amount = 0;
    amount = this.calculateTotalTime(this.OfferTypeDetail.numberOfDrivingDays == this.OfferTypeDetail.numberOfDays ? this.OfferTypeDetail.numberOfDrivingDays : this.OfferTypeDetail.numberOfDays);
    amount = amount * this.DistancePerDayValue;
    this.OfferTypeDetail.travelingAmount = amount;
    this.OfferTypeDetail.price = ((this.OfferTypeDetail.priceTotal + this.OfferTypeDetail.travelingAmount) - this.OfferTypeDetail.discount);
    this.OfferTypeDetail.vat = ((this.OfferTypeDetail.price) * 25 / 100);
    this.OfferTypeDetail.totalInclVAT = (this.OfferTypeDetail.price + (this.OfferTypeDetail.price * 25 / 100));
    this.OfferTypeDetail.numberOfHours = this.address.numberOfHours;
    this.OfferTypeDetail.numberOfDays = Math.ceil(Math.ceil((this.OfferTypeDetail.numberOfHours / 7.5) / 0.25) * 0.25);
  }

  getTravelingDistancePerDayValue() {
    this.projectofferService.GetDistancePerMinuteValue().subscribe((response: ResponseBase) => {
      if (response) {
        this.DistancePerDayValue = parseFloat(response.result.myResponse[0].value);
      }
    });
  }
  showDirection() {
    this.isShowDriving = true;
    setTimeout(() => {
      this.OfferTypeDetail.numberOfDrivingDays = this.OfferTypeDetail.numberOfDays;
      this.OfferTypeDetail.distance = this.address.distance;
      this.OfferTypeDetail.time = this.address.time;
    }, 1000);
    this.calculateDistanceAmt();
  }

  GetMaterial(model, displayLoader) {
    this.showSpinner = displayLoader;
    model.Limit = 400;
    model.OrderBy = "Text";
    this.materialService.Get<ResponseBase>(model)
      .subscribe((response: ResponseBase) => {
        if (response) {
          this.showSpinner = false;
          this.bindMaterialDropdownOnEdit();
          this.getNextRequestForMaterial(response.pagination, model, response.result);
        }
        else {
          this.showSpinner = false;
          this.Materials = [];
          this.tempMaterials = [];
        }
      },
        error => (err) => {
          this.showSpinner = false;
          this.Materials = [];
          this.tempMaterials = [];
        },
        () => {
        });
  }

  getNextRequestForMaterial(pagination, model, response) {
    if (pagination.index == 1) {
      this.Materials = response;
      this.tempMaterials = response;
    }
    else {
      this.Materials.push(...response);
      this.tempMaterials = this.Materials;
    }
    localStorage.setItem('Materials', JSON.stringify(this.Materials));
    model.Index = pagination.index + 1;
    model.Limit = pagination.limit;
    model.TotalRows = pagination.totalCount;
    if (pagination.totalCount > pagination.index * pagination.limit)
      this.GetMaterial(model, false);    
  }

  bindMaterialDropdownOnEdit() {
    if (this.Materials.length && this.editedOfferMaterialObj && this.editedOfferMaterialObj.material) {
      var material = this.Materials.find(x => x.id == this.editedOfferMaterialObj.material.id);
      if (material) {
        this.materialObject.materialID = material.id;
      }
    }
  }

  getMaterialOnType(typeId) {
    if (typeId != 0) {
      this.Materials = this.tempMaterials.filter(x => x.materialType && x.materialType.split(',').find(p => p == (this.materialType.toString())));
    } else {
      this.Materials = this.tempMaterials;
    }
  }

  ResetForm() {
    this.materialObject = {};
    this.calculatedMaterialListForm.reset();
  }

  GetMaterialOnClear(value) {
    if (!value) {
      if (this.materialType > 0) {
        this.Materials = this.tempMaterials.filter(x => x.materialType && x.materialType.split(',').find(p => p == (this.materialType.toString())));
      } else {
        this.Materials = this.tempMaterials;
      }
    }
  }

  _handleKeydown(event: KeyboardEvent) {
    if (event.keyCode === 13) {
      event.stopPropagation();
    }
  }

  DropDownSearchMaterial(MaterialName) {
    if (this.materialType > 0) {
      this.Materials = this.tempMaterials.filter(o => o.text.toLowerCase().includes(MaterialName.toLowerCase()) || o.itemNumber.toLowerCase().includes(MaterialName.toLowerCase()) && o.materialType && o.materialType && o.materialType.split(',').find(p => p == (this.materialType.toString())));
    } else {
      this.Materials = this.tempMaterials.filter(o => o.text.toLowerCase().includes(MaterialName.toLowerCase()) || o.itemNumber.toLowerCase().includes(MaterialName.toLowerCase()));
    }
  }

  onChangeMaterial(matID) {
    this.showSpinner = true;
    this.materialObject = new OfferMaterialCalculation();
    var material = this.Materials.find(o => o.id == matID);
    if (material) {
      var matCopy = Object.assign({}, material);
      this.materialObject.materialID = material.id;
      this.materialObject.material = material;
      this.materialObject.id = "";
      this.materialObject.unit = matCopy.unit;
      this.materialObject.retailPrice = matCopy.retailPrice;
      this.materialObject.personalPrice = matCopy.personalPrice;
      this.materialObject.rows = matCopy.rows;
      if (this.materialObject.rows && parseInt(this.materialObject.rows) > 0)
        this.materialObject.quantity = 1 / parseInt(this.materialObject.rows);
      else
        this.materialObject.quantity = 1;
      this.materialObject.itemNumber = matCopy.itemNumber;
      this.materialObject.colorCode = matCopy.colorCode;
      this.materialObject.discountPercent = 0;
      this.materialObject.noStockTraek = matCopy.noStockTraek;
      this.materialObject.imagePath = matCopy.imagePath;
      this.materialObject.purchasePrice = matCopy.purchasePrice;
      this.materialObject.purchaseQuantity = matCopy.purchaseQuantity;
      this.materialObject.totalQtyBucket = matCopy.quantity;
      // this.materialListObject.offersMaterialId = this.offerMaterialId;
      this.materialObject.text = matCopy.text;
      if(matCopy.maxOrderNumber)
        this.materialObject.maxOrderNumber = matCopy.maxOrderNumber;
      // this.materialListObject.offersMaterialPriceId = this.offersMaterialPriceId;            
      this.calculatedMaterialListForm.patchValue(this.materialObject);

    }
    else {
      this.translate.get("MESSAGES.NoMaterialFound").subscribe({
        next: (res) => {
          this.notificationsService.error(res);
        },
        error: err => {
        }
      });
    }
    this.showSpinner = false;
  }

  EditMaterialList(item) {
    this.materialObject = {...item};//item.material;
  }

  DeleteMaterialList(index, materialobj) {
    var message = "";
    var materialText = materialobj.text ? materialobj.text : materialobj.material.text;
    this.translate.get("Headers.DeleteMaterial").subscribe({
      next: (res) => { message = res; }, error: err => { }
    });
    message = message.replace("@MaterialName", materialText)
    const dialogRef = this.dialog.open(AlertMessageModelComponent, {
      height: '200px',
      data: { Header: message }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.showSpinner = true;
        this.projectofferService.DeleteOfferEstimateMaterial(materialobj.id).subscribe({
          next: (response) => {
            this.GetOfferEstimateMaterialList();
            this.calculatedMaterialListForm.reset();
            this.translate.get("MESSAGES.Deleted").subscribe({
              next: (res) => {
                this.notificationsService.create(res);
              },
              error: err => {
              }
            });
          },
        });
      }
      else{
        this.showSpinner = false;
      }
    });
  }

  async SaveMaterialList() {
      this.calculatedMaterialListForm.markAllAsTouched();
      if (this.isAddUpdateMaterial && this.offerTypeList.length > 0
          && !this.selectedOfferName) {
          this.translate.get("Validation.OfferIsRequired").subscribe({
              next: (res) => {
                  this.notificationsService.error(res);
              },
              error: err => {
              }
          });
          return;
      }
    if (!this.calculatedMaterialListForm.valid)
      return;
    //const checkMaterialAlredyExist = this.materialList.find(item => item.text.trim() === this.materialObject.text.trim());
    const checkMaterialAlredyExist = this.materialList.find(item => (item.text?item.text.trim():"") === this.materialObject.text.trim());
    if (checkMaterialAlredyExist && !this.materialObject.id) {
      this.translate.get("MESSAGES.MaterialAlreadyExists").subscribe({
        next: (res) => {
          this.notificationsService.error(res);
        },
        error: err => {
        }
      });
      return;
    }

    let isbelowholdingQty = false;
    if (this.materialObject.material) {
      const estimateQty = (this.materialObject.material.holdings - this.materialObject.totalQtyBucket);
      if (estimateQty <= 0)
        isbelowholdingQty = true;
      else if (estimateQty < this.materialObject.material.minInventory)
        isbelowholdingQty = true;
    }

    if (isbelowholdingQty) {
      let msg: string = '';
      this.translate.get("Validation.MaxQuantityAddMsg1").subscribe({
        next: (res) => { msg = res.replace("@qty", this.materialObject.totalQtyBucket + " " + this.materialObject.unit); }, error: err => { }
      });
      const dialogRef = this.dialog.open(AlertMessageModelComponent, {
        height: '200px',
        data: { Header: msg },
      });

      await dialogRef.afterClosed()
        .toPromise()
        .then(result => {
          if (result == false) {
            return Promise.reject();
          } else {
            return Promise.resolve();
          }
        });
    }
    
    if (this.calculatedMaterialListForm.valid || this.materialObject.totalQtyBucket) {
      this.editedOfferMaterialObj = {};
      this.editedOfferMaterialObj.id = this.materialObject.id ? this.materialObject.id : null;
      this.editedOfferMaterialObj.offerID = this.data.requestCalculatedOfferMaterialList.offerID ? this.data.requestCalculatedOfferMaterialList.offerID : null;
      this.editedOfferMaterialObj.offerTypeID = this.data.requestCalculatedOfferMaterialList.offerTypeIDS ? this.data.requestCalculatedOfferMaterialList.offerTypeIDS : null;
      this.editedOfferMaterialObj.offerMaterialID = this.materialObject.offerMaterialID ? this.materialObject.offerMaterialID : null;
      this.editedOfferMaterialObj.offerMaterialPriceID = this.materialObject.offerMaterialPriceID ? this.materialObject.offerMaterialPriceID : null;
      this.editedOfferMaterialObj.materialID = this.materialObject.materialID ? this.materialObject.materialID : null;
      this.editedOfferMaterialObj.text = this.materialObject.text ? this.materialObject.text : "";
      this.editedOfferMaterialObj.quantity = this.materialObject.quantity ? this.materialObject.quantity : 0;
      this.editedOfferMaterialObj.colorCode = this.materialObject.colorCode ? this.materialObject.colorCode : "";
      this.editedOfferMaterialObj.price = this.materialObject.price ? this.materialObject.price : 0;
      this.editedOfferMaterialObj.purchasePrice = this.materialObject.purchasePrice ? this.materialObject.purchasePrice : 0;
      this.editedOfferMaterialObj.retailPrice = this.materialObject.retailPrice ? this.materialObject.retailPrice : 0;
      //this.editedOfferMaterialObj.totalPrice = this.materialObject.totalPrice ? this.materialObject.totalPrice :0;
      this.editedOfferMaterialObj.totalPriceVAT = this.materialObject.totalPriceVAT ? this.materialObject.totalPriceVAT : 0;
      this.editedOfferMaterialObj.personalPrice = this.materialObject.personalPrice ? this.materialObject.personalPrice : 0;
      //this.editedOfferMaterialObj.personalPriceTotal = this.materialObject.personalPriceTotal ? this.materialObject.personalPriceTotal:0;
      this.editedOfferMaterialObj.discountPercent = this.materialObject.discountPercent ? this.materialObject.discountPercent : 0;
      this.editedOfferMaterialObj.retailPriceDiscount = this.materialObject.retailPriceDiscount ? this.materialObject.retailPriceDiscount : 0;
      this.editedOfferMaterialObj.personalPriceDiscount = this.materialObject.personalPriceDiscount ? this.materialObject.personalPriceDiscount : 0;
      this.editedOfferMaterialObj.timer = this.materialObject.timer ? this.materialObject.timer : 0;
      this.editedOfferMaterialObj.offerRoomOrDiverseID = this.materialObject.offerRoomOrDiverseID ? this.materialObject.offerRoomOrDiverseID : null;
      this.editedOfferMaterialObj.isDeleted = this.materialObject.isDeleted;
      this.editedOfferMaterialObj.offerQty = this.materialObject.offerQty ? this.materialObject.offerQty : 0;
      this.editedOfferMaterialObj.m2Qty = this.materialObject.m2Qty ? this.materialObject.m2Qty : 0;
      this.editedOfferMaterialObj.totalQtyBucket = this.materialObject.totalQtyBucket ? this.materialObject.totalQtyBucket : 0;
      this.editedOfferMaterialObj.totalAreaPaint = this.materialObject.totalAreaPaint ? this.materialObject.totalAreaPaint : 0;
      this.editedOfferMaterialObj.material = this.materialObject.material;
      this.editedOfferMaterialObj.projectId = this.data.requestCalculatedOfferMaterialList.projectID ? this.data.requestCalculatedOfferMaterialList.projectID : null;
      this.showSpinner = true;
      this.projectofferService.AddUpdateOfferEstimateMaterial(this.editedOfferMaterialObj).subscribe({
        next: (response) => {
          this.GetOfferEstimateMaterialList();
        },
        error: err => {
          this.showSpinner = false;
          this.translate.get("MESSAGES.Failed").subscribe({
            next: (res) => {
              this.notificationsService.error(res);
            },
            error: err => {
            }
          });
        },
        complete: () => {
          this.showSpinner = false;
        }
      });
    }
  }

  GetOfferEstimateMaterialList() {
    this.showSpinner = true;
    this.projectofferService.GetOfferEstimateMaterialList(this.requestCalculatedOfferMaterialList).subscribe({
      next: (response: ResponseBase) => {
        this.showSpinner = false;
        this.materialList = response.result;
        this.materialList.filter(p => {
            p.isChecked = p.isAddedToProject ? true : false;
        });
        this.timerTotal = response.pagination && response.pagination.totalCount ? response.pagination.totalCount : 0;
        this.calculateProjMaterialPriceTotal();
        this.calculatedMaterialListForm.reset();
      },
    });
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
 }

 addUpdateMaterial(){
   this.isAddUpdateMaterial = true;
   this.GetMaterial(new RequestMaterial(), true);
   if (this.offerTypeList && this.offerTypeList.length == 1) {
     //this.selectedOfferName = this.offerTypeList[0].name;
     this.changOffer(this.offerTypeList[0]);
       //this.showActionBtn = true;
   }
   //else
     //    this.showActionBtn = false;
   else
       this.showActionBtn = true;

 }

 changOffer(item){
   this.requestCalculatedOfferMaterialList.offerID = item.offerId,
   this.requestCalculatedOfferMaterialList.offerTypeIDS = item.id;
   this.selectedOfferName = item.name;
   this.showSpinner = true;
   this.projectofferService.GetOfferEstimateMaterialList(this.requestCalculatedOfferMaterialList).subscribe({
       next: (response: ResponseBase) => {
           this.showSpinner = false;
           this.materialList = response.result;
           this.materialList.filter(p => {
                p.isChecked = p.isAddedToProject ? true : false;
           });
           this.showActionBtn = true;
           this.calculateProjMaterialPriceTotal();
           this.calculatedMaterialListForm.reset();
       },
   });
 }

  // getOfferItemTimer(offerItem) {
  //   var selectedMaterial = this.MaterialObject.find(o => o.id == offerItem.offerMaterialID);
  //   if (selectedMaterial) {
  //     var selectedPrice = selectedMaterial.offerMaterialPriceList.find(o => o.id == offerItem.offerMaterialPriceID);
  //     if (selectedPrice) {
  //       let timer = 0;
  //       if (selectedPrice.isAddMeterRangePrice) {
  //         if (offerItem.offerMaterialPriceID) {
  //           if (offerItem.offerQty >= selectedPrice.startMeter1 && offerItem.offerQty <= selectedPrice.endMeter1)
  //             timer = selectedPrice.timeMeter1;
  //           else if (offerItem.offerQty >= selectedPrice.startMeter2 && offerItem.offerQty <= selectedPrice.endMeter2)
  //             timer = selectedPrice.timeMeter2;
  //           else if (offerItem.offerQty >= selectedPrice.startMeter3 && offerItem.offerQty <= selectedPrice.endMeter3)
  //             timer = selectedPrice.timeMeter3;
  //         }
  //       }
  //       else
  //         timer = selectedPrice.timeMeter;
  //       offerItem.timer = timer;
  //       this.timerTotal += offerItem.offerQty ? (offerItem.offerQty * (offerItem.timer ? offerItem.timer : 0)) : 0;
  //     }
  //   }
  // }

  getOfferMaterialData() {
    this.showSpinner = true;
    this.projectofferService.GetOfferCategoryMaterial().subscribe({
      next: (response: ResponseBase) => {
        this.MaterialObject = response.result.myResponse;
        //this.showSpinner = true;
        this.GetOfferEstimateMaterialList();
      },
      error: err => {
        //this.showSpinner = false;
      },
      complete: () => {
        //this.showSpinner = false;
      }
    });

  }

  addMaterialsToProject(){
    const selectMaterialList = this.materialList.filter(x => x.isChecked == true && (x.isAddedToProject == null || x.isAddedToProject == false));
    //const selectMaterialList = this.materialList.filter(x => x.isChecked == true);
    const dialogRef = this.dialog.open(AddMaterialToProjectComponent, {
      height: '50%',
      width: '80%',
      data: {  
        "requisitionList" : this.requisitionList,
        "materialList" : selectMaterialList,
      }
    });  
    dialogRef.afterClosed().subscribe(result => {
      this.GetOfferEstimateMaterialList();
      this.isSelectedMaterial = true;
      this.isCheckedAll = false;
    });
  }

  onSetActive() {
    let atleastOneCheckedMat = this.materialList.find(i => i.isChecked == true && (i.isAddedToProject == null || i.isAddedToProject == false))
    //let atleastOneUncheckedMat = this.materialList.find(i => i.isChecked == true)
    if (atleastOneCheckedMat) 
      this.isSelectedMaterial = true;
    else 
      this.isSelectedMaterial = false;

    let atleastOneUncheckedMat = this.materialList.find(i => i.isChecked == false)
    if (atleastOneUncheckedMat) 
      this.isCheckedAll = false;
    else 
      this.isCheckedAll = true;
  }

  gotoMaterial(id) {
    if (id) {
      const dialogRef = this.dialog.open(NewMaterialComponent, {
        height: '800px',
        width: '80%',
        data: id,
      });
      dialogRef.afterClosed().subscribe(result => { });
    }
  }

  back(){
    this.requisitionList=[];
    this.isAddUpdateMaterial = false;
    this.showActionBtn = false;
    this.selectedOfferName = null;
  }

  getProjectTimerFromRequisition() {
    if (this.requisitionList.length > 0) {
      // this.projectHoursDetail.estimatedTime = this.requisitionList[0].projectEstimatedTime ? this.requisitionList[0].projectEstimatedTime : 0;
      // this.projectHoursDetail.totalWorkingTime = this.requisitionList[0].projectTotalWorkingTime ? this.requisitionList[0].projectTotalWorkingTime : 0;
      // this.projectHoursDetail.plannedWorkHour = this.requisitionList[0].projectPlannedWorkHour ? this.requisitionList[0].projectPlannedWorkHour : 0;
      this.requisition =  this.requisitionList[0];
      //this.changePrice();
      this.showCalculatedValuesForEconomy();
    }
    
  }
  DropDownRequistionSelect(requisition) {
    this.requisition = requisition;
  }

  updateRequisition() {
    if (this.requisition.donotUpdateWorkHour) {
      this.requisition.doNotUpdateHour = "T";
    }
    else {
      this.requisition.doNotUpdateHour = "F";
    }
    this.showSpinner = true;
    this.requisition.CreatedBy = this.authService.getCoWorkerId();
    this.projectRequisitionService.Update(this.requisition.projectId, this.requisition.id, this.requisition).subscribe({
      next: (response: ResponseBase) => {
        if (response && response.result) {
          this.requisition.startDate = response.result.startDate;
          this.requisition.endDate = response.result.endDate;
          this.requisition.comingDate = response.result.comingDate;
          this.requisition.status = response.result.status;
          this.requisition.discountPercent = response.result.discountPercent;
          this.requisition.discount = response.result.discount;
          this.requisition.materialCreation = response.result.materialCreation;
          if (this.requisition.doNotUpdateHour == "T")
            this.requisition.donotUpdateWorkHour = true;
          else
            this.requisition.donotUpdateWorkHour = false;
          this.translate.get("MESSAGES.Updated").subscribe({
            next: (res) => {
              this.notificationsService.create(res);
            },
            error: err => {
            }
          });
        }
      },
      error: err => {
        this.translate.get("MESSAGES.Failed").subscribe({
          next: (res) => {
            this.notificationsService.error(res);
          },
          error: err => {
          }
        });
        this.showSpinner = false;
      },
      complete: () => {
        this.showSpinner = false;
      }
    });
  }

  changeIncludePrice() {
    this.requisition.excludePrice = Number(((Number(this.requisition.includePrice)) / 1.25).toFixed(2));
    this.changePrice();
  }

  changePrice() {
    this.requisition.includePrice = Number((this.requisition.excludePrice + ((Number(this.requisition.excludePrice) * 25) / 100)).toFixed(2));//.toLocaleString('en-DA', { style: 'currency', currency: 'DKK' });
    this.requisition.vatPrice = Number((Number(this.requisition.excludePrice) * 25) / 100);//.toLocaleString('en-DA', { style: 'currency', currency: 'DKK' });
    //this.getTotalEtstimeteOrPrice();
    if (this.requisition.excludePrice > 0) {
      this.requisition.suggestedHours = Number((this.requisition.excludePrice / this.requisition.coworkerWorkingRate).toFixed(2)); //Number(((this.Requisition.excludePrice - this.materialTotalPrice) / 517.50).toFixed(2));
      this.requisition.estimatedTime = this.requisition.suggestedHours;
    }
    else if (this.requisition.estimatedPrice > 0) {
      this.requisition.suggestedHours = Number((this.requisition.estimatedPrice / this.requisition.coworkerWorkingRate).toFixed(2));  //Number(((this.Requisition.estimatedPrice - this.materialTotalPrice) / 517.50).toFixed(2));
      this.requisition.estimatedTime = this.requisition.suggestedHours;
    }
    else if (!this.requisition.estimatedPrice || !this.requisition.excludePrice) {
      this.requisition.suggestedHours = 0;
      this.requisition.estimatedTime = 0;
    }
    this.setRequisitionNoOfDays();
  }

  setRequisitionNoOfDays() {
    this.requisition.hoursLeft = 0;
    if (this.requisition.estimatedTime) {
      this.requisition.hoursLeft = parseFloat(((this.requisition.estimatedTime - this.requisition.totalWorkingTime)).toFixed(2));
      this.requisition.numberOfDays = parseFloat(((this.requisition.hoursLeft) / 7.5).toFixed(2));
    }
    else
      this.requisition.numberOfDays = 0;
  }

  onCheckCaseOnHourly(value) {
    this.requisition.isHourlyWage = !value;
    this.requisition.hourlyWage = 0;
    if (this.requisition.isHourlyWage) {
      this.requisition.excludePrice = 0
      this.requisition.estimatedPrice = 0
      this.changeIncludePrice();
      //this.changePrice();
      //this.changeEstimatePrice();
    }
    setTimeout(() => {
      this.updateRequisition();
    }, 500);
  }

  showCalculatedValuesForEconomy() {
    var requisitionData = this.requisition;
    if (requisitionData) {
      if (requisitionData.suggestedHours)
        this.requisition.suggestedHours = requisitionData.suggestedHours;
      if (requisitionData.excludePrice) {
        this.requisition.includePrice = Number((requisitionData.excludePrice + ((Number(requisitionData.excludePrice) * 25) / 100)).toFixed(2));
        this.requisition.vatPrice = Number((Number(requisitionData.excludePrice) * 25) / 100);
        if (!requisitionData.suggestedHours)
          this.requisition.suggestedHours = Number((this.requisition.excludePrice / this.requisition.coworkerWorkingRate).toFixed(2));
      }
      if (requisitionData.estimatedPrice > 0) {
        this.requisition.includeEstimatePrice = Number((this.requisition.estimatedPrice + ((Number(this.requisition.estimatedPrice) * 25) / 100)));//.toLocaleString('en-DA', { style: 'currency', currency: 'DKK' });
        this.requisition.vatEstimatePrice = Number((Number(this.requisition.estimatedPrice) * 25) / 100);
        if (!requisitionData.suggestedHours)
          this.requisition.suggestedHours = Number((this.requisition.estimatedPrice / this.requisition.coworkerWorkingRate).toFixed(2));
      }
      if (!requisitionData.suggestedHours)
        requisitionData.suggestedHours = this.requisition.suggestedHours;
      this.requisition.suggestedHours = requisitionData.suggestedHours;
      this.requisition.estimatedTime = requisitionData.estimatedTime ? requisitionData.estimatedTime : requisitionData.suggestedHours;
      this.setRequisitionNoOfDays();
    }
  }

  onSetActiveAll(event) {
    this.materialList.filter(p => {
      p.isChecked = event.checked;
    });

    let atleastOneUncheckedMat = this.materialList.find(i => i.isChecked == true && (i.isAddedToProject == null || i.isAddedToProject == false))
    if (atleastOneUncheckedMat) 
      this.isSelectedMaterial = true;
    else 
      this.isSelectedMaterial = false;
  }

  getNotetabData() {
    if (this.requestCalculatedOfferMaterialList.projectID) {
      this.projectService.GetProjectRequisition<any>(this.requestCalculatedOfferMaterialList.projectID, ApiConstant.RequisitionNote).subscribe({
        next: (response) => {
          this.noteDescription = response.result.description;
        },
        error: err => {
        }
      });
    }
  }
}
