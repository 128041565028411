import { Component, OnInit, EventEmitter, Input, Output, ViewChild, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OfferType, ProjectDetailForOffer, OfferAddressModel } from 'app/main/model/ProjectOffer';
import { RequestProject, RequestProjectForOffer, RequestCustomer, OfferProject, RequestInquiry, SMSModules, RequestProjectOffer, RequestOfferConvert } from 'app/main/model/RequestBase';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatSelect } from '@angular/material';
import { ProjectofferService } from 'app/main/pages/project-offer/project-offer.service';
import { ProjectService } from 'app/main/pages/project/project.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { RequestProjectOfferRoom, RequestCustomerProperty } from 'app/main/model/RequestBase';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { ProjectsOfferRoom } from 'app/main/model/ProjectOffer';
import { ProjectIndoorOutDoorStatus, OfferStatus } from 'app/main/model/ProjectStatus';
import { FormService } from 'app/core/services/form.service';
import { CustomersService } from 'app/main/pages/customer/customer.service';
import { ProjectRequisitionService } from 'app/main/pages/project/project.requisition.service';
import { InquiryService } from 'app/main/pages/inquiry/inquiry.service';
import { CommonService } from 'app/core/services/common.service';
import { AlertMessageModelComponent } from 'app/main/pages/alert-message-model/alert-message-model.component';
import { NewCustomerComponent } from 'app/main/pages/customer/new-customer/new-customer.component';
import { SMSModuleEnum } from 'app/core/enum/sms-module.enum';
import { CkeditorConfig } from 'app/constant/common.constant';
import { postalcodeJson } from 'app/core/constant/PostalCodeConstant';
import { AuthService } from 'app/core/services/auth.service';
import { GoogleMapDirectionComponent } from 'app/main/pages/google-map-direction/google-map-direction.component';
import { CompanyInfoService } from 'app/main/pages/companyInfo/companyInfo.service';
import { CompanyInfo } from 'app/main/model/CompanyInfo';
import { CustomerProperty, InquiryCustomer } from 'app/main/model/Customer';
import * as moment from 'moment';
import { spaceOnlyValidator } from 'app/core/Validators/validation';
import { CustomerPropertiesComponent } from 'app/main/pages/customer/component/customerProperties/customerProperties.component';

@Component({
    selector: 'app-add-project-offer',
    templateUrl: './add-project-offer.component.html',
    styleUrls: ['./add-project-offer.component.scss']
})
export class AddProjectOfferComponent implements OnInit, AfterViewInit {
    @ViewChild('customer', { static: false }) customerField: MatSelect;
    projectOffer: OfferAddressModel = new OfferAddressModel();
    ProjectSearch: string = "";
    projectsList: ProjectDetailForOffer[];
    InsideOrOutsideOffer = OfferType;
    showSpinner: boolean = false;
    @Input() OfferInput: any;
    @Output() OfferOutput = new EventEmitter<{ offerId: string, number: number, mathod: string, inquiryId: string }>();
    @Output() GetProjectNumber = new EventEmitter<any>();
    @Output() smsObj = new EventEmitter<any>();
    @Output("storeFileFolderPathData") storeFileFolderPathData: EventEmitter<any> = new EventEmitter();
    @Output() ProjectOfferOutput = new EventEmitter<any>();
    @Output() CustomerOutput = new EventEmitter<any>();
    requestProjectOfferRoom: RequestProjectOfferRoom = new RequestProjectOfferRoom();
    ProjectsOfferRooms: ProjectsOfferRoom[];
    displayNoRoomMessage: boolean = false;
    offerId: string = "";
    selectIndoorOutDoorStatus = ProjectIndoorOutDoorStatus;
    offerStatus = OfferStatus;
    projectOfferForm: FormGroup;
    Customers: any[];
    CustomerSearch: string = "";
    InquirySearch: string = "";
    RequisitionSearch: string = "";
    RequisitionList = [];
    InquiryList = [];
    model: any;
    customerDetailObj: any;
    submitted = false;
    SMSObject = new SMSModules();
    public editorOptions = {
        placeholder: "Beskrivelse"
    };
    config = CkeditorConfig;
    ProjectOfferList = [];
    requestModel: RequestProjectOffer = new RequestProjectOffer();
    offerApprovedCount: number = 0;
    onFocusCustDropbox: boolean = true;
    companyInfoData: CompanyInfo;
    customerProperties: CustomerProperty[] = [];
    requestCustomerProperty: RequestCustomerProperty = new RequestCustomerProperty();
    isShowInactiveInquiry: boolean = false;
    //InquiryDetail: any = new InquiryCustomer();
    showExistingCustomerList: boolean = false;
    InquiryCustomerList: any = [];
    inquiryObj = new InquiryCustomer();
    assignedCustomerId: string;
    roleClaims: any;
    constructor(public dialog: MatDialog,
        private translationLoader: FuseTranslationLoaderService,
        private projectofferService: ProjectofferService,
        private _router: Router,
        private _route: ActivatedRoute,
        private notificationsService: NotificationsService,
        private translate: TranslateService,
        private formBuilder: FormBuilder,
        private formService: FormService,
        private customersService: CustomersService,
        private projectRequisitionService: ProjectRequisitionService,
        private inquiryService: InquiryService,
        private projectService: ProjectService,
        private commonService: CommonService,
        private authService: AuthService,
        public companyInfoService: CompanyInfoService
    ) {
        this.translationLoader.loadTranslations(english, danish);
        this.roleClaims = this.authService.getRoleClaimsList();
    }

    ngOnInit() {
        this.config.height = "calc(65vh - 385px)";
        this.config.readOnly = false;
        this._route.params.subscribe(params => {
            this.projectOffer.customerId = params['Custid'];
            this.projectOffer.inquiryId = params['InquiryId'];
            //if (this.projectOffer.inquiryId) {
            //    this.projectOffer.isSetInquiryAddress = true;
            //    this.getSelectedAddress(this.projectOffer.inquiryId, 1);
            //} else if (this.projectOffer.customerId) {
            //    this.projectOffer.isSetCustomerAddress = true;
            //    this.getSelectedAddress(this.projectOffer.customerId, 2);
            //}
        });        
        //if(!this.OfferInput){
        //    this._route.queryParams.subscribe(params => {
        //        this.projectOffer.customerId = params['customerId'] ? params['customerId'] : "";
        //    });
        //}
        this.model = new RequestProjectForOffer();
        this.model.IsActive = true;
        this.model.IsDeleted = false;
        this.offerId = this.OfferInput;
        if (!this.offerId) {
            this.GetCompanyInformation();
        }

        // if (this.offerId) {
        //     this.getProjectOffer(this.offerId);
        // } else {
        //     this.GetInquiry(this.model);
        //     this.GetCustomer(new RequestCustomer(), '');
        //     this.GetCompanyInformation();
        // }
        ////this.getProjectOfferList();        
        this.initFormControl();
        this.getOfferWithFullDetails(this.offerId);
    }

    ngAfterViewInit() {

    }

    initFormControl(){
        this.projectOfferForm = this.formBuilder.group({
            inquiryId: [null, ''],
            customerId: [null, Validators.compose([Validators.required])],
            projectId: [null, ''],
            description: [null, Validators.compose([Validators.required])],
            email: [null, [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$")]],
            departmentName: [null, ''],
            indoorsOutdoorsStatistIds: [null, Validators.compose([Validators.required])],
            status: [null, Validators.compose([Validators.required])],
            phoneNumber: [null, [Validators.required, spaceOnlyValidator.bind(this)]],
            city: [null, [Validators.required, spaceOnlyValidator.bind(this)]],
            departmentCity: [null, ''],
            postNumber: [null, [Validators.required]],
            departmentPostNumber: [null, ''],
            address: [null, [Validators.required, spaceOnlyValidator.bind(this)]],
            departmentAddress: [null, ''],
            customerName: [null, ''],
            requisitionId: [null, ''],
            countryCode: [null],
            propertyId: [null, ''],
            offerInvoiceTerms: [null, Validators.compose([Validators.required])],
        });
    }

    // ckchange(editor) {
    //     const EditorData = editor.getData();
    //     this.projectOfferForm.controls['description'].setValue(EditorData);
    //     this.projectOffer.description = EditorData;
    // }

    // getProjectOfferList() {
    //     this.requestModel = new RequestProjectOffer();
    //     this.projectofferService.Get<ResponseBase>(this.requestModel)
    //         .subscribe((response: ResponseBase) => {
    //             if (response != null) {
    //                 this.ProjectOfferList = response.result;
    //                 var approvedOffer = response.result.filter(x => x.status == 3)
    //                 if (approvedOffer.length > 0) {
    //                     this.authService.setApprovedOfferCount(approvedOffer.length);
    //                 }
    //                 else {
    //                     this.authService.setApprovedOfferCount(0);
    //                 }
    //             }
    //         },
    //             error => (err) => {
    //                 this.translate.get("MESSAGES.Failed").subscribe({
    //                     next: (res) => {
    //                         this.notificationsService.error(res);
    //                     },
    //                     error: err => {
    //                     }
    //                 });
    //             },
    //             () => {
    //             });
    // }

    getOfferWithFullDetails(id) {
        var offerid = id ? id : "";
        this.showSpinner = true;
        this.projectofferService.GetOfferWithFullDetails<ResponseBase>(offerid)
            .subscribe((response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null) {
                    this.projectOffer = response.result;
                    this.projectOffer.status = (this.projectOffer.status== 0 ? this.projectOffer.status=1 :this.projectOffer.status);
                    this.ProjectOfferOutput.emit(this.projectOffer);
                    if(this.projectOffer.id)
                        this.projectOffer.isActive = !this.projectOffer.isActive;
                    else
                        this.projectOffer.isActive = false;
                    this.setCompanyInfoProperties(response);
                    this.setInquiry(response);
                    this.setIndoorOutDoorDropDown();
                    this.setCustomer(response);
                    this.setProjectOnCustomer(response)
                    this.setRequisitionOnProject(response);
                }
                else {
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                            this.commonService.playFailedTone();
                        },
                        error: err => {
                        }
                    });
                }
            },
                error => (err) => {
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });
                });
    }

    /**
     * Start set method to set data in related object after page init call getOfferWithFullDetails()
     */
    setInquiry(response) {
        if (response && response.result && response.result.inquiries) {
            this.InquiryList = response.result.inquiries;
            var InquiryId = this.InquiryList.find(o => o.inquiryId == this.projectOffer.inquiryId);

            if (!this.projectOffer.id && this.projectOffer.inquiryId) {
                this.projectOffer.isSetInquiryAddress = true;
                this.getSelectedAddress(this.projectOffer.inquiryId, 1);
            }
        }
        else {
            this.InquiryList = [];
        }
    }

    setCustomer(response) {
        if (response && response.result && response.result.customers) {
            this.Customers = response.result.customers;
            setTimeout(() => {
                if (this.customerField && !this.offerId && this.onFocusCustDropbox)
                    this.customerField.focus();
            }, 100);
            var customerId = this.Customers.find(o => o.customerId == this.projectOffer.customerId);
            if (!customerId && this.projectOffer.customerId) {
                setTimeout(() => {
                    this.getSMSModuleData();
                }, 1000);
                this.setCustomerProperties(response)
            }
            if (!this.projectOffer.id && !this.projectOffer.inquiryId && this.projectOffer.customerId) {
                this.projectOffer.isSetCustomerAddress = true;
                this.getSelectedAddress(this.projectOffer.customerId, 2);
            }
        }
        else {
            this.Customers = [];
        }
    }

    setCompanyInfoProperties(response) {
        if (response && response.result && response.result.companyInfo) {
            this.companyInfoData = response.result.companyInfo;
            if (!this.projectOffer.description) {
                    this.projectOffer.description = this.companyInfoData.offerDescription;
            }
        }
    }

    setCustomerProperties(response) {
        if (response && response.result && response.result.customerProperties)
            this.customerProperties = response.result.customerProperties;
        else
            this.customerProperties = [];
    }

    setProjectOnCustomer(response) {
        this.unCheakedAddressCheckbox();
        this.RequisitionList = [];
        this.projectsList = [];
        if (response && response.result && response.result.customerProjects) {
            this.projectsList = response.result.customerProjects;
            if (this.projectsList) {
                var project = this.projectsList.filter(o => o.id == this.projectOffer.projectId);
                if (project != null && project.length > 0) {
                    this.GetProjectNumber.emit(project[0].projectNumber);
                }
            }
        }
        else {
            this.projectsList = [];
        }
        this.onFocusCustDropbox = false;
    }

    setRequisitionOnProject(response) {
        this.unCheakedAddressCheckbox();
        this.RequisitionList = [];

        if (response && response.result && response.result.projectRequisition) {
            this.RequisitionList = response.result.projectRequisition;
            this.projectOffer.requisitionId = this.RequisitionList[0].id;
        } else {
            this.RequisitionList = [];
        }
    }
    /**
     * End set method to set data in related object after page init call getOfferWithFullDetails()
     */

    getProjectOffer(id) {
        //var that = this;
        this.showSpinner = true;
        this.projectofferService.GetSingle<ResponseBase>(id)
            .subscribe((response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null) {
                    this.projectOffer = response.result;
                    this.ProjectOfferOutput.emit(this.projectOffer);
                    this.projectOffer.isActive = !this.projectOffer.isActive;
                    this.GetInquiry(this.model);
                    // this.GetCustomer(new RequestCustomer(), '');
                    this.setIndoorOutDoorDropDown();
                    this.getProjectOnCustomer(false, false);
                    this.getRequisitionOnProject(false);
                    //this.getProjectOfferRoomList();
                    if (this.projectsList) {
                        var project = this.projectsList.filter(o => o.id == this.projectOffer.projectId);
                        if (project != null && project.length > 0) {
                            this.GetProjectNumber.emit(project[0].projectNumber);
                        }
                    }
                }
                else {
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                            this.commonService.playFailedTone();
                        },
                        error: err => {
                        }
                    });
                }
            },
                error => (err) => {
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });
                });
    }

    setIndoorOutDoorDropDown() {
        this.projectOffer.indoorsOutdoorsStatistIds = new Array();
        if (this.projectOffer.indoorsOutdoorsStatistId) {
            if (this.projectOffer.indoorsOutdoorsStatistId.includes(',')) {
                var SplitIndoorOutdoorStatusId = this.projectOffer.indoorsOutdoorsStatistId.split(',');
                this.selectIndoorOutDoorStatus.filter(x => {
                    SplitIndoorOutdoorStatusId.filter(y => {
                        if (parseInt(y) == x.id)
                            this.projectOffer.indoorsOutdoorsStatistIds.push(x);
                    })
                });
            }
            else {
                this.selectIndoorOutDoorStatus.filter(x => {
                    if (parseInt(this.projectOffer.indoorsOutdoorsStatistId) == x.id)
                        this.projectOffer.indoorsOutdoorsStatistIds.push(x);
                });
            }
        }
    }

    convertProject() {
        // if (!this.ProjectsOfferRooms || this.ProjectsOfferRooms.length == 0) {
        //     const dialogRef = this.dialog.open(AlertMessageModelComponent, {
        //         height: '200px',
        //         data: { Header: 'MESSAGES.CanNotConvertToProject', ShowOKButton: true }
        //     });
        //     dialogRef.afterClosed().subscribe(result => {
        //         if (result) {

        //         }
        //     });
        // } else {
        const requestOfferConvert = new RequestOfferConvert();
        requestOfferConvert.offerId = this.offerId;
        this.showSpinner = true;
        this.projectofferService.ConvertOfferToProject<ResponseBase>(requestOfferConvert)
            .subscribe((response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null) {
                    this._router.navigate(['/projects/edit/' + response.result.id]);
                } else {
                    this.projectsList = [];
                }
            });
        // }
    }

    gotoProject(projectId) {
        this._router.navigate(['/projects/edit/' + projectId]);
    }

    save() {
        if (this.projectOffer.id != undefined) {
            this.Update();
        }
        else {
            this.Add();
        }
    }

    Add() {
        this.submitted = true;
        this.formService.markFormGroupTouched(this.projectOfferForm);
        if (this.projectOfferForm.valid) {
            this.showSpinner = true;
            if (this.projectOffer.indoorsOutdoorsStatistIds.includes(',')) {
                this.projectOffer.indoorsOutdoorsStatistId = this.projectOffer.indoorsOutdoorsStatistIds;
            } else {
                this.projectOffer.indoorsOutdoorsStatistId = this.projectOffer.indoorsOutdoorsStatistIds != undefined ? this.projectOffer.indoorsOutdoorsStatistIds.map(o => o.id).join(",") : "";
            }
            this.projectOffer.isActive = !this.projectOffer.isActive;
            this.projectofferService.Add<ResponseBase>(this.projectOffer)
                .subscribe((response: ResponseBase) => {
                    this.showSpinner = false;
                    if (response != null) {
                        this.projectOffer = response.result;
                        this.ProjectOfferOutput.emit(this.projectOffer);
                        this.projectOffer.isActive = !this.projectOffer.isActive;
                        this.getOfferApprovedCount();
                        this.submitted = false;
                        this.setIndoorOutDoorDropDown();
                        this.OfferOutput.emit({ offerId: this.projectOffer.id, number: this.projectOffer.number, mathod: "Add", inquiryId: this.projectOffer.inquiryId });
                        this.storeFileFolderPathData.emit();
                        this.translate.get("MESSAGES.Success").subscribe({
                            next: (res) => {
                                this.notificationsService.success(res);
                            },
                            error: err => {
                            }
                        });
                        this._router.navigateByUrl('offers/' + this.projectOffer.id);
                    }
                    else {
                        this.translate.get("MESSAGES.Failed").subscribe({
                            next: (res) => {
                                this.submitted = false;
                                this.notificationsService.error(res);
                                this.commonService.playFailedTone();
                            },
                            error: err => {
                            }
                        });
                    }
                },
                    error => (err) => {
                        this.showSpinner = false;
                        this.translate.get("MESSAGES.Failed").subscribe({
                            next: (res) => {
                                this.submitted = false;
                                this.notificationsService.error(res);
                            },
                            error: err => {
                            }
                        });
                    });
        }
    }
    Update() {
        this.submitted = true;
        this.formService.markFormGroupTouched(this.projectOfferForm);
        if (this.projectOfferForm.valid) {
            this.showSpinner = true;
            this.projectOffer.indoorsOutdoorsStatistId = this.projectOffer.indoorsOutdoorsStatistIds.map(o => o.id).join(",");
            this.projectOffer.isActive = !this.projectOffer.isActive;
            this.projectofferService.Update<ResponseBase>(this.projectOffer.id, this.projectOffer)
                .subscribe((response: ResponseBase) => {
                    this.showSpinner = false;
                    if (response != null) {
                        this.projectOffer = response.result;
                        this.ProjectOfferOutput.emit(this.projectOffer);
                        this.getOfferApprovedCount();
                        this.submitted = false;
                        this.projectOffer.isActive = !this.projectOffer.isActive;
                        this.setIndoorOutDoorDropDown();
                        this.OfferOutput.emit({ offerId: this.projectOffer.id, number: this.projectOffer.number, mathod: "Update", inquiryId: this.projectOffer.inquiryId });
                        this.storeFileFolderPathData.emit();
                        this.translate.get("MESSAGES.Success").subscribe({
                            next: (res) => {
                                this.notificationsService.success(res);
                            },
                            error: err => {
                            }
                        });
                    }
                    else {
                        this.translate.get("MESSAGES.Failed").subscribe({
                            next: (res) => {
                                this.submitted = false;
                                this.notificationsService.error(res);
                                this.commonService.playFailedTone();
                            },
                            error: err => {
                            }
                        });
                    }
                },
                    error => (err) => {
                        this.showSpinner = false;
                        this.translate.get("MESSAGES.Failed").subscribe({
                            next: (res) => {
                                this.submitted = false;
                                this.notificationsService.error(res);
                            },
                            error: err => {
                            }
                        });
                    });
        }
    }

    //getProjectsList(model) {
    //    this.projectofferService.GetProjectListForOffer<ResponseBase>(model)
    //        .subscribe((response: ResponseBase) => {
    //            if (response != null) {
    //                this.projectsList = response.result;
    //                //setTimeout(() => { 
    //                // if (this.offerId && this.projectsList && this.projectsList.length > 0) {
    //                //   that.getProjectOffer(this.offerId);
    //                // }
    //                //else
    //                //    this.projectOffer.projectId = null;
    //                //},2000)
    //            } else {
    //                this.projectsList = [];
    //            }
    //        });
    //}

    //DropDownSearchProject() {
    //    if (this.ProjectSearch) {
    //        var request = new RequestProjectForOffer();
    //        request.Search = this.ProjectSearch;
    //        try {
    //            if (!isNaN(Number(this.ProjectSearch.toString()))) {
    //                request.Search = "";
    //                request.Search = this.ProjectSearch;
    //            }
    //        } catch (e) {

    //        }
    //        this.getProjectsList(request);
    //    }
    //}

    onProjectSelectionChange() {
        this.clearCustomerDetail();
        this.unCheakedAddressCheckbox();
        if (this.projectsList) {
            var selectedObj = this.projectsList.filter(o => o['sagId'] == this.projectOffer.projectId)[0];
            if (selectedObj) {
                this.projectOffer.projectId = selectedObj['sagId'];
                this.projectOffer.requisitionId = selectedObj.requisitionId;
                this.projectOffer.address = selectedObj['address1'];
                this.projectOffer.postNumber = selectedObj['zipCode'];
                this.projectOffer.city = selectedObj.city;
                this.projectOffer.email = selectedObj.email;
                this.projectOffer.phoneNumber = selectedObj['phone'];
                this.projectOffer.indoorsOutdoorsStatistId = selectedObj.indoorsOutdoorsStatistId;
                this.projectOffer.isSetProjectAddress = true;
                this.getSelectedAddress(this.projectOffer.projectId, 3);
                //if (this.projectOffer.indoorsOutdoorsStatistID)
                //  this.projectOffer.indoorsOutdoorsStatistID = selectedObj.indoorsOutdoorsStatistID.split(",");
                //else
                //  this.projectOffer.indoorsOutdoorsStatistID = [];
                this.getRequisitionOnProject(false);
            }
        }
    }

    onSelectRequisition() {
        this.clearCustomerDetail();
        this.unCheakedAddressCheckbox();
    }

    clearSelectedInquiry() {
        this.projectOffer.inquiryId = "";
    }
    clearSelectedCustomer() {
        this.projectOffer.customerId = ""
    }
    clearSelectedProject() {
        this.projectOffer.projectId = "";
    }
    clearSelectedRequisition() {
        this.projectOffer.requisitionId = "";
    }


    getOfferApprovedCount() {
        this.projectofferService.GetOfferApprovedCountApi().subscribe({
            next: (response: ResponseBase) => {
                if (response != null) {
                    this.offerApprovedCount = response.result;
                    this.authService.setApprovedOfferCount(this.offerApprovedCount);
                }
            },
            error: err => {
            },
            complete: (
            ) => {
            }
        });
    }

    /**
    *  Get Inquiry List
    */
    GetInquiry(model) {
        // this.showSpinner = true;
        this.inquiryService.Get<ResponseBase>(model)
            .subscribe((response: ResponseBase) => {
                if (response != null) {
                    this.InquiryList = response.result;
                    var InquiryId = this.InquiryList.find(o => o.inquiryId == this.projectOffer.inquiryId);
                    if (!InquiryId && this.projectOffer.inquiryId) {
                        this.getInquiryById(this.projectOffer.inquiryId)
                    }
                    if (!this.projectOffer.id && this.projectOffer.inquiryId) {
                        this.projectOffer.isSetInquiryAddress = true;
                        this.getSelectedAddress(this.projectOffer.inquiryId, 1);
                    }
                    this.showSpinner = false;
                }
                else {
                    this.InquiryList = [];
                }
            },
                error => (err) => {
                    this.InquiryList = [];
                    this.showSpinner = false;
                },
                () => {
                    this.showSpinner = false;
                });
    }

    /**
    * Customer Search 
    */
    InquiryDropDownSearch() {
        if (this.InquirySearch) {
            var request = new RequestInquiry();
            request.Name = this.InquirySearch;
            request.IsActive = this.isShowInactiveInquiry ? false : true;
            try {
                if (!isNaN(Number(this.InquirySearch.toString()))) {
                    request.Name = "";
                    request.Name = this.InquirySearch;
                }
            } catch (e) {

            }
            this.GetInquiry(request);
        }
    }

    InquiryDropDownOnClearSearch() {
        if (!this.InquirySearch) {
            this.GetInquiry(new RequestInquiry());
        }
    }


    /**
    *  Get Customers List
    */
    GetCustomer(model, searchId) {
        model.Limit = 10000;
        model.OrderBy = "Text";
        this.customersService.Get<ResponseBase>(model)
            .subscribe((response: ResponseBase) => {
                if (response) {
                    this.Customers = response.result;
                    setTimeout(() => {
                        if (this.customerField && !this.offerId && this.onFocusCustDropbox)
                            this.customerField.focus();
                    }, 100);
                    var customerId = this.Customers.find(o => o.customerId == this.projectOffer.customerId);
                    if (!customerId && this.projectOffer.customerId) {
                        this.getCustomerById(this.projectOffer.customerId);
                        this.GetCustomerProperties(this.projectOffer.customerId)
                    }
                    if (!this.projectOffer.id && !this.projectOffer.inquiryId && this.projectOffer.customerId) {
                        this.projectOffer.isSetCustomerAddress = true;
                        this.getSelectedAddress(this.projectOffer.customerId, 2);
                    }
                }
                else {
                    this.Customers = [];
                }
            },
                error => (err) => {
                    this.Customers = [];
                });
    }

    /**
    * Customer Search 
    */
    DropDownSearch() {
        if (this.CustomerSearch) {
            var request = new RequestCustomer();
            request.Name = this.CustomerSearch;
            try {
                if (!isNaN(Number(this.CustomerSearch.toString()))) {
                    request.Name = "";
                    request.Name = this.CustomerSearch;
                }
            } catch (e) {

            }
            this.onFocusCustDropbox = false;
            this.GetCustomer(request, '');
        }
    }

    CustomerDropDownOnClearSearch() {
        this.onFocusCustDropbox = false;
        this.GetCustomer(new RequestCustomer(), '');
    }

    /**
    * Get Project List
    */
    getProjectOnCustomer(flag, isClearCustDetail) {
        if (isClearCustDetail) {
            this.clearCustomerDetail();
        }
        this.unCheakedAddressCheckbox();
        this.RequisitionList = [];
        this.projectsList = [];
        if (flag) {
            this.projectOffer.inquiryId = "";
        }
        if (this.projectOffer.customerId) {
            var model = new RequestProject();
            this.showSpinner = true;
            model.IsActive = true;
            model.IsDeleted = false;
            model.CustomerId = this.projectOffer.customerId;
            this.customersService.GetProjectsByCustomer<ResponseBase>(this.projectOffer.customerId, model)
                .subscribe((response: ResponseBase) => {
                    if (response != null) {
                        this.projectsList = response.result;
                        //this.projectOffer.isSetCustomerAddress = true;
                        //this.getSelectedAddress(this.projectOffer.customerId, 2);
                        //if (this.projectsList.length > 0) {
                        //  this.projectOffer.projectId = this.projectsList[0]['sagId'];
                        //  this.getRequisitionOnProject(isClearCustDetail);
                        //}
                        this.showSpinner = false;
                    }
                    else {
                        this.projectsList = [];
                    }
                },
                    error => (err) => {
                        this.projectsList = [];
                        this.showSpinner = false;
                    },
                    () => {
                        this.showSpinner = false;
                    });
        }
        this.onFocusCustDropbox = false;
        this.GetCustomer(new RequestCustomer(), '');
    }

    /**
     * Clear Customer details
     */
    clearCustomerDetail() {
        this.projectOffer.email = null;
        this.projectOffer.customerName = null;
        this.projectOffer.phoneNumber = null;
        this.projectOffer.city = null;
        this.projectOffer.postNumber = null;
        this.projectOffer.address = null;
        this.projectOffer.countryCode = null;
    }

    unCheakedAddressCheckbox() {
        this.projectOffer.isSetInquiryAddress = false
        this.projectOffer.isSetCustomerAddress = false;
        this.projectOffer.isSetProjectAddress = false;
        this.projectOffer.isSetRequisitionAddress = false;
    }

    /**
     * Get Customer on Inquiry
     */

    getCustomerOnInquiry() {
        this.inquiryObj = new InquiryCustomer();
        this.clearCustomerDetail();
        this.unCheakedAddressCheckbox();
        if (this.projectOffer.inquiryId) {
            this.projectsList = [];
            this.inquiryObj = this.InquiryList.find(o => o.id == this.projectOffer.inquiryId);
            if (this.inquiryObj.customerId) {
                var custId = this.inquiryObj.customerId;
                var customerDetail = this.Customers.find(c => c.id == custId);
                if (customerDetail) {
                    this.projectOffer.customerId = customerDetail.id;
                    this.getProjectOnCustomer(false, true);
                } else {
                    this.getCustomerById(custId);
                    this.projectOffer.customerId = custId;
                    this.getProjectOnCustomer(false, true);
                }
                this.projectOffer.isSetInquiryAddress = true;
                this.getSelectedAddress(this.projectOffer.inquiryId, 1)

            } else {
                this.projectOffer.customerId = "";
                this.projectOffer.isSetInquiryAddress = true;
                this.getSelectedAddress(this.projectOffer.inquiryId, 1)
            }
        }
        //this.GetInquiry(new RequestInquiry());
    }

    /**
    * Get Requisition List 
    */
    getRequisitionOnProject(isClearCustDetail) {
        if (isClearCustDetail) {
            this.clearCustomerDetail();
        }
        this.unCheakedAddressCheckbox();
        this.RequisitionList = [];
        if (this.projectOffer.projectId) {
            var model = new OfferProject();
            this.showSpinner = true;
            model.IsActive = true;
            model.IsDeleted = false;
            model.ProjectId = this.projectOffer.projectId;
            this.projectRequisitionService.Get<ResponseBase>(model.ProjectId, model).subscribe({
                next: (response: ResponseBase) => {
                    if (response) {
                        this.showSpinner = false;
                        this.RequisitionList = response.result;
                        this.projectOffer.requisitionId = this.RequisitionList[0].id;
                    } else {
                        this.RequisitionList = [];
                    }
                },
                error: err => {
                    this.RequisitionList = [];
                },
                complete: () => {
                    this.showSpinner = false;
                }
            });
        }
    }

    /**
    * Select Address from Selected dropdown
    */
    getSelectedAddress(id, value) {
        this.clearCustomerDetail();
        if (id && value) {
            //Inquiry Address
            if (value == 1) {
                this.projectOffer.isSetCustomerAddress = false;
                this.projectOffer.isSetProjectAddress = false;
                this.projectOffer.isSetRequisitionAddress = false;
                this.inquiryService.GetSingle<ResponseBase>(this.projectOffer.inquiryId).subscribe({
                    next: (response: ResponseBase) => {
                        this.showSpinner = false;
                        var inquiryObj = response.result;
                        this.projectOffer.email = inquiryObj.email;
                        this.projectOffer.customerName = inquiryObj.name;
                        this.projectOffer.phoneNumber = inquiryObj.phoneNo;
                        this.projectOffer.city = inquiryObj.city;
                        this.projectOffer.postNumber = inquiryObj.postNo;
                        this.projectOffer.address = inquiryObj.address;
                        this.projectOffer.indoorsOutdoorsStatistId = inquiryObj.indoorOutdoorStatusId;
                        this.projectOffer.description = inquiryObj.comments;
                        this.projectOffer.countryCode = inquiryObj.countryCode;
                        this.setIndoorOutDoorDropDown();
                    },
                    error: err => {
                        this.showSpinner = false;
                    },
                    complete: () => {

                    }
                });
            }

            //Customer Address
            if (value == 2) {
                this.projectOffer.isSetInquiryAddress = false
                this.projectOffer.isSetProjectAddress = false;
                this.projectOffer.isSetRequisitionAddress = false;
                this.projectOffer.isSetCustomerAddress = true;
                this.customersService.GetSingle<ResponseBase>(this.projectOffer.customerId).subscribe({
                    next: (response: ResponseBase) => {
                        this.showSpinner = false;
                        var customerObject = response.result;
                        this.projectOffer.email = customerObject.email;
                        this.projectOffer.customerName = customerObject.name;
                        this.projectOffer.phoneNumber = customerObject.phone;
                        this.projectOffer.city = customerObject.city;
                        this.projectOffer.postNumber = customerObject.pinCode;
                        this.projectOffer.address = customerObject.address;
                        this.projectOffer.indoorsOutdoorsStatistId = customerObject.indoorOutdoorStatusId;
                        this.projectOffer.countryCode = customerObject.countryCode;
                        this.setIndoorOutDoorDropDown();
                    },
                    error: err => {
                        this.showSpinner = false;
                    },
                    complete: () => {

                    }
                });
            }
            //Project Address
            if (value == 3) {
                this.projectOffer.isSetInquiryAddress = false
                this.projectOffer.isSetCustomerAddress = false;
                this.projectOffer.isSetRequisitionAddress = false;
                this.projectService.GetSingle<ResponseBase>(this.projectOffer.projectId).subscribe({
                    next: (response: ResponseBase) => {
                        var ObjProject = response.result;
                        this.projectOffer.email = ObjProject.email;
                        this.projectOffer.customerName = ObjProject.customerName;
                        this.projectOffer.phoneNumber = ObjProject.phone;
                        this.projectOffer.city = ObjProject.city;
                        this.projectOffer.postNumber = ObjProject.zipCode;
                        this.projectOffer.address = ObjProject.address1;
                        this.projectOffer.countryCode = ObjProject.countryCode;
                        this.projectOffer.indoorsOutdoorsStatistIds = ObjProject.indoorOutdoorStatusId;
                    },
                    error: err => {
                        this.showSpinner = false;
                    },
                    complete: () => {

                    }
                });
            }
            //Requisition Address
            if (value == 4) {
                this.projectOffer.isSetInquiryAddress = false
                this.projectOffer.isSetCustomerAddress = false;
                this.projectOffer.isSetProjectAddress = false;
                this.projectRequisitionService.GetSingle(this.projectOffer.projectId, this.projectOffer.requisitionId).subscribe({
                    next: (response: ResponseBase) => {
                        this.showSpinner = false;
                        var objectRequisition = response.result;
                        this.projectOffer.email = objectRequisition.email;
                        this.projectOffer.customerName = objectRequisition.departmentName;
                        this.projectOffer.phoneNumber = objectRequisition.departmentPhone;
                        this.projectOffer.city = objectRequisition.departmentCity;
                        this.projectOffer.postNumber = objectRequisition.departmentZipCode;
                        this.projectOffer.address = objectRequisition.departmentAddress;
                        this.projectOffer.countryCode = objectRequisition.countryCode;
                    }
                });
            }
        }
    }

    getCustomerById(customerId) {
        this.customersService.GetSingle<ResponseBase>(customerId).subscribe({
            next: (response: ResponseBase) => {
                var customerObject = response.result;
                this.customerDetailObj = response.result;
                this.CustomerOutput.emit(this.customerDetailObj);
                const customerArrayObject = {
                    id: customerObject.id,
                    name: customerObject.name,
                    number: customerObject.number,
                    phone: customerObject.phone,
                    email: customerObject.email,
                    comment: "",
                    indoorOutdoorStatusId: "",
                    isActive: customerObject.isActive,
                    emailInvoice: "",
                };
                //this.Customers.push(customerArrayObject);
                setTimeout(() => {
                    this.getSMSModuleData();
                }, 1000);
            },
            error: err => {
                this.showSpinner = false;
            },
            complete: () => {

            }
        });
    }

    getInquiryById(inquiryId) {
        this.inquiryService.GetSingle<ResponseBase>(inquiryId).subscribe({
            next: (response: ResponseBase) => {
                var inquiryObj = response.result;
                var InquiryObjArray = {
                    id: inquiryObj.id,
                    name: inquiryObj.name,
                    phoneNo: inquiryObj.phoneNo,
                    email: inquiryObj.email,
                    address: inquiryObj.address,
                    postNo: inquiryObj.postNo,
                    city: inquiryObj.city,
                    isFeildManually: inquiryObj.isFeildManually,
                    received: inquiryObj.received,
                    isNote: inquiryObj.isNote,
                    coworkerComment: inquiryObj.coworkerComment,
                    source: inquiryObj.source,
                    number: inquiryObj.number,
                    isRead: inquiryObj.isRead,
                    createdDate: inquiryObj.createdDate,
                    isActive: inquiryObj.isActive,
                    isDeleted: inquiryObj.isDeleted,
                    emailInvoice: inquiryObj.emailInvoice
                }
                this.InquiryList.push(InquiryObjArray);
            },
            error: err => {
                this.showSpinner = false;
            },
            complete: () => {

            }
        });
    }

    AddCustomer() {
        if (this.projectOffer.customerId) {
            const dialogRef = this.dialog.open(AlertMessageModelComponent, {
                height: 'auto',
                width: '90%',
                data: { Header: 'Headers.CopyCustomerDetails' }
            });
            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.AddCustomerModelPopUp(true);
                }
                else
                    this.AddCustomerModelPopUp(false);
            });
        } else {
            this.AddCustomerModelPopUp(false);
        }
    }

    AddCustomerModelPopUp(openWithDetail) {
        var sentData = {};
        if (openWithDetail) {
            this.getCustomerById(this.projectOffer.customerId);
            var data = this.customerDetailObj;
            sentData = {
                'city': data.city,
                'pinCode': data.pinCode,
                'phone': data.phone,
                'name': data.name,
                'email': data.email,
                'address': data.address,
                isRedirectToOffer: true,
                IsAdd: true
            }
        }
        if (!openWithDetail && !this.projectOffer.customerId) {
            sentData = {
                'city': this.projectOffer.city,
                'pinCode': this.projectOffer.postNumber,
                'phone': this.projectOffer.phoneNumber,
                'name': this.projectOffer.customerName,
                'email': this.projectOffer.email,
                'address': this.projectOffer.address,
                isRedirectToOffer: true,
                IsAdd: true
            }
        }
        const dialogRef = this.dialog.open(NewCustomerComponent, {
            height: 'auto',
            width: '90%',
            disableClose: true,
            data: sentData,
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result != null && result != '') {
                this.projectOffer.customerId = result;
                this.GetCustomer([], result);
            }
        });
    }

    AddProject() {
        var custId = this.projectOffer.customerId;
        var offerId = this.projectOffer.id
        this._router.navigate(["/projects/add/offer/" + custId + "/" + offerId]);
    }

    ViewProject() {
        this._router.navigate(["/projects/edit/" + this.projectOffer.projectId]);
    }

    _handleKeydown(event: KeyboardEvent) {
        if (event.keyCode === 13) {
            event.stopPropagation();
        }
    }

    getSMSModuleData() {
        var moduleObj = new SMSModules()
        moduleObj.name = this.customerDetailObj ? this.customerDetailObj.name: null;
        moduleObj.moduleID = this.projectOffer.id;
        moduleObj.mobile = this.projectOffer.phoneNumber;
        moduleObj.flag = SMSModuleEnum.Offer;
        moduleObj.countryCode = this.projectOffer.countryCode;
        this.smsObj.emit(moduleObj);
    }

    isNumber(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    searchCityFromZipcode() {
        var zip = postalcodeJson.filter(o => o.zip == this.projectOffer.postNumber);
        if (zip.length > 0) {
            this.projectOffer.city = zip[0].city;
        }
        else {
            this.projectOffer.city = "";
        }
    }

    //Get Room List
    // getProjectOfferRoomList() {
    //     this.requestProjectOfferRoom.Limit = 100;
    //     this.requestProjectOfferRoom.OfferId = this.offerId;
    //     this.projectofferService.getProjectOfferRoomList<ResponseBase>(this.requestProjectOfferRoom)
    //         .subscribe((response: ResponseBase) => {
    //             this.ProjectsOfferRooms = [];
    //             if (response != null) {
    //                 this.ProjectsOfferRooms = response.result.myResponse;
    //             }
    //         },
    //             error => (err) => {

    //             });
    // }

    showDirection() {
        const dialogRef = this.dialog.open(GoogleMapDirectionComponent, {
            width: '80%',
            height: '80%',
            data: {
                Address: this.projectOffer.address,
                ZipCode: this.projectOffer.postNumber,
                City: this.projectOffer.city
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
            }
        });
    }

    GetCompanyInformation() {
        this.showSpinner = true;
        this.companyInfoService.GetCompanyInformationData<ResponseBase>()
            .subscribe((response: ResponseBase) => {
                if (response) {
                    this.companyInfoData = response.result;
                    if (this.companyInfoData.invoiceTermDescription) {
                            setTimeout(() => {
                                //this.projectOffer.description = this.companyInfoData.invoiceTermDescription;
                                this.projectOfferForm.controls['offerInvoiceTerms'].setValue(this.companyInfoData.invoiceTermDescription);
                            }, 2000);

                    }
                }
            },
                (err: any) => {
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        }, error: err => { }
                    });
                });
    }

    ActiveBoxChange() {
        this.save();
    }

    onStateChange() {
        if (this.projectOffer.status >= 3) {
            this.projectOffer.isActive = true;
        }
        this.save();
    }

    GetCustomerProperties(customerID) {
        if (customerID) {
            this.showSpinner = true;
            this.requestCustomerProperty.Index = 1;
            this.requestCustomerProperty.Limit = 100;
            this.requestCustomerProperty.CustomerID = customerID;
            this.requestCustomerProperty.OrderBy = 'Text';
            this.customersService.GetCustomerProperties<ResponseBase>(this.requestCustomerProperty)
                .subscribe((response: ResponseBase) => {
                    if (response != null)
                        this.customerProperties = response.result;
                    else
                        this.customerProperties = [];
                },
                    error => (err) => {
                        this.customerProperties = [];
                    },
                    () => {
                        this.showSpinner = false;
                    });
        }
    }


    clearSelectedProperty() {
        this.projectOffer.propertyId = "";
    }

    getInActiveInquiry() {
        var request = new RequestInquiry();
        request.Name = this.InquirySearch;
        request.IsActive = this.isShowInactiveInquiry ? false : true;
        try {
            if (!isNaN(Number(this.InquirySearch.toString()))) {
                request.Name = "";
                request.Name = this.InquirySearch;
            }
        } catch (e) { }
        this.GetInquiry(request);
    }
    ShowCustomerModelPopUp(id) {
        const dialogRef = this.dialog.open(NewCustomerComponent, {
            height: 'auto',
            width: '90%',
            data: { isRedirectToInquiry: true, isAdd: false, id: id },
        });

        dialogRef.afterClosed().subscribe(result => {
            //this.titleService.SetTitle("TITLE_INQUIRY");
        });
    }

    AddCustomerFromInquiry() {
        const dialogRef = this.dialog.open(NewCustomerComponent, {
            height: 'auto',
            width: '90%',
            disableClose: true,
            data: { isRedirectToInquiry: true, isAdd: true, id: this.projectOffer.inquiryId },
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.assignedCustomerId = result;
                this.UpdateInquiry(false);
                this.projectOffer.customerId = result;
                this.GetCustomer([], result);
            }
        });
    }

    ngOnChangeCustomerSelected(id) {
        this.InquiryCustomerList.filter(x => {
            if (x.id != id) {
                x.isSelected = false;
            }
            else {
                if (this.assignedCustomerId && this.assignedCustomerId == id) {
                    this.assignedCustomerId = null;
                }
                else {
                    this.assignedCustomerId = id;
                    this.onChangeCustomer(id);
                }
            }
        });
    }

    onChangeCustomer(selectedCustomer) {
        if (this.Customers) {
            var data = this.Customers.find(c => c.id == selectedCustomer);
            var msg = "";
            this.translate.get("Headers.CustomerDetail").subscribe({
                next: (res) => { msg = res; }, error: err => { }
            });
            var custDetail = data.number + " - " + data.name + " " + data.address + " " + data.pinCode + " " + data.city;
            var inqDetail = this.inquiryObj.number + " - " + this.inquiryObj.name + " " + this.inquiryObj.address + " " + this.inquiryObj.postNo + " " + this.inquiryObj.city;
            msg = msg.replace("@customerdetail", custDetail).replace("@inquirydetail", inqDetail);
            const dialogRef = this.dialog.open(AlertMessageModelComponent, {
                width: '50%',
                data: { Header: msg }
            });

            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    if (data) {
                        this.inquiryObj.customerId = data.id;
                        this.inquiryObj.customerNumber = data.number;
                        this.inquiryObj.name = data.name;
                        this.inquiryObj.email = data.email;
                        this.inquiryObj.emailInvoice = data.emailInvoice;
                        this.inquiryObj.phoneNo = data.phone;
                        this.inquiryObj.customerName = data.name;
                        this.inquiryObj.customerAddress = data.address;
                        if (data.comingFrom && data.comingFrom != 0) {
                            this.inquiryObj.source = data.comingFrom;
                        } 
                        // else {
                        //     this.inquiryObj.source = 6;
                        // }
                        this.inquiryObj.indoorOutdoorStatusId = data.indoorOutdoorStatusId;
                        this.setIndoorOutDoorDropDown()
                        this.inquiryObj.address = data.address ? data.address : '';
                        this.inquiryObj.city = data.city ? data.city : '';
                        this.inquiryObj.postNo = data.pinCode ? data.pinCode : '';
                        this.inquiryObj.comments = data.comment ? data.comment : '';
                        this.UpdateInquiry(false);
                        this.projectOffer.customerId = this.inquiryObj.customerId;
                        this.GetCustomer([], this.inquiryObj.customerId);
                    }
                }
            });
        }
    }

    showExistingCustomer() {
        this.InquiryCustomerList = [];
        this.showExistingCustomerList = !this.showExistingCustomerList;
        this.projectService.GetMatchCustomersOnInquiry<ResponseBase>(this.projectOffer.inquiryId)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.showSpinner = false;
                    this.InquiryCustomerList = response.result.myResponse;
                },
                error: err => {
                    this.showSpinner = false;
                },
                complete: () => {
                    this.showSpinner = false;
                }
            });
    }

    UpdateInquiry(showMessage) {
        this.showSpinner = true;
        this.inquiryObj.customerId = this.assignedCustomerId;
        if (this.inquiryObj.received) {
            if (this.inquiryObj.receivedTime)
                this.inquiryObj.received = moment.utc(this.inquiryObj.received + " " + this.inquiryObj.receivedTime).format("YYYY-MM-DD HH:mm");
            else
                this.inquiryObj.received = moment.utc(this.inquiryObj.received).format("YYYY-MM-DD");
        }
        this.inquiryService.Update<ResponseBase>(this.inquiryObj.id, this.inquiryObj)
            .subscribe({
                next: (response: ResponseBase) => {
                    if (response.result) {
                        this.showExistingCustomerList = false;
                        this.inquiryObj = response.result;
                        if (this.inquiryObj.received) {
                            this.inquiryObj.receivedTime = moment.utc(this.inquiryObj.received).format("HH:mm") == "00:00" ? "" : moment.utc(this.inquiryObj.received).format("HH:mm");
                            this.inquiryObj.received = moment.utc(this.inquiryObj.received).format("YYYY-MM-DD");
                        }
                    }
                    this.showSpinner = false;
                    if (showMessage) {
                        this.translate.get("MESSAGES.Updated").subscribe({
                            next: (res) => {
                                this.notificationsService.create(res);
                            },
                            error: err => {
                            }
                        });
                    }
                },
                error: err => {
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        },
                        error: err => {
                        }
                    });
                }
            });
    }

    addCustomerProperty() {
        if (this.projectOffer.customerId) {
            let sendData = {
                'customerId': this.projectOffer.customerId,
            }
            const dialogRef = this.dialog.open(CustomerPropertiesComponent, {
                height: 'auto',
                width: '90%',
                disableClose: false,
                data: sendData,
            })
            dialogRef.afterClosed().subscribe( result => {
                if(result)
                    this.GetCustomerProperties(this.projectOffer.customerId);
            });
        } 
    }

    propertySelectionChange(event, data) {
        if (event) {
            this.projectOffer.address = data.address ? data.address : '';
            this.projectOffer.city = data.city ? data.city : '';
            this.projectOffer.postNumber = data.pinCode ? data.pinCode : '';
        }
    }
}
