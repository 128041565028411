export const locale = {
    lang: 'en',
    data: {
        'TITLE': {
            'HeaderForBettery': 'Vehicle Battery Notification',
        },
        'FIELDS': {
            'HoursToSend': 'Hours to send',
            'PercentageToSend':  'Send x percentage before',
            'VoltageToSend': 'Send x voltage before',
            'SMSText': 'SMS text',
            'NotificationText': 'Notification text',
            'EmailText': 'Email text',
            'ExecuteTime':'Execute Time',
            'IsCreateReminder':'Is Create Reminder?',
            'BetteryNoteLabel':'Please do not remove @BatteryLowDeviceList from Email template text.',
            'NumberofDays':'Number of days',
        },
        'VALIDATIONS': {
            'HoursToSend': 'Hours to send is required',
            'PercentageToSend': 'percentage to send is required',
            'VoltageToSend': 'voltage to send is required',
            'SMSText': 'SMS text is required',
            'NotificationText': 'Notification text is required',
            'EmailText': 'Email text is required',
            'ExecuteTime':'Execution time is required'
        },
        'MESSAGES': {
            'Saved': 'Saved successfully.',
        },
        'BUTTONS': {
            'Save': 'Save',
        }
    }
};