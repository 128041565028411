import { Component, OnInit, ViewChild, Output, EventEmitter, Input, ChangeDetectorRef } from '@angular/core';
import { MatOption, MatPaginator, MatSort } from '@angular/material';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { Router } from '@angular/router';
import { MaterialService } from 'app/main/pages/material/material.service';
import { Material, MaterialReport, MaterialReportSearch, MaterialReportForExcel } from 'app/main/model/Material';
import { RequestCoWorker, RequestMaterialForReport } from 'app/main/model/RequestBase';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { AuthService } from 'app/core/services/auth.service';
import { TitleService } from 'app/core/services/title.service';
import { CompanyInfoService } from 'app/main/pages/companyInfo/companyInfo.service';
import { ReminderService } from 'app/main/pages/reminder/reminder.service';
import { EmailSendingParam } from 'app/main/model/common';
import { TextEditorEmailSendComponent } from 'app/main/shared-component/text-editor-email-send/text-editor-email-send.component';
// import { drawDOM, exportPDF, DrawOptions, Group } from '@progress/kendo-drawing';
import * as moment from 'moment';
import { MatDialog } from '@angular/material';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { DenmarkDigitFormate } from 'app/core/pipes/denmarkDigitFormate.pipe';
import { PdfViewerModelComponent } from 'app/main/pages/pdf-viewer/pdf-viewer.component';
import { MaterialTypeCategory } from 'app/constant/common.constant';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { BehaviorSubject } from 'rxjs';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { SortPipe } from 'app/core/pipes/sort.pipe';
import { CommonService } from 'app/core/services/common.service';
import { ProjectofferService } from 'app/main/pages/project-offer/project-offer.service';
import { AlertMessageModelComponent } from "app/main/pages/alert-message-model/alert-message-model.component";
import * as ExcelJS from 'exceljs';
import { CompanyInfo } from 'app/main/model/CompanyInfo';
import { environment } from 'environments/environment';

@Component({
    selector: 'vibe-materialreport',
    templateUrl: './material.component.html',
    styleUrls: ['./material.component.scss']
})
export class MaterialReportComponent implements OnInit {
    private _SelectedTab = new BehaviorSubject<number>(null);
    @Input()
    set SelectedTab(value) {
        this._SelectedTab.next(value);
    };
    get SelectedTab() {
        return this._SelectedTab.getValue();
    }

    @Output() IsTabLoading = new EventEmitter<any>();
    Materials: Material[];
    AllMaterials: any[];
    auth: any;
    search: any = '';
    number: any = '';
    displayPagination: boolean;
    pagination: any;
    showSpinner: boolean = false;
    requestMaterial: RequestMaterialForReport = new RequestMaterialForReport();
    materialReportList: MaterialReport[];
    materialDeletedReportList: MaterialReport[];
    //Arrays to dispaly rows in datatable
    //roleName: string;
    orderByField: string = "text";
    orderByDirection: string = "asc";
    isValid: boolean = false;
    isActive: boolean = false;
    isDeleted: boolean = false;
    MaterialReportSearch = MaterialReportSearch;
    selectMaterialTypeList = MaterialTypeCategory;
    materialStatus: number = 0;
    isShowPdf = false;
    coWokers = [];
    orderDate: string = "";
    materialSum: number = 0;
    type: any = 0;
    displayedColumns = ['itemNumber', 'icon', 'text', 'retailPrice', 'purchasePrice', 'personalPrice', 'holdings', 'minInventory', 'maxInventory', 'unit', 'shelfNo', 'total'];
    dataSource = new TableVirtualScrollDataSource<MaterialReport>();
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;
    @ViewChild(CdkVirtualScrollViewport, { static: false }) viewport: CdkVirtualScrollViewport;
    @ViewChild('allOfferTypeSelected', { static: false }) private allOfferTypeSelected: MatOption;
    currentSelectedOfferTypeObj: number[];
    isShowOffer: boolean = false;
    public denmarkDigitFormate = new DenmarkDigitFormate();
    isShowPersonalPriceorMinMaxNumber: boolean = false;
    companyInfoData: CompanyInfo;
    baseUrl: string = environment.url;
    //Initiators
    constructor(private translationLoader: FuseTranslationLoaderService,
        private _router: Router,
        public dialog: MatDialog,
        private materialService: MaterialService, private authService: AuthService,
        private reminderService: ReminderService,
        public companyInfoService: CompanyInfoService,
        private notificationsService: NotificationsService,
        private translate: TranslateService,
        private titleService: TitleService,
        private commonService: CommonService,
        private projectofferService: ProjectofferService,
        private changeDetector: ChangeDetectorRef,
        private sortPipe: SortPipe
    ) {
        this.translationLoader.loadTranslations(english, danish);
        this.titleService.SetTitle("TITLE_REPORT");
    }

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
        this.dataSource.filter = filterValue;
    }

    sortData(e) {
        this.materialSum = 0;
        this.orderByField = e['active'];
        this.orderByDirection = e['direction'];
        if (this.orderByDirection == "asc") {
            this.requestMaterial.OrderBy = `${this.orderByField}`
        }
        else {
            this.requestMaterial.OrderBy = `${this.orderByField} ${this.orderByDirection}`
        }
        //this.GetMaterial(this.requestMaterial);
        var mat = this.AllMaterials;
        if (!this.isActive) {
            mat = mat.filter(o => (o.isActive != false));
        }
        var mater = this.sortPipe.transform(mat, this.orderByDirection, this.orderByField);
        this.dataSource = new TableVirtualScrollDataSource(mater);
    }

    ngOnInit() {
        this._SelectedTab
            .subscribe(i => {
                if (i == 0) {
                    if (this.viewport) {
                        this.viewport.setRenderedContentOffset(0)
                        this.viewport.setRenderedRange({ start: 0, end: 24 })
                    }
                }
            })

        // this.roleName = this.authService.getUserRole();

        // if (this.roleName == "Admin") {
        //   this.isValid = true;
        // }
        // else if (this.roleName == "Kontor") {
        //   this.isValid = true;
        // }
        // else if (this.roleName == "Maler") {
        //   this.isValid = false;
        // }
        // else if (this.roleName == "Gaest") {
        //   this.isValid = false;
        // }
        // else {
        //   this.isValid = true;
        // }

        this.materialReportList = [];
        this.orderDate = moment(new Date()).format("DD.MM.YYYY HH:mm");
        this.requestMaterial.IsActive = false;
        this.requestMaterial.IsDeleted = false;

        this.requestMaterial.Limit = 10000;
        if (this.orderByDirection == "asc") {
            this.requestMaterial.OrderBy = `${this.orderByField}`
        }
        else {
            this.requestMaterial.OrderBy = `${this.orderByField} ${this.orderByDirection}`
        }
        this.GetMaterial(this.requestMaterial, true);
        // this.requestMaterial.IsDeleted = true;
        // this.requestMaterial.Limit = 10000;
        // this.GetMaterial(this.requestMaterial);
        this.paginator._intl.itemsPerPageLabel = "Antal materialer pr. side";
        let coWorker = new RequestCoWorker();
        coWorker.IsDeleted = false;
        this.getCoWorkers(coWorker);
    }

    ngAfterViewInit() {
        this.changeDetector.detectChanges();
    }

    GetMaterial(requestModel, isLoadActive = false) {
        this.materialSum = 0;
        this.IsStartLoading(true)
        this.materialService.GetMaterialsForReport<ResponseBase>(requestModel)
            .subscribe((response: ResponseBase) => {
                if (response != null) {
                    this.Materials = response.result;
                    if (requestModel.IsDeleted)
                        this.materialDeletedReportList = [];
                    else
                        this.materialReportList = [];
                    for (var i = 0; i < this.Materials.length; i++) {
                        var materialReport = new MaterialReport();
                        materialReport.id = this.Materials[i].id;
                        materialReport.itemNumber = this.Materials[i].itemNumber;
                        materialReport.text = this.Materials[i].text;
                        materialReport.retailPrice = this.Materials[i].retailPrice ? this.Materials[i].retailPrice : 0;
                        materialReport.personalPrice = this.Materials[i].personalPrice ? this.Materials[i].personalPrice : 0;
                        materialReport.purchasePrice = this.Materials[i].purchasePrice ? this.Materials[i].purchasePrice : 0;
                        materialReport.holdings = this.Materials[i].holdings ? this.Materials[i].holdings : 0;
                        materialReport.minInventory = this.Materials[i].minInventory ? this.Materials[i].minInventory : 0;
                        materialReport.maxInventory = this.Materials[i].maxInventory ? this.Materials[i].maxInventory : 0;
                        materialReport.total = (materialReport.purchasePrice * materialReport.holdings);
                        materialReport.nonStockList = this.Materials[i].nonStockList ? this.Materials[i].nonStockList : '-';
                        this.materialSum = this.materialSum + materialReport.total;
                        if (materialReport.minInventory == 0 && materialReport.maxInventory == 0) {
                            materialReport.displayUP = false;
                            materialReport.displayDown = false;
                        }
                        else if (materialReport.holdings <= materialReport.minInventory) {
                            materialReport.displayUP = false;
                            materialReport.displayDown = true;
                        }
                        else if (materialReport.holdings >= materialReport.maxInventory) {
                            materialReport.displayDown = false;
                            materialReport.displayUP = true;
                        }
                        materialReport.unit = this.Materials[i].unit;
                        materialReport.isActive = this.Materials[i].isActive;
                        materialReport.isDelete = this.Materials[i].isDeleted;
                        materialReport.shelfNo = this.Materials[i].shelfNo ? this.Materials[i].shelfNo : '';

                        if (requestModel.IsDeleted)
                            this.materialDeletedReportList.push(materialReport);
                        else
                            this.materialReportList.push(materialReport);
                    }
                    this.AllMaterials = this.materialReportList;
                    if (requestModel.IsDeleted) {
                        if (this.orderByField == "total")
                            this.materialDeletedReportList = this.sortPipe.transform(this.materialDeletedReportList, this.orderByDirection, "total");
                        this.dataSource = new TableVirtualScrollDataSource(this.materialDeletedReportList);
                    }
                    else {
                        // Assign the data to the data source for the table to render
                        if (this.orderByField == "total")
                            this.materialReportList = this.sortPipe.transform(this.materialReportList, this.orderByDirection, "total");
                        this.dataSource = new TableVirtualScrollDataSource(this.materialReportList);
                    }
                    this.dataSource.paginator = this.paginator;
                    this.dataSource.sort = this.sort;
                    this.pagination = response.pagination;
                    this.displayPagination = true;

                    if (isLoadActive)
                        this.onSetActive();
                }
                else {
                    //this.Materials = [];
                    this.displayPagination = false;
                }
                this.IsStartLoading(false)
            },
                error => (err) => {
                    //this.Materials = [];
                    this.displayPagination = false;
                    this.IsStartLoading(false)
                },
                () => {
                });
    }

    searchManually(flag) {
        this.paginator.pageIndex = 0;
        this.materialSum = 0;
        var findMaterial = [];
        if (flag == 1 && !this.isDeleted) {
            this.materialReportList.filter(x => {
                if (x.holdings <= x.minInventory && (x.minInventory > 0 || x.maxInventory > 0)) {
                    this.materialSum = this.materialSum + x.total;
                    findMaterial.push(x);
                }
            });
        }
        else if (flag == 1 && this.isDeleted) {
            this.materialDeletedReportList.filter(x => {
                if (x.holdings <= x.minInventory && (x.minInventory > 0 || x.maxInventory > 0)) {
                    this.materialSum = this.materialSum + x.total;
                    findMaterial.push(x);
                }
            });
        }
        else if (flag == 2 && !this.isDeleted) {
            this.materialReportList.filter(x => {
                if (x.holdings >= x.maxInventory && (x.minInventory > 0 || x.maxInventory > 0)) {
                    this.materialSum = this.materialSum + x.total;
                    findMaterial.push(x);
                }
            });
        }
        else if (flag == 2 && this.isDeleted) {
            this.materialDeletedReportList.filter(x => {
                if (x.holdings >= x.maxInventory && (x.minInventory > 0 || x.maxInventory > 0)) {
                    this.materialSum = this.materialSum + x.total;
                    findMaterial.push(x);
                }
            });
        }
        else if (flag == 0 && !this.isDeleted) {
            //findMaterial = this.materialReportList;
            this.materialReportList.filter(x => {
                this.materialSum = this.materialSum + x.total;
                findMaterial.push(x);
            });
        }
        else if (flag == 0 && this.isDeleted) {
            this.materialDeletedReportList.filter(x => {
                this.materialSum = this.materialSum + x.total;
                findMaterial.push(x);
            });
        }
        var filter = this.dataSource.filter;
        this.applyFilter(filter);
        this.dataSource = new TableVirtualScrollDataSource(findMaterial);
        this.dataSource.paginator = this.paginator;
        // if (this.dataSource.filteredData.length > 0) {
        //   this.dataSource.filteredData.forEach(item => {
        //     this.materialSum = this.materialSum + item.total;
        //   });
        // }
    }

    onSetActive() {
        // var filter = this.dataSource.filter;
        // this.paginator.pageIndex = 0;
        // this.materialSum = 0;
        // this.isDeleted = false;
        // var findMaterial = [];
        // if (!this.isActive) {
        //   this.materialReportList.filter(x => {
        //     if (x.isActive || x.isActive == null) {
        //       this.materialSum = this.materialSum + x.total;
        //       findMaterial.push(x);
        //     }
        //   });
        // }
        // else {
        //   findMaterial = this.materialReportList;

        // }
        // var filter = this.dataSource.filter;
        // this.dataSource = new MatTableDataSource(findMaterial);
        // this.materialStatus = 0;
        // if (this.dataSource.filteredData.length > 0) {
        //   this.dataSource.filteredData.forEach(item => {
        //     this.materialSum = this.materialSum + item.total;
        //   });
        // }
        // this.applyFilter(filter);
        // this.dataSource.paginator = this.paginator;
        // this.requestMaterial.IsActive = false;
        // this.requestMaterial.IsDeleted = false;
        // this.requestMaterial.Limit = 10000;
        // this.materialReportList = [];
        // this.GetMaterial(this.requestMaterial);
        this.materialStatus = 0;
        this.paginator.pageIndex = 0;
        this.materialSum = 0;
        this.isDeleted = false;
        if (this.isActive) {
            this.dataSource = new TableVirtualScrollDataSource(this.materialReportList);
            this.dataSource.filteredData.forEach(item => {
                this.materialSum = this.materialSum + item.total;
            });
            this.dataSource.paginator = this.paginator;
        }
        else {
            var activematerialOnly = this.materialReportList.filter(o => {
                if (o.isActive == true || o.isActive == null) {
                    this.materialSum = this.materialSum + o.total;
                    return o;
                }
            });
            var filter = this.dataSource.filter;
            this.applyFilter(filter);
            this.dataSource = new TableVirtualScrollDataSource(activematerialOnly);
            this.dataSource.paginator = this.paginator;
        }
    }

    onSetDeleted() {
        // var filter = this.dataSource.filter;
        // this.materialSum = 0;
        // if (this.isDeleted) {
        //   this.dataSource = new MatTableDataSource(this.materialDeletedReportList);
        //   this.applyFilter(filter);

        //   if (this.dataSource.filteredData.length > 0) {
        //     this.dataSource.filteredData.forEach(item => {
        //       this.materialSum = this.materialSum + item.total;
        //     });
        //   }
        //   this.paginator.pageIndex = 0;
        //   this.dataSource.paginator = this.paginator;
        //   this.materialStatus = 0;

        // this.isActive = false;
        // }
        // else {
        //   this.dataSource = new MatTableDataSource(this.materialReportList);
        //   if (this.dataSource.filteredData.length > 0) {
        //     this.dataSource.filteredData.forEach(item => {
        //       this.materialSum = this.materialSum + item.total;
        //     });
        //   }
        //   this.applyFilter(filter);
        //   this.paginator.pageIndex = 0;
        //   this.dataSource.paginator = this.paginator;
        //   this.isActive = true;
        // }
        this.paginator.pageIndex = 0;
        this.materialStatus = 0;
        this.isActive = false;
        this.materialSum = 0;
        if (this.isDeleted && (!this.materialDeletedReportList || this.materialDeletedReportList.length == 0)) {
            this.requestMaterial.IsDeleted = true;
            this.requestMaterial.Limit = 10000;
            this.GetMaterial(this.requestMaterial);
        } else if (this.isDeleted && this.materialDeletedReportList.length > 0) {
            var filter = this.dataSource.filter;
            this.dataSource = new TableVirtualScrollDataSource(this.materialDeletedReportList);
            this.applyFilter(filter);
            if (this.dataSource.filteredData.length > 0) {
                this.dataSource.filteredData.forEach(item => {
                    this.materialSum = this.materialSum + item.total;
                });
            }
            // this.paginator.pageIndex = 0;
            this.applyFilter(filter);
            this.dataSource.paginator = this.paginator;
            this.materialStatus = 0;
        } else if (!this.isDeleted) {
            this.isActive = true;
            this.onSetActive();
        }
    }

    getCoWorkers(model) {
        model.Limit = 400;
        model.OrderBy = "Name";
        this.companyInfoService.GetCoworker<ResponseBase>(model)
            .subscribe((response: ResponseBase) => {
                if (response) {
                    //this.coWokers = response.result;
                    this.getNextRequestForCoWorker(response.pagination, model, response.result);
                }
                else {
                    this.coWokers = [];
                    //this.SecurityOfficers = [];
                }
            },
                error => (err) => {
                    this.coWokers = [];
                    //this.SecurityOfficers = [];
                },
                () => {
                });
    }

    getNextRequestForCoWorker(pagination, model, response) {
        if (pagination.index == 1) {
            this.coWokers = response;
        }
        else {
            this.coWokers.push(...response);
        }
        model.Index = pagination.index + 1;
        model.Limit = pagination.limit;
        model.TotalRows = pagination.totalCount;
        if (pagination.totalCount > pagination.index * pagination.limit)
            this.getCoWorkers(model);
    }

    sendEmailPopup(element) {
        var emailSendingParam = new EmailSendingParam();
        emailSendingParam.fromName = this.authService.getUserName();
        emailSendingParam.from = this.authService.getUserEmail();
        emailSendingParam.displayAttachement = true;
        emailSendingParam.to = " ";
        emailSendingParam.toName = " ";
        emailSendingParam.subject = "Materialer rapport fra Vibe malerfirma.";
        emailSendingParam.coworkerList = this.coWokers;
        emailSendingParam.isLoadDefaultEmailTemplate = true;
        const dialogRef = this.dialog.open(TextEditorEmailSendComponent, {
            height: '90%',
            width: '90%',
            data: emailSendingParam
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result != null && result != '') {
                var that = this;
                var emailSending = new EmailSendingParam();
                emailSending = result;
                this.IsStartLoading(true);
                if (emailSending.sendAttachement) {
                    this.isShowPdf = true;
                    // setTimeout(function () {
                    //   const options = <DrawOptions>{ paperSize: "A4", landscape: false, scale: 0.7, margin: 5 };
                    //   drawDOM(element, options).then((group: Group) => {
                    //     return exportPDF(group);
                    //   }).then((dataUri) => {
                    //     var datetime = moment(new Date()).format("YYYYMMDDhhmmss");
                    //     emailSending.attachmentBase64 = dataUri.replace('data:application/pdf;base64,', '');
                    //     emailSending.attachmentFileName = 'Materialer_Rapport_' + datetime + '.pdf';
                    //     that.sendEmail(emailSending);
                    //     that.isShowPdf = false;
                    //   });
                    // }, 100);

                    setTimeout(() => {
                        const requestModel = { invoiceHtml: element.innerHTML }
                        this.isShowPdf = false;
                        this.projectofferService.HtmlToPDFConvert(requestModel).subscribe({
                            next: (response) => {
                                if (response != null && response.result != null) {
                                    var datetime = moment(new Date()).format("YYYYMMDDhhmmss");
                                    emailSending.attachmentBase64 = response.result.base64.replace('data:application/pdf;base64,', '');
                                    emailSending.attachmentFileName = 'Materialer_Rapport_' + datetime + '.pdf';
                                    that.sendEmail(emailSending);
                                }
                                this.IsStartLoading(false)
                            },
                            error: err => {
                                this.IsStartLoading(false)
                            },
                            complete: () => {
                                this.IsStartLoading(false)
                                this.isShowPdf = false;
                            }
                        });
                    }, 100);
                }
                else {
                    that.sendEmail(emailSending);
                }
            }
        });
    }

    sendEmail(emailSending) {
        this.IsStartLoading(true)
        this.companyInfoService.SendEmail<ResponseBase>(emailSending)
            .subscribe({
                next: (response: ResponseBase) => {
                    this.IsStartLoading(false)
                    this.translate.get("MESSAGES.SENDEMAILSUCCESS").subscribe({
                        next: (res) => {
                            this.notificationsService.create(res);
                        },
                        error: err => {
                        }
                    });
                    this.commonService.checkEmailIsToBeSent();
                },
                error: err => {
                    this.IsStartLoading(false)
                },
                complete: () => {
                    this.IsStartLoading(false)
                }
            });
    }

    // downloadPDF1(element) {
    //   var datetime = moment.utc(new Date()).format("YYYYMMDDhhmmss");
    //   this.IsStartLoading(true)
    //   this.isShowPdf = true;
    //   var that = this;
    //   setTimeout(function () {
    //     const options = <DrawOptions>{ paperSize: "A4", landscape: false, scale: 0.6, margin: 5 };
    //     drawDOM(element, options).then((group: Group) => {
    //       return exportPDF(group);
    //     }).then((dataUri) => {
    //       var data = dataUri.replace("data:application/pdf;base64,", "");
    //       // decode base64 string, remove space for IE compatibility
    //       var binary = atob(data.replace(/\s/g, ''));
    //       var len = binary.length;
    //       var buffer = new ArrayBuffer(len);
    //       var view = new Uint8Array(buffer);
    //       for (var i = 0; i < len; i++) {
    //         view[i] = binary.charCodeAt(i);
    //       }
    //       // create the blob object with content-type "application/pdf"               
    //       var blob = new Blob([view], { type: "application/pdf" });
    //       var url = URL.createObjectURL(blob);
    //       var link = document.createElement('a');
    //       link.innerHTML = 'Download PDF file';
    //       link.href = url;
    //       link.target = "_blank";
    //       link.click();
    //       that.isShowPdf = false;
    //       this.IsStartLoading(false)
    //     });
    //   }, 100)
    // }

    downloadPDF(element) {
        this.IsStartLoading(true);
        this.isShowPdf = true;
        setTimeout(() => {
            const requestModel = { invoiceHtml: element.innerHTML }
            this.isShowPdf = false;
            this.projectofferService.HtmlToPDFConvert(requestModel).subscribe({
                next: (response) => {
                    if (response != null && response.result != null) {
                        var data = response.result.base64.replace("data:application/pdf;base64,", "");
                        const dialogRef = this.dialog.open(PdfViewerModelComponent, {
                            height: '90%',
                            width: '90%',
                            data: {
                                base64: data,
                            }
                        });
                        dialogRef.afterClosed().subscribe(result => { });
                    }
                    this.IsStartLoading(false)
                },
                error: err => {
                    this.IsStartLoading(false)
                },
                complete: () => {
                    this.IsStartLoading(false)
                    this.isShowPdf = false;
                }
            });
        }, 100);
    }

    GetCompanyInformation() {
        this.showSpinner = true;
        this.companyInfoService.GetCompanyInformationData<ResponseBase>()
            .subscribe((response: ResponseBase) => {
                if (response) {
                    this.companyInfoData = response.result;
                    this.companyInfoData.imagePath = this.companyInfoData.logo;
                    this.downloadExcelFile();
                }
                else {
                }
                this.showSpinner = false;
            },
                (err: any) => {
                    this.showSpinner = false;
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        }, error: err => { }
                    });
                },
                () => {
                });
    }

    downloadExcelFile() {
        // Initialize ExcelJS
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Material Report');

        // Add the image to the workbook using the Base64 string
        const imageId = workbook.addImage({
            base64: this.companyInfoData.logoBase64, // Remove 'data:image/png;base64,' part
            extension: "png",
        });

        // Add the image to the worksheet (e.g., A1 to B3)
        worksheet.addImage(imageId, 'K1:L6');

        // Merge cells A4 and B4 for the company name
        worksheet.mergeCells('A1:G2');
        const companyCell = worksheet.getCell('A1');
        companyCell.value = 'Lagerliste';
        companyCell.alignment = { horizontal: 'left', vertical: 'top' }; // Center align the text

        // Apply font styling
        companyCell.font = {
            name: 'Arial', // Font type
            size: 16,      // Font size
            bold: true,    // Make the text bold
            underline: true, // Underline the text
            color: { argb: 'FF0000' }
        };


        // Merge cells A4 and B4 for the company name
        worksheet.mergeCells('H1:J2');
        worksheet.getCell('H1').value = this.companyInfoData.name;
        worksheet.getCell('H1').alignment = { horizontal: 'left', vertical: 'middle' }; // Center align the text

        // Merge cells A4 and B4 for the company name
        worksheet.mergeCells('H3:J3');
        worksheet.getCell('H3').value = this.companyInfoData.address;

        worksheet.mergeCells('H4:J4');
        worksheet.getCell('H4').value = this.companyInfoData.zipcode + " " + this.companyInfoData.city;

        worksheet.mergeCells('H6:J6');
        worksheet.getCell('H6').value = moment(new Date()).format("DD-MM-YYYY HH:mm");

        // Add headers under the image
        let headers = [];
        if (this.isShowPersonalPriceorMinMaxNumber) {
            headers = ['Vare Nr.', 'Navn', 'Udsalgs Pris', 'Indkøbs Pris', 'Beholding', 'Personale Pris', 'Min Antal', 'Max Antal', 'Enhed', 'Hylde nr', 'I Alt', 'IkkeLagerliste'];
        }
        else {
            headers = ['Vare Nr.', 'Navn', 'Udsalgs Pris', 'Indkøbs Pris', 'Beholding', 'Enhed', 'Hylde nr', 'I Alt', 'IkkeLagerliste'];
        }

        for (let col = 0; col < headers.length; col++) {
            const cell = worksheet.getCell(`${String.fromCharCode(65 + col)}8`); // Columns A-L
            cell.value = headers[col];
            cell.font = { bold: true }; // Apply bold font to the header row
        }

        // Add the data rows from listObj
        let rowIndex = 9; // Start after the header (row 5)
        for (var i = 0; i < this.dataSource.filteredData.length; i++) {
            if (this.dataSource.filteredData[i].holdings > 0) {
                worksheet.getCell(`A${rowIndex}`).value = this.dataSource.filteredData[i].itemNumber || '-';
                worksheet.getCell(`B${rowIndex}`).value = this.dataSource.filteredData[i].text || '-';
                worksheet.getCell(`C${rowIndex}`).value = this.dataSource.filteredData[i].retailPrice ? this.denmarkDigitFormate.transform(this.dataSource.filteredData[i].retailPrice.toFixed(2)).toString() : '0';
                worksheet.getCell(`D${rowIndex}`).value = this.dataSource.filteredData[i].purchasePrice ? this.denmarkDigitFormate.transform(this.dataSource.filteredData[i].purchasePrice.toFixed(2)).toString() : '0';
                worksheet.getCell(`E${rowIndex}`).value = this.dataSource.filteredData[i].holdings ? this.denmarkDigitFormate.transform(this.dataSource.filteredData[i].holdings.toFixed(2)).toString() : '0';

                if (this.isShowPersonalPriceorMinMaxNumber) {
                    worksheet.getCell(`F${rowIndex}`).value = this.dataSource.filteredData[i].personalPrice ? this.denmarkDigitFormate.transform(this.dataSource.filteredData[i].personalPrice.toFixed(2)).toString() : '0';
                    worksheet.getCell(`G${rowIndex}`).value = this.dataSource.filteredData[i].minInventory ? this.denmarkDigitFormate.transform(this.dataSource.filteredData[i].minInventory.toFixed(2)).toString() : '0';
                    worksheet.getCell(`H${rowIndex}`).value = this.dataSource.filteredData[i].maxInventory ? this.denmarkDigitFormate.transform(this.dataSource.filteredData[i].maxInventory.toFixed(2)).toString() : '0';
                    worksheet.getCell(`I${rowIndex}`).value = this.dataSource.filteredData[i].unit || '-';
                    worksheet.getCell(`J${rowIndex}`).value = this.dataSource.filteredData[i].shelfNo || '';
                    worksheet.getCell(`K${rowIndex}`).value = this.dataSource.filteredData[i].total ? this.denmarkDigitFormate.transform(this.dataSource.filteredData[i].total.toFixed(2)).toString() : '0';
                    worksheet.getCell(`L${rowIndex}`).value = this.dataSource.filteredData[i].nonStockList || '-';
                }
                else {
                    worksheet.getCell(`F${rowIndex}`).value = this.dataSource.filteredData[i].unit || '-';
                    worksheet.getCell(`G${rowIndex}`).value = this.dataSource.filteredData[i].shelfNo || '';
                    worksheet.getCell(`H${rowIndex}`).value = this.dataSource.filteredData[i].total ? this.denmarkDigitFormate.transform(this.dataSource.filteredData[i].total.toFixed(2)).toString() : '0';
                    worksheet.getCell(`I${rowIndex}`).value = this.dataSource.filteredData[i].nonStockList || '-';
                }

                rowIndex++; // Move to the next row
            }
        }

        // Save the Excel file
        workbook.xlsx.writeBuffer().then(buffer => {
            var download = document.createElement("a");
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            download.href = window.URL.createObjectURL(blob);
            download.download = 'Materialler_rapport.xlsx';

            var event = document.createEvent("MouseEvents");
            event.initMouseEvent(
                "click", true, false, window, 0, 0, 0, 0, 0
                , false, false, false, false, 0, null
            );
            download.dispatchEvent(event);
        });
        this.showSpinner = false;
    }

    IsStartLoading(val) {
        this.showSpinner = val;
        this.IsTabLoading.emit(val);
    }
    goToMaterial(id) {
        this._router.navigate(["/materials/edit", id], { queryParams: { previousUrl: 'report' } });
    }

    offerPricePDF() {
        this.showSpinner = true;
        var tempOfferType = this.currentSelectedOfferTypeObj.filter(val => val !== 0);
        var offerType = tempOfferType.join().toString();//(this.type != null && this.type != 0) ? this.type.toString():"";
        this.materialService.GetOfferMaterialPriceList(offerType).subscribe((response: ResponseBase) => {
            var data = response.result.base64.replace("data:application/pdf;base64,", "");
            const dialogRef = this.dialog.open(PdfViewerModelComponent, {
                height: '90%',
                width: '90%',
                data: {
                    base64: data,
                    filename: "Tilbudspris" + new Date() + ".pdf"
                }
            });

            dialogRef.afterClosed().subscribe(result => {
                if (result) { }
            });

        },
            error => (err) => {
                this.showSpinner = false;
            },
            () => {
                this.showSpinner = false;
            });
    }

    toggleAllofferTypeSelection() {
        this.currentSelectedOfferTypeObj = [];
        if (this.allOfferTypeSelected.selected) {
            for (var i = 0; i < 28; i++) {
                this.currentSelectedOfferTypeObj.push(i);
            }
        }
    }

    onSetShowOffer(isShowOffer) {
        if (isShowOffer)
            this.requestMaterial.IsShowOfferMaterial = true;
        else
            this.requestMaterial.IsShowOfferMaterial = false;
        this.GetMaterial(this.requestMaterial);
    }

    createPDForExcelorEmail(type, element) {
        const dilogRef = this.dialog.open(AlertMessageModelComponent, {
            data: { Header: 'Headers.ViewPersonalPrice-Min-Max-Quantity-Message' }
        })
        dilogRef.afterClosed().subscribe(result => {
            if (result)
                this.isShowPersonalPriceorMinMaxNumber = true;
            else
                this.isShowPersonalPriceorMinMaxNumber = false;

            this.showSpinner = true;
            if (type == "Excel")
                this.GetCompanyInformation();
            if (type == "PDF")
                this.downloadPDF(element);
            if (type == "Email")
                this.sendEmailPopup(element);
        });
    }
}
