export const locale = {
    lang: 'en',
    data: {
        'TITLE': {
        },
        'FIELDS': {
            'Rate': 'Amount',
            'CoworkerAwaitedStatus': 'Project status is pending, and if the amount is large, request an on Aconto invoice.',
        },
        'Message': {

        },
        'FILTERS': {
        },
        'Buttons': {
            'Save': 'Save',
        }
    }
};