import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from "@angular/core";
import { Router } from '@angular/router';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import * as moment from 'moment';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';

@Component({
    selector: 'vibe-project-card',
    templateUrl: './project-card.component.html',
    styleUrls: ['./project-card.component.scss']
})

export class ProjectCardComponent implements OnInit {
    @ViewChild("projectSearch", { static: false }) projectSearch: ElementRef;
    @Input() projects = [];
    @Input() isCommingFrom: string = "";
    @Input() isProjectActiveInactive: boolean = true;
    @Input() pagination: any;
    @Input() requestProjectMonth: number;
    //@Output() onClickCard = new EventEmitter<{ id: string, sagNumber: string }>();
    @Output() onClickCard = new EventEmitter<any>();
    @Output() showInActiveProjects = new EventEmitter<boolean>();
    @Output("goToRequisitionTab") goToRequisitionTab: EventEmitter<any> = new EventEmitter();
    @Output("goToRequisitionMaterialTab") goToRequisitionMaterialTab: EventEmitter<any> = new EventEmitter();
    @Output("gotoMessageLogTab") gotoMessageLogTab: EventEmitter<any> = new EventEmitter();
    @Output("goToRequisitionTabMode") goToRequisitionTabMode: EventEmitter<any> = new EventEmitter();
    isSingleClick: Boolean = true;
    SearchDate: any;
    searchBy: number = 0;
    orderBy: string = "Number DESC"
    tempProjectList = [];
    searchProject: any;
    currentDate: Date = new Date();
    sixMonthsAgo: Date = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 6, this.currentDate.getDate());
    checkDate: string ="";
    constructor(private translationLoader: FuseTranslationLoaderService,
        private _router: Router, ) {
        this.translationLoader.loadTranslations(english, danish);
    }

    ngOnInit() {
        if (this.projects && this.projects.length > 0) {
            this.projects = this.projects
        }
        if (this.requestProjectMonth) {
            var futureDate = new Date(this.currentDate.setMonth(this.currentDate.getMonth() + this.requestProjectMonth))
            this.checkDate = moment(futureDate).format('YYYY-MM-DD');
        }
        else {
            this.checkDate = moment(this.sixMonthsAgo).format('YYYY-MM-DD');
        }
    }
    truncDecimalpoint(value) {
        return Math.trunc(value)
    }

    checkAccess(project) {
        this.onClickCard.emit(project);
        // this.isSingleClick = true;
        // setTimeout(() => {
        //     if (this.isSingleClick) {
        //         this.onClickCard.emit(project);
        //     }
        // }, 250);
    }

    onCheckSelect(flag) {
        this.isProjectActiveInactive = flag
        this.showInActiveProjects.emit(flag);
    }

    // doubleClickFunction(project) {
    //     this.isSingleClick = false;
    //     if (!this.isSingleClick) {
    //         this.goToRequisitionTab.emit(project);
    //     }
    // }
    gotoMaterial(project) {
        this.goToRequisitionMaterialTab.emit(project);
    }

    gotoRequisition(project) {
        this.goToRequisitionTab.emit(project);
    }
    // EditCustomer(id) {
    //     this._router.navigate(['/projects/edit', id], { queryParams: { isActive: this.isActive } }); //navigationExtras
    // }
    search(value) {
        if (this.tempProjectList.length == 0) {
            this.tempProjectList = this.projects;
        }
        if (value) {
            this.projects = this.tempProjectList.filter(x => (x.materialPriceTotalWithVat == value || x.personalPriceTotal == value));
        }
        else {
            this.projects = this.tempProjectList;
        }
    }

    getProjectOnDate() {
        if (this.tempProjectList.length == 0) {
            this.tempProjectList = this.projects;
        }
        if (this.SearchDate) {
            this.projects = this.tempProjectList.filter(x => x.received && moment(x.received).format("YYYY-MM-DD") == moment(this.SearchDate).format("YYYY-MM-DD"));
        } else {
            this.projects = this.tempProjectList;
        }
    }

    receiveFilterEvent(event) {
        this.orderBy = event;
        if (this.orderBy == "Number") {
            this.projects.sort((a, b) => a.sagNumber - b.sagNumber);
        }
        else if (this.orderBy == "Number DESC") {
            this.projects.sort((a, b) => b.sagNumber - a.sagNumber);
        }
    }

    searchProjectDetail(value) {
        value.trim();
        if (this.tempProjectList.length == 0) {
            this.tempProjectList = this.projects;
        }
        if (value) {
            this.projects = this.tempProjectList.filter(x => x.sagNumber == parseInt(value) || x.address1 && x.address1.toLowerCase().includes(value.toLowerCase()) || x.comment && x.comment.toLowerCase().includes(value.toLowerCase()) || x.invoiceText && x.invoiceText.toLowerCase().includes(value.toLowerCase()));
        }
        else{
            this.projects = this.tempProjectList
        }
        // if (value && this.projects.length == 0) {
        //     this.projects = this.tempProjectList.filter(it => { return (it.address1 && it.address1.toLowerCase().includes(value.toLowerCase()) || it.comment && it.comment.toLowerCase().includes(value.toLowerCase()) || it.invoiceText && it.invoiceText.toLowerCase().includes(value.toLowerCase()) ) });
        // }
        // if (!value || this.projects.length == 0) {
        //     this.projects = this.tempProjectList;
        // }
    }

    onFocusIn(event) {
        event.model.show();
    }

    gotoMessageLog(project){
        this.gotoMessageLogTab.emit(project);
    }

    gotoRequisitionTabMode(project, tabChangeName) {
        var tempObject = {
            project:project,
            tabChangeName: tabChangeName,
        }
        this.goToRequisitionTabMode.emit(tempObject);
    }
}