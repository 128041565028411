import { Component, OnInit, ViewChild } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { RequestCommonBase } from 'app/main/model/RequestBase';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { MatDialog, MatOption } from '@angular/material';
import { AuthService } from 'app/core/services/auth.service';
import { SettingsService } from "app/main/pages/settings/settings.service";
import { ViewAnswerCoworkersComponent } from "../view-answer-coworkers/view-answer-coworkers.component";
import { environment } from "environments/environment";

@Component({
    selector: "coworker-review-rating-chart",
    templateUrl: "./coworker-review-rating-chart.component.html",
    styleUrls: ["./coworker-review-rating-chart.component.scss"]
})

export class CoworkerReviewRatingChartComponent implements OnInit {
    showSpinner: boolean = false;
    requestModel: RequestCommonBase = new RequestCommonBase();
    coworkerReviewAnswerForPieChart = [];
    //roleName: string;
    quizId: string;
    searchQuiz: any = "";
    currentId: any;
    previousId: any;
    nextId: any;
    tempQuestionId: any;
    questionList: any = [];
    questionObject: any = {};
    questionText: any = "";
    questionImagePaths: any = [];
    questionJson: any = [];
    apiHostUrl: string;
    pieChartData : any =[];
    colorScheme = {
        domain: ["#5AA454", "#ce7d78", "#ea9e70", "#a48a9e", "#c6e1e8", "#648177", "#0d5ac1",
            "#f205e6", "#1c0365", "#14a9ad", "#4ca2f9", "#a4e43f", "#d298e2", "#6119d0",]
    };
    view: any[] = [700, 300];
    gradient: boolean = true;
    showLegend: boolean = true;
    showLabels: boolean = true;
    isDoughnut: boolean = false;
    legendPosition: string = '';
    legendTitle: string = '';
    isHideAnswer: boolean = false;
    answerView: boolean = false;
    roleClaims: any;
    
    @ViewChild('allSelected', { static: false }) private allSelected: MatOption;
    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private settingsService: SettingsService,
        private authService: AuthService,
        private dialog: MatDialog) {
        this.translationLoaderService.loadTranslations(danish, english);
        this.roleClaims = this.authService.getRoleClaimsList();
    }   

    ngOnInit() {
        this.apiHostUrl = environment.url;
        this.authService.hasClaim(this.roleClaims.Coworker_Answer_View).subscribe(hasClaim => {
            if (hasClaim)
                this.answerView = hasClaim;
            else 
                this.answerView = false;
        });

        this.GetCoworkerReviewAnswerForPieChart();
    }

    GetCoworkerReviewAnswerForPieChart() {
        this.showSpinner = true;
        this.settingsService.GetCoworkerReviewAnswerForPieChart<ResponseBase>().subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.result) {
                    this.coworkerReviewAnswerForPieChart = response.result;
                }
                else {
                    this.coworkerReviewAnswerForPieChart = [];
                }
            },
            error: err => {
                this.showSpinner = false;
                this.coworkerReviewAnswerForPieChart = [];
            },
            complete: () => { }
        });
    }

    DropDownSearchCoWorker() {
        var quiz = this.coworkerReviewAnswerForPieChart.filter(x => x.id == this.quizId);
        if (quiz && quiz.length > 0){
            this.questionObject = quiz[0];
            this.questionJson = JSON.parse(this.questionObject.json);
            this.questionList = this.questionObject.questionsList;
            if(this.questionList && this.questionList.length >0){
                this.tempQuestionId = this.questionList[0].id;
                this.getSetNextPreviousId();
            }
        }
        else{
            this.questionObject = {};
            this.questionText = ""
            this.questionImagePaths = [];
            this.currentId = "";
            this.questionList = [];
        }

    }

    gotoQuestion(id) {
        if (this.questionList) {
            this.tempQuestionId = id;
            this.currentId = this.tempQuestionId;
            this.getSetNextPreviousId();
        }
    }

    getSetNextPreviousId() {
        this.currentId = this.tempQuestionId;
        this.questionList.filter(q => {
            if (q.id == this.currentId) {
                this.questionText = q.question;
                this.isHideAnswer = q.isHideAnswer;
                var pathObj = this.questionJson.find(o => o.id == this.currentId);
                if (pathObj) {
                    this.questionImagePaths = pathObj.filesPath;
                }
                if(q.questionType=="SingleChoice" || q.questionType=="MultiChoice"){
                    this.pieChartData = q.answerList;
                    this.pieChartData.filter(p =>{
                        p.value = p.countPercentage;
                    })
                }
                else
                    this.pieChartData = [];
            }
        });
        var itemIndex = this.questionList.map(function (o) { return o.id; }).indexOf(this.currentId);
        if (itemIndex > 0)
            this.previousId = this.questionList[itemIndex - 1].id;
        else
            this.previousId = null;

        if (itemIndex != (this.questionList.length - 1))
            this.nextId = this.questionList[itemIndex + 1].id;
        else
            this.nextId = null;
    }

    onSelect(data): void {
        if (this.answerView == true) 
            this.viewAnswerCowrokers();
    }

    viewAnswerCowrokers() {
        const dilogRef = this.dialog.open(ViewAnswerCoworkersComponent, {
            // height: '60%',
            // width: '60%',
            data: { Header: 'Headers.DeleteMessage', pieChartData : this.pieChartData, questionText: this.questionText}
        })
        dilogRef.afterClosed().subscribe(result => {
        });
    }

}