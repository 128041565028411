export const locale = {
    lang: 'en',
    data: {
        'TITLE': {
            'AssetPagination': 'Units per page',
        },
        'FIELDS': {
            'Designation': 'Designation',
            'ValideFrom': 'Valide From',
            'SRNO': 'Sr no.',
            'Labels': 'Category',
            'ShowOnlyHomePosition': 'Show only Home position',
            'ShowLastServiceDue': 'Show only(>365 days)',
        },
        'BUTTONS': {
            'Save': 'Save',
            'BACK': 'Back',
            'Reset': 'Reset',
            'AddAssets':'Add Assets'
        },
        'MESSAGES': {
            'Updated': 'Updated Successfully.',
            'Failed': 'Failed',
            'Deleted': 'Deleted successfully',
            "NORECORD": "No Record Found",
            'Created': 'Created successfully',
        },
        'Validation': {
            'DesignationRequired': 'Designation is required',
            'ValideFromRequired': 'Valide from date is required',
            'HourlyRateRequired': 'Hourly rate is required',
            'WeekdayRequired': 'Weekday is required',
            'TypeRequired': 'Type is required',
        },
        'Headers': {
            'Title': 'Cars',
        },
        'Lable': {
            'SRNO': 'Sr nr.',
            'Image': 'Image',
            'Brand': 'Brand',
            'Model': 'Model',
            'Created': 'Created',
            'SerialNumber': 'Serial number',
            'Keywords': 'Keywords',
            'Description': 'Description',
            'Group': 'Group',
            'Labels': 'Labels',
            'VID':'vID'
        },
        'ratesType': {
            'valueA': 'A Hourly rate',
            'valueB': 'B Hourly Wages Overtime 1',
            'valueC': 'C Hourly Wages Overtime 2',
            'valueJ': 'J Sick pay per. hour',

            'valueK': 'Vacation',
            'valueL': 'Holiday of the work',
            'valueM': 'Holiday with payment',
            'valueN': 'Holiday without payment',
            'valueO': 'School or learning',
            'valueP': 'Other things',
            'valueQ': 'Offer',
        },
        'FILTERS': {
            'All': 'All',
            'SEARCH': 'Search',
            'SORTING': 'Sort By',
        },
        'selectedValueWeekDays': {
            'value1': 'Monday',
            'value2': 'Tuesday',
            'value4': 'Wednesday',
            'value8': 'Thursday',
            'value16': 'Friday',
            'value32': 'Saturday',
            'value64': 'Sunday'
          },
        'SORTBY':
            [
                {
                    Text: 'Brand ↑',
                    Value: 'Brand'
                },
                {
                    Text: 'Brand ↓',
                    Value: 'Brand DESC'
                },
                {
                    Text: 'Model ↑',
                    Value: 'Model'
                },
                {
                    Text: 'Model ↓',
                    Value: 'Model DESC'
                },
                {
                    Text: 'Serial number ↑',
                    Value: 'Nummer'
                },
                {
                    Text: 'Serial number ↓',
                    Value: 'Nummer DESC'
                },
                {
                    Text: 'Date ↑',
                    Value: 'Date'
                },
                {
                    Text: 'Date ↓',
                    Value: 'Date DESC'
                },
                {
                    Text: 'Group ↑',
                    Value: 'Group'
                },
                {
                    Text: 'Group ↓',
                    Value: 'Group DESC'
                }
            ],
    }
};