export const locale = {
    lang: 'ds',
    data: {
        'MESSAGES': {
            'Created': 'Oprettet med succes',
            'Updated': 'Opdateret med succes',
            'Deleted': 'Slettet med succes',
            'Failed': 'Operation mislykket, prøv senere',
            'NoBarcodeAttachedToMaterial': 'Ingen stregkode knyttet til noget materiale.',
            'EnterTextColorCode': 'Gå ind Tekst.',
            'MaterialPriseMoreOne': 'Gå ind Udsalgs pris',
            'NoMaterialFound': 'Intet materiale fundet',
            'AddColorCode': 'Farvekode mangler at blive udfyldt',
            'MaterialAlreadyExists': 'Materiale allerede tilføjet.',
        },
        'BUTTONS': {
            'NEWCOWORKER': 'Ny medarbejder',
            'BACK': 'Tilbage',
            'AddScanMaterial': 'Tilføj scanne materiale',
            'SAVE': 'Gem',
            'BtnAdd': 'Tilføj / Gem',
            'ViewColorCode': 'Se farvekode',
            'Calculation': 'Prisbogen',
            'CopyMaterial': 'Kopi Materiale',
        },
        'FIELDS': {
            'Status': 'Status',
            'MaterialTextColor': 'Tekst',
            'MaterialNo': 'Materiale nr.',
            'Material': 'Materiale',
            'MaterialText': 'Vare Tekst',
            'DateonCreate': 'Dato',
            'Quantity': 'Antal',
            'Unit': 'Enhed',
            'RetailPrice': 'Udsalgs pris pr. stk',
            'TotalPrice': 'Pris i alt',
            'PersonalPrice': 'Personale pris',
            'PersonalTotalPrice': 'Personal i alt',
            'TotalIncludedVAT': 'Tilbuds Pris incl. moms',
            'Estimate': 'Overslagspris',
            'Price-included': 'Pris incl. moms',
            'ProfitSubProfit': 'Overskud / UnderSkud',
            'ScanBarcode': 'Scan stregkode',
            'ColorCode': 'Farve kode',
            'PriceExclVAT': 'Pris excl. moms',
            'Discount': 'Rabat',
            'Total': 'I alt',
            'DonePercentage': 'Udført %',
            'DiscountLable': 'Rabat / Debitor Rabat',
            'Amount': 'Beløb',
            'Percentage': 'Procent',
            'PurchasePrice': 'Indkøbs pris',
            'RemainsTakenFromBasements': 'Rester fra kældre eller ingen lagertræk',
            'MaterialCreation': 'Materialer Udgør',
            'All': 'Alle',
            "Materialtype": 'Vare type',
            'Vat': 'Excl. Moms',
            'Not-Found-Material': 'Ingen materiale fundet',
            'Rows': 'Række',
            'Minute': 'Minutter',
            'AmountforWorkhours':'Udgift til arbejdsløn',
            'AmountWorshoursandMateriels':'Udgift arbejdsløn og materialer'
        },
        'selectedValueIndoorOutdoorStatus': {
            'value1': 'Indendørs ',
            'value2': 'Udendørs',
            'value3': 'Sprøjtemaling',
            'value4': 'Reklamation',
            'value5': 'Personale køb', //drawing of painter
            'value6': 'Butiks køb'     //Nordsjø logo
        },
        'Headers': {
            'AddNewMaterial': 'Tilføj nyt materiale',
            'Nomaterialadded': 'Ingen materialer',
            'DeleteMaterial': 'Vil du slette "@MaterialName"?',
            'MaterialList': 'Materialeliste',
        },
        'VALIDATIONS': {
            'TextRequired': 'Tekst krævet',
            'ColorCodeRequired': 'Farve kode krævet',
            'PersonalPriceRequired': 'Personlig pris påkrævet',
            'RetailPriceRequired': 'Detailpris påkrævet',
            'PurchaseQuantityRequired': 'Indkøbsmængde krævet',
            'QuantityRequired': 'Mængde krævet',
        }
    }
};

