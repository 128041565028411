export class Settings {
    public id: string;
    public key1: string;
    public key2: string;
    public key3: string;
    public value: any;
    public isDefaultMapper: boolean = false;
    constructor() { }
}


export class SettingValue {
    CoworkersID: string;
    HoursToSend: string;
    PercentageToSend: number;
    VoltageToSend: number;
    IsSendSMS: boolean;
    IsSendNotification: boolean;
    IsSendEmail: boolean;

    IsCreateReminder: boolean;

    SMSText: string;

    NotificationText: string;

    EmailText: string;
    ExecuteTime: string;
    DaysToSend: number;
    EmailTemplate: any;
    Body: string;
    TemplateFilePath: string;
    Subject: string;
    Key: string;
    OtherBody: string;
    OtherTemplateFilePath: string;
    OtherSubject: string;
    OtherKey: string;
    IsSendSMSToNewCoWorker: boolean;
    IsSendEmailToNewCoWorker: boolean;
    SendOnYear: number;

    IsEnable: boolean;

    OtherBeforeXDaysBody: string;
    OtherBeforeXDaysTemplateFilePath: string;
    OtherBeforeXDaysSubject: string;
    DrivingCurrency: number;
    GDayCurrency: number;
    ExtraText: string;
    NumberofDays: number;
}

export class SettingValueWithWeekDays extends SettingValue {
    isExecuteMonday: boolean;
    isExecuteMondayTime: string;
    isExecuteTuesday: boolean;
    isExecuteTuesdayTime: string;
    isExecuteWednesday: boolean;
    isExecuteWednesdayTime: string;
    isExecuteThursday: boolean;
    isExecuteThursdayTime: string;
    isExecuteFriday: boolean;
    isExecuteFridayTime: string;
    isExecuteSaturday: boolean;
    isExecuteSaturdayTime: string;
    isExecuteSunday: boolean;
    isExecuteSundayTime: string;
}

export class SettingValueForAll extends SettingValue {
    // CoworkersID: string
    // HoursToSend: string
    // PercentageToSend: number

    // IsSendSMS: boolean	
    // IsSendNotification: boolean	
    // IsSendEmail: boolean	
    // IsCreateReminder: boolean	
    // SMSText: string	
    // NotificationText: string	
    // EmailText: string

    // ExecuteTime: string
    // DaysToSend: number
    // EmailTemplate: any
    // Body: string
    // TemplateFilePath: string
    // Subject: string
    // Key: string
    // OtherBody: string
    // OtherTemplateFilePath: string
    // OtherSubject: string
    // OtherKey: string
    // IsSendSMSToNewCoWorker: boolean
    // IsSendEmailToNewCoWorker: boolean
    SendOnYear: number;

    // IsEnable: boolean

    OtherBeforeXDaysBody: string;
    OtherBeforeXDaysTemplateFilePath: string;
    OtherBeforeXDaysSubject: string;

    IsCreateReminderForCoworker: boolean;
    IsCreateReminderForOthers: boolean;
    IsCreateReminderForOthersBeforeXDays: boolean;

    IsSendSMSForCoworker: boolean;
    IsSendSMSForOthers: boolean;
    IsSendSMSForOthersBeforeXDays: boolean;

    IsSendNotificationForCoworker: boolean;
    IsSendNotificationForOthers: boolean;
    IsSendNotificationForOthersBeforeXDays: boolean;

    IsSendEmailForCoworker: boolean;
    IsSendEmailForOthers: boolean;
    IsSendEmailForOthersBeforeXDays: boolean;

    SMSTextForCoworker: string;
    SMSTextForOthers: string;
    SMSTextForOthersBeforeXDays: string;

    NotificationTextForCoworker: string;
    NotificationTextForOthers: string;
    NotificationTextForOthersBeforeXDays: string;

    NotificationTitleForCoworker: string;
    NotificationTitleForOthers: string;
    NotificationTitleForOthersBeforeXDays: string;
}

export class SettingForUnicontaValue {
    UserName: string;
    Password: string;
    AccessId: string;
    ComapnyName: string;
    ComapnyId: string;
    IsIntegrateUniconta: boolean;
    IsDefault: boolean;
    Customer: boolean;
    Material: boolean;
    ProjectInvoice: boolean;
    Supplier: boolean;
    FinancialDetail: boolean;
    CustomerVATOption: string;
    IsAcontoGenerateTXTFile: boolean;
    AcontoFilePath: string;
    IsAcontoGenerateSalesOrders: boolean;
    IsAcontoGenerateInvoices: boolean;
    AcontoAmountToGenerateInvoice: number;
    AcontoMoreAmountToGenerateInvoice: number;
    AcontoInvoiceMaterialID: string;
    AcontoInvoiceGenerateTime: string;
    AcontoInvoiceListSendCoworkerIDS: any[];
    IsGeneralGenerateTXTFile: boolean;
    GeneralFilePath: string;
    IsGeneralGenerateSalesOrders: boolean;
    IsGeneralGenerateInvoices: boolean;
    AcontoGenerateOrders: string;
    GeneralGenerateOrders: string;
}

export class SettingForOnedrive {
    ClientId: string;
    ClientSecret: string;
    TenantId: string;
    UserEmail: string;
    Path: string;
}
export class SettingForDropbox {
    Token: string;
    Path: string;
}
export class SettingForDriveValue {
    Dropbox: SettingForDropbox;
    Onedrive: SettingForOnedrive;
    DocumentDriveType: string;
}