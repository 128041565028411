import { Component, OnInit } from '@angular/core';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { AuthService } from 'app/core/services/auth.service';
import { TitleService } from 'app/core/services/title.service';

@Component({
  selector: 'vibe-reportInfo',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
  selectedIndex: number = 0;
  selectedTab: string = "";
  showSpinner: boolean = false;
  //Arrays to dispaly rows in datatable
  // roleName: string;
  // isValid: boolean = false;
  isFirstTabLoading: boolean = false;
  isSecTabLoading: boolean = false;
  isThirdTabLoading: boolean = false;
  isFourTabLoading: boolean = false;
  roleClaims: any;
  hasReportViewPermision: boolean = false;
  hasMaterialReportTabPermision: boolean = false;
  hasCoworkerAnswerViewTabPermision: boolean = false;
  hasCoworkerReportViewTabPermision: boolean = false;

  //Initiators
  constructor(private translationLoader: FuseTranslationLoaderService,
    private authService: AuthService,
    private titleService: TitleService) {
    this.translationLoader.loadTranslations(english, danish);
    this.titleService.SetTitle("TITLE_REPORT");
    this.roleClaims = this.authService.getRoleClaimsList();
  }

  ngOnInit() {
    //this.roleName = this.authService.getUserRole();

    // if (this.roleName == "Admin") {
    //   this.isValid = true;
    // }
    // else if (this.roleName == "Kontor") {
    //   this.isValid = true;
    // }
    // else if (this.roleName == "Maler") {
    //   this.isValid = false;
    // }
    // else if (this.roleName == "Gaest") {
    //   this.isValid = false;
    // }
    // else {
    //   this.isValid = true;
    // }
    this.authService.hasClaim(this.roleClaims.Report_View).subscribe(hasClaim => {
      if (hasClaim)
        this.hasReportViewPermision = hasClaim;
      else
        this.hasReportViewPermision = false;
    });
    this.authService.hasClaim(this.roleClaims.MaterialReport_View).subscribe(hasClaim => {
      if (hasClaim)
        this.hasMaterialReportTabPermision = hasClaim;
      else
        this.hasMaterialReportTabPermision = false;
    });
    this.authService.hasClaim(this.roleClaims.Coworker_Answer_View).subscribe(hasClaim => {
      if (hasClaim)
        this.hasCoworkerAnswerViewTabPermision = hasClaim;
      else
        this.hasCoworkerAnswerViewTabPermision = false;
    });
    this.authService.hasClaim(this.roleClaims.CoworkerReport_View).subscribe(hasClaim => {
      if (hasClaim)
        this.hasCoworkerReportViewTabPermision = hasClaim;
      else
        this.hasCoworkerReportViewTabPermision = false;
    });
    if(this.hasMaterialReportTabPermision && this.hasReportViewPermision)
      this.selectedTab = "material";
  }

  TabChange(event) {
    this.selectedIndex = event.index;
    this.selectedTab = event.tab.textLabel;
  }

  IsTabLoading(tabIndex, isLoading){
    this.isFirstTabLoading = false;
    this.isSecTabLoading = false;
    this.isThirdTabLoading = false;
    this.isFourTabLoading = false;

    if(tabIndex==0 && isLoading) { 
      this.isSecTabLoading = true;
      this.isThirdTabLoading = true;
      this.isFourTabLoading = true;
    }

    if(tabIndex==1 && isLoading) { 
      this.isFirstTabLoading = true;
      this.isThirdTabLoading = true;
      this.isFourTabLoading = true;
    }
  }
}
