import { Request } from "@angular/http/src/static_request";

export interface CommanBase {
    IsActive?: boolean;
    IsDeleted?: boolean;
}

export class RequestBase {
    public Index: number = 1;
    public Limit: number = 100;
    public TotalRows: number = 0;
    public OrderBy: string;
    public IsDeleted?: boolean;
    //public Search: string = "";

    constructor() {
    }
}


export class RequestBaseFull extends RequestBase {
    public IsDeleted: boolean = false;
    public IsActive: boolean = true;
    constructor() {
        super();
    }
}

export class RequestMaterial {
    public Index: number = 1;
    public Limit: number = 500;
    public TotalRows: number = 0;
    public OrderBy: string;
    public IsDeleted: boolean = false;
    public IsActive: boolean = true;
    public Text: string = "";
    public Number: string = "";
    public Barcode: string = "";
    public Temp: string = "false";
    public Type: string = '';
    public SupplierID: string = "";
    public MaterialType: number = 0;
    public IsMismatch: boolean = false;
    public IsShowOnlyWebShopMaterial: boolean = false;
    public IsOfferMaterial: boolean = false;
    constructor() {
    }
}

export class RequestMaterialFile extends RequestBaseFull {
    public Name: string = "";
    public MaterialId: string = "";
}

export class RequestCoWorkerFile extends RequestBaseFull {
    public Name: string = "";
    public CoWorkerId: string = "";
}

export class RequestCustomerFile extends RequestBaseFull {
    public Name: string = "";
    public CustomerId: string = "";
}

export class RequestCustomer extends RequestBaseFull {
    public Name: string = "";
    public Number: string = "";
    constructor() {
        super();
    }
}
export class RequestCustomerLog extends RequestBaseFull {
    public DebitorId: string = "";
    constructor() {
        super();
    }
}
export class RequestCustomerMaterial extends RequestBaseFull {
    public DebitorId: string = "";
    constructor() {
        super();
    }
}
export class RequestInquiry extends RequestBaseFull {
    public Name: string = "";
    public Number: string = "";
    constructor() {
        super();
    }
}

export class RequestProject extends RequestBaseFull {
    public Number: string = "";
    public CoworkerId: string = "";
    public includeAll: boolean = true;
    public CustomerId?: string;
    public Address?: string;
    public Status?: number;
    constructor() {
        super();
    }
}

export class RequestForPeojectsMaterialHistory extends RequestBaseFull {
    public MaterialID: string;
    public ProjectNumber: string = "";
    public CoworkerID: string;
    public StartDate?: string = null;
    public EndDate?: string = null;
    constructor() {
        super();
    }
}

export class OfferProject extends RequestProject {
    public Number: string = "";
    public CoworkerId: string = "";
    public includeAll: boolean = true;
    public CustomerId?: string;
    public Address?: string;
    public Status?: number;
    public ProjectId?: string;
    constructor() {
        super();
    }
}

export class RequestStatusProject extends RequestBaseFull {
    public Number: string = "";
    public CoworkerId: string = "";
    public includeAll: boolean = true;
    public CustomerId?: string;
    public Address?: string = "";
    public postNummer?: string = "";
    //public Status?: number;
    public Status?: string;
    public multipleStatus?: string;
    public Name?: string;
    public isLift: boolean = false;
    public isRiseman: boolean = false;
    public isIndoorOutdoorTypeProjects: string;
    public before60DayDate: string = null;
    public date: string;
    public startDate: string = null;
    public projectPurchaseStatus: string = "";
    public viewMode: string = "";
    constructor() {
        super();
    }
}

export class RequestProjectForOffer extends RequestBaseFull {
    public Search: string = "";
    constructor() {
        super();
    }
}

export class RequestStatusProjectForLocalStorage extends RequestBaseFull {
    public Number: string = "";
    public CoworkerId: string = "";
    public includeAll: boolean = true;
    public CustomerId?: string;
    public Address?: string;
    //public Status?: number;
    public Status?: string = "";
    public multipleStatus?: string;
    public Name?: string;
    constructor() {
        super();
    }
}

export class RequestProjectMaterial extends RequestBase {
    constructor() {
        super();
    }
}

export class RequestCaseOfficer extends RequestBase {
    public fileAssignmentId?: string;
    constructor() {
        super();
    }
}

export class RequestCoWorker extends RequestBaseFull {
    public Name?: string;
    public ProjectId?: number;
    public fileAssignmentId?: string;
    constructor() {
        super();
    }
}

export class RequestCaseTask extends RequestBase {
    public fileAssignmentId?: string;
    constructor() {
        super();
    }
}

export class RequestReminder {
    public CoworkerId: string;
    public Status?: boolean;
    public ReminderDate?: string;
    public StartDate?: string;
    public EndDate?: string;
    public Text?: string;
    public CreatedBy?: string;
    public ReminderListType: string = 'SentByMe';
    public Index: number = 1;
    public Limit: number = 100;
    public TotalRows: number = 0;
    public OrderBy: string;
    public IsDeleted?: boolean;
    public getAll: boolean = false;
    constructor() {
    }
}

export class RequestWorkHourGroupBy {
    public StartDate?: string;
    public EndDate?: string;
    public ProjectId?: string;
    public OrderBy?: string;
}

export class RequestRequisitionBaseWorkHourGroupBy {
    public StartDate?: string;
    public EndDate?: string;
    public ProjectId?: string;
    public RequisitionId?: string;
}

export class RequestWorkHour extends RequestBaseFull {
    public StartDate?: string;
    public EndDate?: string;
    public ProjectId?: string;
    public CoworkerId?: string;
    public Status: number;
    public Id?: string;
    constructor() {
        super();
    }
}

export class RequestRequisition extends RequestBaseFull {
    public Id?: string;
    public ProjectId?: string;
    constructor() {
        super();
    }
}

export class RequestProjectRequisitionByCowokrer extends RequestBaseFull {
    public Id?: string;
    public ProjectId?: string;
    public CoworkerId?: string;
    constructor() {
        super();
    }
}

export class RequestFile extends RequestBase {
    public Id?: string;
    public Name?: string;
}

export class RequestCompanyInfo extends RequestBaseFull {
    public ID: string;
    public Name: string;
    public Description: string;
    public IsHidden: boolean;
}

export class RequestProjectOffer extends RequestBaseFull {
    public Search: string; //custname
    public IndendoersUdendoersStatistID: string;
    public Status: number;
    constructor() {
        super();
    }
}

export class RequestProjectOfferRoom extends RequestBase {
    public Name: string;
    public OfferId: string;
    constructor() {
        super();
        this.IsDeleted = false;
    }
}
export class RequestProjectCustomerRoom extends RequestBase {
    public Name: string;
    public CustomerPropertyID: string;
    public IsActive: boolean;
    constructor() {
        super();
        this.IsActive = true;
        this.IsDeleted = false;
    }
}
export class RequestProjectRoom extends RequestBase {
    public Name: string;
    public ProjectId: string;
    constructor() {
        super();
        this.IsDeleted = false;
    }
}
export class RequestCustomerProperty extends RequestBase {
    public Search: string;
    public CustomerID: string;
    public IsActive: boolean;
    constructor() {
        super();
        this.IsActive = true;
        this.IsDeleted = false;
    }
}
export class RequestStatusLog {
    public ProjectId: number;
    public CustomerId: string;
    public LogStatusModuleName: number;
    public Index: number = 1;
    public Limit: number = 10;
    public TotalRows: number = 0;
    public OrderBy: string;
    public IsDeleted?: boolean;
    constructor() {
    }
}
export class RequestForLocalStorage {
    public ProjectRequest: any;
    public MaterialRequest: any;
    public CustomerRequest: any;
    public InquiryRequest: any;
    public CalenderRequest: any;
    public CalenderProjectRequest: any;
    public ProjectWorkingHoursDetailRequest: any;
    public OfferMaterialRequest: any;
}
export class RequestResourceWorkHours extends RequestBase {
    public StartDate: string;
    public EndDate: string;
    public IsDeleted: boolean = false;
    public IsActive: boolean = true;
    public WorkingStatus: number;
    public CoworkerId: string;
    public NonWorkingStatus: string;
    public Search: string;
    constructor() {
        super();
    }
}

export class RequestProjectLogs extends RequestBase {
    public ProjectID = null;
    public RequisitionID = null;
    public RequisitionMaterialID = null;
    public RequisitionCoworkerID = null;
    public InquiryID = null;
    public OfferID = null;
    public MaterialID = null;
    public IsActive?: boolean = null;
    public ModuleName = null;
}

export class SMSModules {
    public id: string;
    public moduleID: string;
    public name: string;
    public mobile: string;
    public tekst: string;
    public flag?: number;
    public countryCode: string;
}

export class RequestMaterialSupplierLogs extends RequestBaseFull {
    public Search: string = "";
    constructor() {
        super();
    }
}

export class SupplierRequest extends RequestBaseFull {
    public Search: string = "";
    constructor() {
        super();
    }
}

export class CarInformationsRequest extends RequestBaseFull {
    public Search: string = "";
    constructor() {
        super();
    }
}

export class PostnumberRequest extends RequestBaseFull {
    // public Index: number = 1;
    // public Limit: number = 100;
    // public TotalRows: number = 0;
    // public OrderBy: string;
    public Search: string = "";
    constructor() {
        super();
    }
}

export class RequestStataticsBase extends RequestBase {
    public Search: string = "";
    public CoworkerIds: string = "";
    public ModuleType: number = 1;
    constructor() {
        super();
    }
}

export class RequestCoworkerReminderBase extends RequestBase {
    public Search: string = "";
    constructor() {
        super()
    }
}

export class RequestSupplierMaterialPriceChangesList extends RequestBase {
    public SupplierID: string;
    constructor() {
        super()
    }
}

export class RequestOrderMaterial extends RequestBaseFull {
    public Search: string = "";
    public SupplierID: string = "";
    public StartDate: string = "";
    public EndDate: string = "";
    public StatusID: number;
    constructor() {
        super()
    }
}

export class RequestTearmsBase extends RequestBase {
    public Search: string = "";
    constructor() {
        super()
    }
}

export class RequestForGetInactiveProject extends RequestBase {
    public StartDate: string = null;
    public EndDate: string = null;
    constructor() {
        super()
    }
}
export class RequestMessageLogs extends RequestBase {
    public ProjectId = null;
    public RequisitionID = null;
    public CustomerId = null;
    public MobileNo = null;
}
export class RequestOfferMaterialCalculation extends RequestBaseFull {
    public OfferMaterialID: string = "";
    constructor() {
        super();
    }
}

export class RequestForQualityTestList extends RequestBase {
    IsActive?: boolean;

    constructor() {
        super()
    }
}

export class RequestOfferColorCode extends RequestBase {
    public offerID: string = "";
    public projectID: string = "";
    public qualityTestCategoryID: string = "";
    constructor() {
        super()
    }
}

export class RequestOfferQualityTestAnswer extends RequestBase {
    public offerID: string = "";
    public projectID: string = "";
    public qualityTestCategoryID: string = "";
    constructor() {
        super()
    }
}

export class RequestForSoldMaterialStock extends RequestBaseFull {
    public Search: string = "";
    public StartDate?: string = null;
    public EndDate?: string = null;
    constructor() {
        super();
    }
}

export class RequestForQualityTestTemperaturList extends RequestBase {
    public offerID: string = "";
    public IsActive: boolean = true;
    public projectID: string = "";
    public qualityTestCategoryID: string = "";
    constructor() {
        super()
    }
}
export class RequestProjectMaterialCountForOrdered {
    public requisitionNumber?: number;
    public projectNumber?: string;
    public TotalRows: number = 0;
    public IsActive: boolean = true;
    constructor() {
    }
}

export class RequestEmailSendingCoWorker extends RequestBaseFull {
    constructor() {
        super()
    }
}

export class RequestPaySlip {
    public StartDate?: string;
    public EndDate?: string;
    public CoworkerId?: string;
    public LeaveStatus?: number;
    public IsFutureWorkHours?: boolean = false;
    public isShowWorkingCoworker?: boolean = false;
    public IsShowingPaymentDetail?: boolean = false;
}

export class RequestRatesForTermsOfEmployment extends RequestBase {
    public Search: string = "";
    public TermsofEmploymentID: string = "";
    constructor() {
        super()
    }
}

export class RequestHolidayList extends RequestBase {
    constructor() {
        super();
    }
}
export class RequestMaterialOrderHistory {
    public Index: number = 1;
    public Limit: number = 100;
    public TotalRows: number = 0;
    public OrderBy: string;
    public MaterialID: string = "";
    public StartDate: string = "";
    public EndDate: string = "";
    constructor() {
    }
}

export class ResponseForLocalStorageData {
    public Coworkers: any;
    public Suppliers: any;
}

export class RequestOfferCreateLater {
    public OfferID: string = "";
    public CustomerID: string = "";
    public PropertyID: string = "";
    constructor() {
    }
}

export class RequestCustomerPropertyOffer {
    public CustomerID: string = "";
    public PropertyID: string = "";
    constructor() {
    }
}

export class MaterialInventoryRequest {
    public number: string = "";
    public startDate: string;
    public endDate: string;
    constructor() {
    }
}

export class RequestProjectWokringHoursDetail extends RequestStatusProject {
    public averagePrice: number = 0;
    public autoCalculation: boolean = true;
    public noOfEmployee: number = 10;
    public projectPurchaseStatusValue: number = 3;
    constructor() {
        super()
    }
}

export class RequestCommonBase {
    public Index: number = 1;
    public Limit: number = 100;
    public TotalRows: number = 0;
    public OrderBy: string;
    public IsDeleted?: boolean;
    public IsActive?: boolean;
    public Search: string = "";
    public CoworkerID: string = "";
    constructor() {
    }
}

export class BaseRequest {
    public Index: number = 1;
    public Limit: number = 100;
    public TotalRows: number = 0;
    public OrderBy: string;
    public Search: string = "";
}

export class RequestForDeviceTripList {
    public StartDate?: string;
    public EndDate?: string;
    public CoworkerID?: string;
    public NanolinkDeviceID?: string;
}

export class RequestMovePlanningWithRanage {
    public StartDate: string;
    public EndDate: string;
    public FromCoworkerID: string;
    public ToCoworkerID: string;
}

export class RequestPayroll extends RequestBaseFull {
    public Name?: string;
    public CoworkerID?: string;
    constructor() {
        super();
    }
}

export class RequestAssetsList extends RequestBase {
    public Labels: string;
    public ShowOnlyHomePosition?: boolean;
    public ShowLastServiceDue?: boolean;
    constructor() {
        super();
    }
}

export class RequestCalculatedOfferMaterialList {
    public offerID: string = "";
    public offerTypeIDS: string = "";
}

export class RequestCloseCoworkersPayment {
    public StartDate?: string;
    public EndDate?: string;
}

export class SendEmailPasswordData {
    public sendEmailId: string;
    public sendEmailPassword: string;
    public smtp: string;
    public smtpPort: any;
    public isEdit: boolean;
}
export class RequestForQualityTestImagesFilter {
    public orderBy: string;
    public search: string;
    constructor() {
    }
}

export class RequestOfferConvert {
    public offerId: string;
    public offerTypeIds: string[] = [];
    public isInactiveOffer: boolean = false;
}

export class SendEmailJson {
    IsSendEmail: boolean;
    IsTimeBased: boolean;
    isExecuteMonday: boolean;
    isExecuteTuesday: boolean;
    isExecuteWednesday: boolean;
    isExecuteThursday: boolean;
    isExecuteFriday: boolean;
    isExecuteSaturday: boolean;
    isExecuteSunday: boolean;
    HoursFromSend: string;
    HoursToSend: string;
}

export class RequestMaterialForReport extends RequestMaterial {
    public IsShowOfferMaterial: boolean = false;

}

export class RequestProjectRecuringSetting extends RequestBase {
    public search: string;
    public customerId: string;
    constructor() {
        super();
    }
}

export class RequestProjectLocation {
    public ProjectID = null;
    public Index: number = 1;
    public Limit: number = 100;
    public TotalRows: number = 0;
    public OrderBy: string;
    public search: string;
}

export class RequestSupplierMaterialPriceListChange {
    public SupplierNumber: string;
    public supplierId: string;
    public supplierPriceListType: string;
}

export class RequestForCarPositions {
    public TrackDeviceID?: string;
    public StartDate?: string;
    public EndDate?: string;
    public CarID?: string;
}

export class RequestProjectHoursDetails extends RequestBase {
    public status: string = "";
    public indoorOutdoorStatus: string = "";
    constructor() {
        super();
    }
}

export class RequestProjectWarningMaterial extends RequestBase {
    public ProjectId: string = "";
    public RequisitionId: string = "";
    constructor() {
        super();
    }
}

export class RequestOfferMaterialList extends RequestCalculatedOfferMaterialList {
    public projectID: string = "";
}