export const locale = {
    lang: 'ds',
    data: {
        'FIELDS': {
            'number': 'Nummer',
            'text': 'Tekst',
            'm2200': '0-200 m2',
            'm2201': '201-500 m2',
            'm2500': '> 500 m2',
            'appendix': 'Tillæg',
            'm2': 'M2',
            'lb15CmH': '15 Cm H',
            'lb30CmI': '30 Cm I',
            'lb40CmK': '40 Cm K',
            'lb60CmL': '60 Cm L',
            'stk10': '0-10 stk.',
            'stk20': '11-20 stk.',
            'stk21': '> 21 stk.',
            'TreatmentType':'Behandlings type',
            'Min': 'Min',
            'indoorOutdoor': 'Indvendigt / Udvendigt',
            'lbM2A': 'M2 A',
            'lbM2B': 'M2 B',
            'lbM2C': 'M2 C',
            'lbRammerD': '0-30 Cm D',
            'lbRuderE': '31-60 Cm E',
            'lbFalsF': 'False F',
            'lbKantG': 'Kant G ',
            'lbIndVangeM': 'In. vange + shocktr on ext.',
        },
        'BUTTONS':{
            'Add':'Tilføje',
            'BTNSAVE': 'Gem',
            'BACK': 'Tilbage',
        },
        'MESSAGES': {
            'NORECORD': 'Ingen registrering fundet',
            'NO_REMINDER_RECORD': 'Ingen påmindelser fundet',
            'NO_PROJECT_RECORD': 'Ingen Projekter fundet',
            'Created': 'Oprettet med succes.',
            'Updated': 'Opdateret med succes.',
            'Deleted': 'Slettet med succes.',
            'Failed': 'Noget gik galt !',
          },
    }
};