import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as danish } from "./translate/ds";
import { locale as english } from "./translate/en";
import { MatDialog, MatOption, MatSlideToggleChange } from '@angular/material';
import { AuthService } from 'app/core/services/auth.service';
import { SettingsService } from '../../settings.service';
import { ResponseBase } from "app/main/model/ResponseBase";
import { Settings, SettingValueWithWeekDays } from "app/main/model/Settings";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { CoWorker } from "app/main/model/CoWorker";
import { RequestCoWorker } from "app/main/model/RequestBase";
import { CoWorkersService } from "app/main/pages/coWorker/coWorkers.service";
import { NotificationsService } from "angular2-notifications";
import { TranslateService } from "@ngx-translate/core";
import { SettingEmailTempalteEditorComponent } from "../setting-email-tempalte-editor/setting-email-tempalte-editor.component";
import { ReminderWeekDays } from "app/main/model/Reminder";
import { ProjectStatus } from "app/constant/common.constant";

@Component({
    selector: "vibe-common-notifications-settings",
    templateUrl: "./common-notifications-settings.component.html",
    styleUrls: ["./common-notifications-settings.component.scss"]
})

export class CommonNotificationsSettingsComponent implements OnInit {
    @Input("commonNotificationSetting") commonNotificationSetting: Settings;
    @Input("CoWokers") CoWokers: CoWorker[];
    @Input("isShowExtraText") isShowExtraText: boolean = false;
    @Input("isShowSMSCheckbox") isShowSMSCheckbox: boolean = true;
    @Input("isShowNotificationCheckbox") isShowNotificationCheckbox: boolean = true;
    @Input("isShowEmailCheckbox") isShowEmailCheckbox: boolean = true;
    @Input("isShowCreateReminderCheckbox") isShowCreateReminderCheckbox: boolean = true;
    @Input("isShowTimeBase") isShowTimeBase: boolean = false;
    @Input("isShowStatus") isShowStatus: boolean = false;

    showSpinner: boolean = false;
    notificationForm: FormGroup;
    isSubmitted = false;
    coWokers: CoWorker[] = [];
    isGettingCoWokers: boolean = false;
    notificationsObj: Settings;
    settingMasterValues: any[] = [];
    settingValue: SettingValueWithWeekDays = new SettingValueWithWeekDays();
    weekDays = ReminderWeekDays;
    projectStatus = ProjectStatus;

    constructor(private translationLoaderService: FuseTranslationLoaderService,
        private fb: FormBuilder,
        private coWorkersService: CoWorkersService,
        private authService: AuthService,
        private settingsService: SettingsService,
        private translate: TranslateService,
        private notificationsService: NotificationsService,
        private dialog: MatDialog) {
        this.translationLoaderService.loadTranslations(danish, english);
    }

    ngOnInit() {
        this.settingMasterValues = Object.assign([], JSON.parse(localStorage.getItem("settingMasterValues")));
        this.notificationForm = this.fb.group({
            CoworkersID: [null, Validators.required],
            IsCreateReminder: [false],
            IsSendSMS: [false],
            IsSendNotification: [false],
            IsSendEmail: [false],
            SMSText: [null],
            NotificationText: [null],
            IsEnable: [true],
            ExtraText: [null],
            isExecuteMonday: false,
            isExecuteMondayTime: [null],
            isExecuteTuesday: false,
            isExecuteTuesdayTime: [null],
            isExecuteWednesday: false,
            isExecuteWednesdayTime: [null],
            isExecuteThursday: false,
            isExecuteThursdayTime: [null],
            isExecuteFriday: false,
            isExecuteFridayTime: [null],
            isExecuteSaturday: false,
            isExecuteSaturdayTime: [null],
            isExecuteSunday: false,
            isExecuteSundayTime: [null],
            status: [null],
        });

        this.notificationForm.get('IsSendSMS').valueChanges
            .subscribe(value => {
                if (value) {
                    this.notificationForm.controls['SMSText'].setValidators(Validators.required)
                } else {
                    this.notificationForm.controls['SMSText'].setValidators(null)
                }
                this.notificationForm.controls['SMSText'].updateValueAndValidity()
            });

        this.notificationForm.get('IsSendNotification').valueChanges
            .subscribe(value => {
                if (value) {
                    this.notificationForm.controls['NotificationText'].setValidators(Validators.required)
                } else {
                    this.notificationForm.controls['NotificationText'].setValidators(null)
                }
                this.notificationForm.controls['NotificationText'].updateValueAndValidity()
            });

        this.notificationForm.controls['CoworkersID'].valueChanges
            .subscribe(selectedCoWorkers => {
            });
        this.setSettings();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.coWokers = this.CoWokers;
    }

    getCoWorkers(model: RequestCoWorker) {
        model.OrderBy = "Name";
        this.isGettingCoWokers = true;
        this.coWorkersService.Get<ResponseBase>(model)
            .subscribe((response: ResponseBase) => {
                if (response) {
                    this.coWokers = Object.assign([], response.result);
                    this.getNextRequest(response.pagination, model, response.result);
                }
                else {
                    this.coWokers = [];
                    this.isGettingCoWokers = false;
                }
            },
                error => (err) => {
                    this.coWokers = [];
                    this.isGettingCoWokers = false;
                },
                () => {

                });
    }

    getNextRequest(pagination, model, response) {
        if (pagination.index == 1) {
            this.coWokers = Object.assign([], response);
            this.isGettingCoWokers = false;
        }
        else {
            this.coWokers.push(...response);
            this.isGettingCoWokers = false;
        }
        model.Index = pagination.index + 1;
        model.Limit = pagination.limit;
        model.TotalRows = pagination.totalCount;
        if (pagination.totalCount > pagination.index * pagination.limit)
            this.getCoWorkers(model);
    }

    DropDownSearchCoWorker(value) {
        if (value != null && value != "") {
            var request = new RequestCoWorker();
            request.Name = value;
            this.getCoWorkers(request);
        } else {
            let coWorker = new RequestCoWorker();
            coWorker.IsDeleted = false;
            this.getCoWorkers(coWorker);
        }
    }

    onSubmit() {
        this.notificationForm.markAllAsTouched();
        this.isSubmitted = true;
        if (this.notificationForm.valid) {
            this.changeSettings()
        }
    }

    setSettings() {
        if (this.commonNotificationSetting && this.commonNotificationSetting != null) {
            this.notificationsObj = this.commonNotificationSetting;
            this.settingValue = JSON.parse(this.notificationsObj.value)
            this.notificationForm.patchValue(this.settingValue);
            this.notificationForm.controls['CoworkersID'].setValue(this.notificationForm.controls['CoworkersID'].value.split(','))
            if(this.isShowStatus && this.notificationForm.controls['ExtraText'].value)
                this.notificationForm.controls['status'].setValue(this.notificationForm.controls['ExtraText'].value.split(',').map(item => parseInt(item)))
            this.changeInLocalStorage(this.notificationsObj.id, this.notificationsObj);
            if (this.isShowTimeBase) {
                this.weekDays.forEach(element => {
                    switch (element.value) {
                        case 'isExecuteMonday': {
                            element.IsShowTime = this.notificationForm.value.isExecuteMonday;
                            break;
                        }
                        case 'isExecuteTuesday': {
                            element.IsShowTime = this.notificationForm.value.isExecuteTuesday;
                            break;
                        }
                        case 'isExecuteWednesday': {
                            element.IsShowTime = this.notificationForm.value.isExecuteWednesday;
                            break;
                        }
                        case 'isExecuteThursday': {
                            element.IsShowTime = this.notificationForm.value.isExecuteThursday;
                            break;
                        }
                        case 'isExecuteFriday': {
                            element.IsShowTime = this.notificationForm.value.isExecuteFriday;
                            break;
                        }
                        case 'isExecuteSaturday': {
                            element.IsShowTime = this.notificationForm.value.isExecuteSaturday;
                            break;
                        }
                        case 'isExecuteSunday': {
                            element.IsShowTime = this.notificationForm.value.isExecuteSunday;
                            break;
                        }
                        default: {
                            element.IsShowTime = false;
                            break;
                        }
                    }
                });
            }
        }
    }

    IsEnableReminder(event: MatSlideToggleChange) {
        this.settingValue.IsEnable = event.checked;
        this.changeSettings();
    }

    setSettingsAfterUpdate() {
        this.settingsService.GetSettingsWithTemplateValue<ResponseBase>("Notification", this.commonNotificationSetting.key2, null).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.result && response.result.myResponse.length > 0) {
                    this.notificationsObj = response.result.myResponse[0];
                    this.settingValue = JSON.parse(this.notificationsObj.value)
                    this.notificationForm.patchValue(this.settingValue);
                    this.notificationForm.controls['CoworkersID'].setValue(this.notificationForm.controls['CoworkersID'].value.split(','))
                    if(this.isShowStatus && this.notificationForm.controls['ExtraText'].value)
                        this.notificationForm.controls['status'].setValue(this.notificationForm.controls['ExtraText'].value.split(',').map(item => parseInt(item)))
                    this.changeInLocalStorage(this.notificationsObj.id, this.notificationsObj)
                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinner = false;
            }
        });
    }

    changeSettings() {
        this.showSpinner = true;
        this.settingValue.CoworkersID = this.notificationForm.value.CoworkersID.toString();
        this.settingValue.IsCreateReminder = this.notificationForm.value.IsCreateReminder;
        this.settingValue.IsSendSMS = this.notificationForm.value.IsSendSMS;
        this.settingValue.IsSendNotification = this.notificationForm.value.IsSendNotification;
        this.settingValue.IsSendEmail = this.notificationForm.value.IsSendEmail;
        this.settingValue.SMSText = this.notificationForm.value.SMSText || '';
        this.settingValue.NotificationText = this.notificationForm.value.NotificationText || '';
        this.settingValue.IsEnable = this.notificationForm.value.IsEnable;
        this.settingValue.ExtraText = this.notificationForm.value.ExtraText ? this.notificationForm.value.ExtraText : null;
        if(this.isShowStatus)
            this.settingValue.ExtraText = this.notificationForm.value.status ? this.notificationForm.value.status.join(): null;
        if (this.isShowTimeBase) {
            this.settingValue.isExecuteMonday = this.notificationForm.value.isExecuteMonday;
            this.settingValue.isExecuteMondayTime = this.notificationForm.value.isExecuteMonday ? this.notificationForm.value.isExecuteMondayTime : null;
            this.settingValue.isExecuteTuesday = this.notificationForm.value.isExecuteTuesday;
            this.settingValue.isExecuteTuesdayTime = this.notificationForm.value.isExecuteTuesday ? this.notificationForm.value.isExecuteTuesdayTime : null;
            this.settingValue.isExecuteWednesday = this.notificationForm.value.isExecuteWednesday;
            this.settingValue.isExecuteWednesdayTime = this.notificationForm.value.isExecuteWednesday ? this.notificationForm.value.isExecuteWednesdayTime : null;
            this.settingValue.isExecuteThursday = this.notificationForm.value.isExecuteThursday;
            this.settingValue.isExecuteThursdayTime = this.notificationForm.value.isExecuteThursday ? this.notificationForm.value.isExecuteThursdayTime : null;
            this.settingValue.isExecuteFriday = this.notificationForm.value.isExecuteFriday;
            this.settingValue.isExecuteFridayTime = this.notificationForm.value.isExecuteFriday ? this.notificationForm.value.isExecuteFridayTime : null;
            this.settingValue.isExecuteSaturday = this.notificationForm.value.isExecuteSaturday;
            this.settingValue.isExecuteSaturdayTime = this.notificationForm.value.isExecuteSaturday ? this.notificationForm.value.isExecuteSaturdayTime : null;
            this.settingValue.isExecuteSunday = this.notificationForm.value.isExecuteSunday;
            this.settingValue.isExecuteSundayTime = this.notificationForm.value.isExecuteSunday ? this.notificationForm.value.isExecuteSundayTime : null;
        }
        this.notificationsObj.value = JSON.stringify(this.settingValue);

        this.settingsService.UpdateSettingWithTemplate<ResponseBase>(this.notificationsObj).subscribe({
            next: (response: ResponseBase) => {
                this.showSpinner = false;
                if (response != null && response.result) {
                    this.notificationsObj = response.result;
                    if (this.settingValue.IsEnable)
                        this.setSettingsAfterUpdate();
                    this.translate.get("MESSAGES.Saved").subscribe({
                        next: (res) => {
                            this.notificationsService.create(res);
                        },
                        error: err => { }
                    });
                }
            },
            error: err => {
            },
            complete: () => {
                this.showSpinner = false;
                this.isSubmitted = false;
            }
        });
    }

    changeInLocalStorage(ID, new_obj) {
        if (this.settingMasterValues.find(obj => obj.id == ID)) {
            this.settingMasterValues.forEach((old_obj, index) => {
                if (old_obj.id == ID) {
                    this.settingMasterValues[index] = new_obj
                }
            })
        } else {
            this.settingMasterValues.push(new_obj)
        }
        localStorage.setItem("settingMasterValues", JSON.stringify(this.settingMasterValues));
    }

    onWeekDayChange($event, days) {
        days.IsShowTime = $event.checked;
    }

    EmailTemplateDialog() {
        var lableName = "";
        if (this.commonNotificationSetting && this.commonNotificationSetting.key2 && this.commonNotificationSetting.key2 == 'Inquiry')
            lableName = 'Note.InquiryEmailTextLabel';
        if (this.commonNotificationSetting && this.commonNotificationSetting.key2 && this.commonNotificationSetting.key2 == 'PlanningLeave')
            lableName = 'Note.PlanningEmailTextLabel';
        if (this.commonNotificationSetting && this.commonNotificationSetting.key2 && this.commonNotificationSetting.key2 == 'Offer')
            lableName = 'Note.OfferEmailTextLabel';
        if (this.commonNotificationSetting && this.commonNotificationSetting.key2 && this.commonNotificationSetting.key2 == 'OfferRejectedByCustomer')
            lableName = 'Note.OfferRejectedEmailTextLabel';
        if (this.commonNotificationSetting && this.commonNotificationSetting.key2 && this.commonNotificationSetting.key2 == 'ProjectStatusBeforeSevenDays')
            lableName = 'Note.ProjectStatusBeforeSevenDaysEmailTextLabel';
        if (this.commonNotificationSetting && this.commonNotificationSetting.key2 && this.commonNotificationSetting.key2 == 'ProjectWarningMaterialColorCodeReminder')
            lableName = 'Note.ProjectWarningMaterialColorCodeReminderEmailTextLabel';

        const dialogRef = this.dialog.open(SettingEmailTempalteEditorComponent, {
            height: '90%',
            width: '90%',
            data: { settingValue: this.settingValue, IsOther: false, IsBday: false, IsSameDay: false, Note: lableName }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result != null && result != '') {
                this.settingValue = result;
                this.onSubmit();
            }
        });
    }

    ddlProjectStatus(event){
        
    }
}