export const locale = {
    lang: 'en',
    data: {
        'FIELDS': {
            'Search': 'Search',
            'Project': 'Project',
            'Type': 'Type',
            'Description': 'Description',
            'Name': 'Name',
            'Width': 'Width',
            'Height': 'Height',
            'ADDNEWROOM': 'Add New Room',
            'Address': 'Address',
            'Email': 'Email',
            'CustomerName': 'Customer Name',
            'PhoneNo': 'Phone No',
            'IndoorOutDoor': 'Indoor,Outdoor,Spray Painting,Reclamation',
            'City': 'City',
            'DepartmentCity': 'Department City',
            'Zipcode': 'Zipcode',
            'DepartmentZipCode': 'Department Zipcode',
            'DepartmentAddress': 'Department Address',
            'SelectCustomer': 'Select customer *',
            'SelectIndoorOutDoor': 'Select Indoor,Outdoor,Spray *',
            'EnterDescription': 'Enter Description',
            'Not-Found-CUSTOMER': 'Customer not found',
            'Not-Found-INQUIRY': 'Inquiry not found',
            'Not-Found-PROJECT': 'Project not found',
            'Not-Found-REQUISITION': 'Requisition not found',
            'Inquiry': 'Inquiry',
            'SetAddress': 'Set Address',
            'Requisition': 'Requisition',
            'Setasinactive': 'Set as inactive',
            'Select': 'Select',
            'Status': 'Status',
            'Property': 'Property',
            'GetInactive': 'Get inactive',
            'MatchCustomerInformation': '** Match customer\'s information based on name, email, phone.',
            'NoMatchCustomerInformation': 'No customer\'s match detail found',
            'Number': 'Number',
            'OfferInvoiceTerms': 'Tilbyd faktura tårer'
        },
        'MESSAGES': {
            'Failed': 'Operation failed , try later',
            'Success': 'Success.',
            'NoRoomList': 'No Room Detail Add.',
            'CanNotConvertToProject':'Add atlest  1 room for converting to project.'
        },
        'InsideOrOutsideOffer': {
            'value1': 'Inside',
            'value2': 'OutSide',
        },
        'BUTTONS': {
            'SAVE': 'Save',
            'BACK': 'Cancel',
            'ConvertToProject':'Convert Project',
            'GotoProject':'Goto Project',
            'SHOWCUSTOMER': 'Show Cust.',
            'NEWCUSTOMER': 'New Customer',
            'UnassignCustomer': 'Remove Cust.',
            'MatchCustomer': 'Match Customer',
        },
        'selectedValueIndoorOutdoorStatus': {
            'value1': 'Indoor ',//Man in a room
            'value2': 'Outdoor', //Sun
            'value3': 'Spray Painting',//spraypaint
            'value4': 'Complaint', //Reklamation
            'value5': 'Staff purchase', //drawing of painter
            'value6': 'Store purchase' //Nordsjø logo
        },
        'selectedValueOfferStatus': {
            'value1': 'Created',
            'value2': 'Send to customer',
            'value3': 'Approved by customer',
            'value4': 'Rejected by customer',
            'value5': 'Project Created',
        },
        'FILTERS': {
            'Select': 'Select'
        },
        'Validations': {
            'EmailRequired': 'Email Required',
            'PhoneRequired': 'Phone Required',
            'ValidPhoneRequired': 'Enter Valid Phone',
            'AddressRequired': 'Address Required',
            'CityRequired': 'City Required',
            'ZipCodeRequired': 'ZipCode Required',
            'ValidEmail':'Enter Valid Email Address',
        },
        'Headers':{
            'CustomerDetail': 'Are you sure you want to attach @customerdetail to @inquirydetail ?'
        },
        'TOOTIPORTITLE':{
            'AddCustomer':'Add Customer',
            'ShowDirection':'Get Direction',
            'ViewProject':'View Project',
            'AddCustomerProperty':'Properties'
        }
    }
};
