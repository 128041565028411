import { Component, OnInit, ViewChild, OnDestroy, NgZone } from '@angular/core';
import { FuseTranslationLoaderService } from 'app/core/services/translation-loader.service';
import { locale as english } from './translate/en';
import { locale as danish } from './translate/ds';
import { AuthService } from 'app/core/services/auth.service';
import { TitleService } from 'app/core/services/title.service';
import { CommonService } from 'app/core/services/common.service';
import { RequestCoWorker, RequestResourceWorkHours, RequestCoWorkerFile, RequestBaseFull } from 'app/main/model/RequestBase';
import { CalenderWorkHourPopup } from 'app/main/model/Calender';
import { ResponseBase } from 'app/main/model/ResponseBase';
import { WorkingHourDetails } from 'app/main/model/Project';
import { WorkHourStatus, WorkHoursType } from 'app/main/model/WorkHourStatus';
import { ProjectService } from 'app/main/pages/project/project.service';
import { CoWorkersService } from 'app/main/pages/coWorker/coWorkers.service';
import {
    TimelineViewsService, TimelineMonthService, EventSettingsModel,
    GroupModel, RenderCellEventArgs, ActionEventArgs, TimeScaleModel, EventRenderedArgs,
    getWeekFirstDate, ScheduleComponent, ToolbarActionArgs, DragAndDropService, PopupOpenEventArgs, PrintService,
} from '@syncfusion/ej2-angular-schedule';
import { loadCldr, Browser, L10n, Internationalization } from '@syncfusion/ej2-base';
import * as moment from 'moment';
import { addDays } from 'date-fns';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDrawer, MatOption } from '@angular/material';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { CalenderWorkingHourPopupComponent } from 'app/main/pages/calendar/component/working-hour-popup/working-hour-popup.component';
import { CalenderEditNonWorkingHourPopupComponent } from 'app/main/pages/calendar/component/edit-non-working-hour-popup/edit-non-working-hour-popup.component';
import { CalenderInquiryWorkingHourPopupComponent } from 'app/main/pages/calendar/component/inquiry-working-hour-popup/inquiry-working-hour-popup.component';
import { DropDownList } from '@syncfusion/ej2-dropdowns';
import { ItemModel } from '@syncfusion/ej2-navigations';
import { WorkhoursService } from 'app/main/pages/workHour/workhours.service';
import { CalenderEditWorkingHourPopupComponent } from './component/edit-working-hour-popup/edit-working-hour-popup.component';
// import { RemoveNonWorkingHourPopupComponent } from './component/remove-non-working-hour-popup/remove-non-working-hour-popup';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { QuestionPopupComponent } from './component/question-popup/question-popup.component';
import { ProjectStatus, Colors } from 'app/constant/common.constant';
import { DatePipe } from '@angular/common';
import { CalendarDefaultCoworkerComponent } from 'app/main/pages/settings/component/calendar-default-coworker/calendar-default-coworker.component';
import { CalendarService } from './calendar.service';
import { SignalRService } from 'app/core/services/signalR.service';
import { ClickPlanPopupComponent } from './component/click-plan-popup/click-plan-popup.component';
import { MovePlanningWithDateRangeComponent } from './component/move-planning-with-date-range/move-planning-with-date-range.component';
import { SortPipe } from 'app/core/pipes/sort.pipe';
import { CalenderAddNonWorkingHourPopupComponent } from './component/add-non-working-hour-popup/add-non-working-hour-popup.component';
import { MovePlanningMessageModelComponent } from 'app/main/pages/move-planning-message-model/move-planning-message-model.component';
import { GoogleMapDirectionComponent } from 'app/main/pages/google-map-direction/google-map-direction.component';
import { MovePlanningFromOneCowrkerToAnother } from './component/move-planning-to-another-coworker/move-planning-to-another-coworker.component';
import { CompanyInfo } from 'app/main/model/CompanyInfo';
import { CompanyInfoService } from 'app/main/pages/companyInfo/companyInfo.service';
import { Subject } from 'rxjs';
import { BulkProjectPlanPopupComponent } from './component/bulk-project-plan-popup/bulk-project-plan-popup.component';
import { TabIdService } from 'app/core/services/tabId.service';
import { SettingsService } from 'app/main/pages/settings/settings.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
//import { NumberOfEmployee } from 'app/main/model/CoWorker';
//var momentBusiness = require('moment-business-days')

declare var require: any;
loadCldr(
    require('cldr-data/supplemental/numberingSystems.json'),
    require('cldr-data/main/da/ca-gregorian.json'),
    require('cldr-data/main/da/numbers.json'),
    require('cldr-data/main/da/timeZoneNames.json'));
L10n.load({
    'da': {
        'schedule': {
            'today': 'i dag',
        }
    }
});

const colors: string[] = Colors;

@Component({
    selector: 'vibe-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.scss'],
    providers: [TimelineViewsService, TimelineMonthService, DragAndDropService, PrintService],
})
export class CalendarComponent implements OnInit, OnDestroy {
    showSpinner: boolean = false;
    //Arrays to dispaly rows in datatable
    @ViewChild("scheduleObj", { static: false }) public scheduleObj: ScheduleComponent;
    @ViewChild('allSelected', { static: false }) private allSelected: MatOption;
    @ViewChild('allSelectedForNonWorking', { static: false }) private allSelectedForNonWorking: MatOption;
    @ViewChild('allStatusSelectedForWorkHourselected', { static: false }) private allStatusSelectedForWorkHourselected: MatOption;
    @ViewChild("drawer", { static: false }) drawerObj: MatDrawer;
    public instance: Internationalization = new Internationalization('da');
    requestResourceWorkHours: RequestResourceWorkHours;
    coWokerList: any = [];
    projectStatus = ProjectStatus;
    hourlyStatus: any[] = [];
    searchCoWokerList: any = [];
    selectedcoWorkerIds: any = [];
    searchCoworker: string = "";
    nonWorkingStatus = WorkHourStatus;
    selectedNonworkingStatus: any = [];
    allSelectedcoWorkerId: boolean = false;
    workHourList = WorkHoursType;
    workHourType: number = 1;
    //roleName: string;
    projectNumber: string = "";
    requisitionId: string = "";
    // isValid: boolean = false;
    public weekFirstDay: number = 1;
    public selectedDate: Date = new Date();
    //public views: Array<string> = ['TimelineWeek', 'TimelineMonth'];
    public roomData: any = [];
    isShowProjectWorkingHourDetail: boolean = false;
    isShowPlanningWorkingHourDetail: boolean = false;
    currentLocale: string = '';
    public dayInterval: number = 30;

    public eventSettings: EventSettingsModel = {
        //dataSource: roomData
    };
    public group: GroupModel = {
        resources: ['MeetingRoom'],
        enableCompactView: true,
    };
    public cssClass: string = 'schedule-cell-dimension';
    public allowMultipleRoom: Boolean = true;
    public rowAutoHeight: Boolean = true;
    public timeScale: TimeScaleModel = { enable: false };
    public resourceDataSource: any = [];
    public workHoursList: any = [];
    public holidayList: any = [];
    public showWeekend: boolean = true;
    calenderWorkHourPopup = new CalenderWorkHourPopup();
    workingHourDetails = new WorkingHourDetails();
    workingHourDetailsForPlanning = new WorkingHourDetails();
    isShowHourDetail: boolean = false;
    isShowWorkingHourDetail: boolean = true;
    currentDate: any;
    //numberOfEmployee: NumberOfEmployee[] = [];
    weekNumbers: any = [];
    selectedWeekNumber: string = "1";
    isShowTime: boolean = true;

    idleState = 'Not started.';
    timedOut = false;
    lastPing?: Date = null;
    TodaysDateTime: Date = new Date();
    currentView: string = "'TimelineWeek'";
    isShowProjectList: boolean = true;
    isShowInquiryList: boolean = false;
    isGotoMaterial: boolean = false;
    currentselectedcoWorkerObj: any = {};
    coworkerDetailForDoubleClick: any;
    defaultCoworkerRequest: RequestCoWorkerFile = new RequestCoWorkerFile();
    planningStartDate: string = "";
    isShowMap: boolean = false;
    companyInfoData: CompanyInfo;

    projectList:Subject<any> = new Subject();
    roleClaims: any;
    ownPlanningView: boolean = false;
    tabId: string;
    requestModel: RequestBaseFull = new RequestBaseFull();
    defaultMaterialList: any = [];
    //Initiators
    constructor(private translationLoader: FuseTranslationLoaderService,
        private authService: AuthService,
        private titleService: TitleService,
        private coWorkersService: CoWorkersService,
        private workhoursService: WorkhoursService,
        private projectservice: ProjectService,
        private settingsService: SettingsService,
        private notificationsService: NotificationsService,
        private translation: TranslateService,
        private commonService: CommonService,
        private _route: ActivatedRoute,
        private _router: Router,
        private dialog: MatDialog,
        private _fuseSidebarService: FuseSidebarService,
        private idle: Idle,
        private keepalive: Keepalive,
        private _ngZone: NgZone,
        private translate: TranslateService,
        private datePipe: DatePipe,
        private calanderService: CalendarService,
        private signalRService: SignalRService,
        private tabIdService: TabIdService,
        private companyInfoService: CompanyInfoService,
        private sortPipe: SortPipe) {
        this.translationLoader.loadTranslations(english, danish);
        this.titleService.SetTitle("TITLE_PLANING");
        //this.idleAfterOneMin();
        window["projectComponent"] = { component: this, zone: this._ngZone };
        this.roleClaims = this.authService.getRoleClaimsList();
        this.tabId = this.tabIdService.generateUniqueId();
        //this.numberOfEmployee = this.coWorkersService.generateEmployeeArray(25);
    }

    ngOnInit() {
        console.log('Current Tab ID:', this.tabId);
        //this.currentLocale = this.translate.currentLang;
        // this.translate.onLangChange
        //     .subscribe((event: LangChangeEvent) => {
        //         //console.log('onLangChange', event);
        //         this.currentLocale = event.lang;
        //         this.scheduleObj.locale = this.currentLocale === 'en' ? 'sv' : 'en';
        //     });

        this._fuseSidebarService.getSidebar('navbar').fold();
        this.authService.hasClaim(this.roleClaims.Own_Planning_View).subscribe(hasClaim => {
            if (hasClaim) 
                this.ownPlanningView = hasClaim
             else 
                this.ownPlanningView = false;
        });
        //this.roleName = this.authService.getUserRole();
        this.currentDate = moment(new Date()).format("YYYY-MM-DD");
        this.selectedWeekNumber = moment(this.currentDate, "YYYY-MM-DD").week().toString();
        this.addWeeksNumber();
        // if (this.roleName == "Admin") {
        //     // this.isValid = true;
        //     // this.isShowWorkingHourDetail = true;
        //     // this.getWorkingHourDetailsForPlanning(this.workingHourDetails.noOfEmployee);
        //     // this.hourlyStatus = [];
        //     // this.hourlyStatus.push(0);
        //     // for (let i = 0; i < this.projectStatus.length; i++) {
        //     //     this.hourlyStatus.push(this.projectStatus[i].id);
        //     // }
        //     // this.getWorkingHourDetails();
        // }
        // else if (this.roleName == "Kontor") {
        //     this.isValid = true;
        // }
        // else if (this.roleName == "Maler") {
        //     this.isValid = false;
        // }
        // else if (this.roleName == "Gaest") {
        //     this.isValid = false;
        // }
        // else {
        //     this.isValid = true;
        // }
        this.showSpinner = true;

        let coWorker = new RequestCoWorker();
        coWorker.IsDeleted = false;
        //this.getCoWorkers(coWorker);

        this._route.params.subscribe(params => {
            if (params['projectNumber']) {
                //this.drawerObj.toggle();
                this.projectNumber = params['projectNumber'];
                this.requisitionId = params['requisitionId'];
            }
        });
        this._route.queryParams.subscribe(params => {
            this.planningStartDate = params['startDate'] ? moment(params['startDate'], "DD.MM.YYYY").format("YYYY-MM-DD") : "";
        });
        this.getCoworkersWithHoliday(coWorker, true);
        document.documentElement.style.setProperty('--hide-show-time', 'none');
        this.TodaysDateTime = new Date();
        this.TodaysDateTime.setTime(this.TodaysDateTime.getTime() + (30 * 60 * 1000));
        this.showPlanningTime(this.isShowTime);
        this.subscribeToEvents();
        this.GetCompanyInformation();
        this.getCustomerDefaultMaterials();
    }

    // ngAfterViewInit() {
    //     if (this.scheduleObj && this.scheduleObj.locale)
    //         //this.scheduleObj.locale = this.currentLocale;// === 'ds' ? 'en' : 'ds';
    //         this.scheduleObj.locale = this.currentLocale === 'en' ? 'sv' : 'en';
    // }

    // ngAfterViewChecked() {
    //     if (this.scheduleObj && this.scheduleObj.locale)
    //     this.scheduleObj.locale = this.currentLocale === 'en' ? 'sv' : 'en';
    // }


    // idleAfterOneMin() {
    //     // sets an idle timeout of 5 seconds, for testing purposes.
    //     this.idle.setIdle(10);
    //     // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    //     this.idle.setTimeout(50);
    //     // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    //     this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    //     this.idle.onIdleEnd.subscribe(() => this.idleState = 'No longer idle.');
    //     this.idle.onTimeout.subscribe(() => {
    //         this.idleState = 'Timed out!';
    //         this.timedOut = true;
    //         var currentDate = new Date;
    //         if (currentDate < this.TodaysDateTime) {
    //             if (this.roleName == "Admin") {
    //                 this.getWorkingHourDetails();
    //                 this.getWorkingHourDetailsForPlanning(this.workingHourDetails.noOfEmployee);
    //             }
    //             let coWorker = new RequestCoWorker();
    //             coWorker.IsDeleted = false;
    //             this.getCoworkersWithHoliday(coWorker, true);
    //             this.reset();
    //         }
    //     });
    //     this.idle.onIdleStart.subscribe(() => this.idleState = 'You\'ve gone idle!');
    //     this.idle.onTimeoutWarning.subscribe((countdown) => this.idleState = 'You will time out in ' + countdown + ' seconds!');
    //     this.idle.getTimeout

    //     // sets the ping interval to 15 seconds
    //     this.keepalive.interval(5);
    //     this.keepalive.onPing.subscribe(() => this.lastPing = new Date());
    //     this.reset();
    // }

    getCoWorkers(model) {
        model.Limit = 400;
        model.OrderBy = "Name";
        this.coWorkersService.Get<ResponseBase>(model)
            .subscribe((response: ResponseBase) => {
                if (response) {
                    this.coWokerList = response.result;//.filter(x => x.doNotShowOnPlanReminderProj == false);
                    this.searchCoWokerList = this.coWokerList;
                    var request = new RequestResourceWorkHours();
                    request = this.coWorkersService.getRequestParameterForCalender();
                    if (request.CoworkerId) {
                        this.selectedcoWorkerIds = request.CoworkerId.split(',');
                    }
                    else {
                        this.selectedcoWorkerIds.push(this.authService.getCoWorkerId());
                    }
                    if (request.NonWorkingStatus) {
                        this.selectedNonworkingStatus = request.NonWorkingStatus.split(',').map(function (item) {
                            return parseInt(item);
                        });
                    }
                    var weekNumber = moment(new Date()).week();
                    let tempStartDate: Date = new Date(moment().week(weekNumber || 1).startOf("week").format("YYYY-MM-DD"));
                    const endDate: Date = addDays(new Date(tempStartDate.getTime()), 6);
                    request.StartDate = moment().week(weekNumber || 1).startOf("week").format("YYYY-MM-DD");
                    request.EndDate = moment(endDate).format("YYYY-MM-DD");
                    this.workHourType = 1;
                    this.getCalenderData(request);
                }
            },
                error => (err) => {
                },
                () => {
                });
    }

    getCoworkersWithHoliday(model, isCalculateDate) {
        model.Limit = 400;
        model.OrderBy = "Name";
        this.coWorkersService.GetCoworkersWithHoliday<ResponseBase>(model)
            .subscribe((response: ResponseBase) => {
                if (response) {
                    this.coWokerList = response.result.filter(x => x.doNotShowOnPlanReminderProj == false);
                    this.searchCoWokerList = this.coWokerList;
                    var request = new RequestResourceWorkHours();
                    request = this.coWorkersService.getRequestParameterForCalender();
                    // if (request.CoworkerId && this.roleName == "Admin") {
                    //     this.selectedcoWorkerIds = request.CoworkerId.split(',');
                    // }
                    // else {
                    //     this.selectedcoWorkerIds.push(this.authService.getCoWorkerId());
                    // }
                    if (request.NonWorkingStatus) {
                        this.selectedNonworkingStatus = request.NonWorkingStatus.split(',').map(function (item) {
                            return parseInt(item);
                        });
                    }
                    if (isCalculateDate) {
                        moment.locale('da');
                        let tempDate = this.planningStartDate ? new Date(moment(this.planningStartDate).format("YYYY-MM-DD")) : new Date();
                        const firstDayOfWeek: Date = getWeekFirstDate(tempDate, this.weekFirstDay as number);
                        let startDate: Date = firstDayOfWeek;
                        this.selectedWeekNumber = moment(firstDayOfWeek).week().toString();
                        const endDate: Date = addDays(new Date(startDate.getTime()), 6);
                        request.StartDate = moment(startDate).format("YYYY-MM-DD");//moment().week(weekNumber || 1).startOf("week").format("YYYY-MM-DD");
                        request.EndDate = moment(endDate).format("YYYY-MM-DD");
                        //this.selectedDate = new Date(startDate);
                    }
                    this.workHourType = 1;
                    if (request.CoworkerId) {
                        this.selectedcoWorkerIds = request.CoworkerId.split(',');
                        this.getCalenderData(request);
                    }
                    else {
                        this.getDefaultCoworkers(request);
                    }
                }
            },
                error => (err) => {
                },
                () => {
                });
    }

    statusChangeCallCalenderData() {
        this.showSpinner = true;
        var tempEventData = [];
        if (this.scheduleObj) {
            tempEventData = this.scheduleObj.eventsData;
            if (tempEventData.length > 0) {
                tempEventData.filter(item1 => {
                    this.scheduleObj.deleteEvent(item1.Id);
                })
            }
        }

        this.getCalenderData(this.requestResourceWorkHours);
    }
    DropDownSearchCoWorker() {
        this.searchCoWokerList = this.coWokerList.filter(o => o.name.toLowerCase().includes(this.searchCoworker.toLowerCase()));
    }

    DropDownCoWorkerSelect() {
        if (this.allSelected.selected) {
            this.selectedcoWorkerIds = [];
            this.selectedcoWorkerIds = this.coWokerList.map(o => o.id);
            this.selectedcoWorkerIds.push("0");
        }
        else {
            this.selectedcoWorkerIds = [];
        }
        this.statusChangeCallCalenderData();
    }

    DDLNonWorkingAllStatus() {
        if (this.allSelectedForNonWorking.selected) {
            this.selectedNonworkingStatus = [];
            this.selectedNonworkingStatus = this.nonWorkingStatus.map(o => o.id);
            this.selectedNonworkingStatus.push(0);
        }
        else {
            this.selectedNonworkingStatus = [];
        }
        this.statusChangeCallCalenderData();
    }

    onOpenedChange($event) {
        this.authService.openProjectListModel(true);
    }

    getCalenderData(model: RequestResourceWorkHours) {
        this.showSpinner = true;
        model.OrderBy = "Date";
        if (this.selectedcoWorkerIds.length <= 0) {
            this.selectedcoWorkerIds.push(this.authService.getCoWorkerId());
        }
        model.CoworkerId = this.selectedcoWorkerIds.join();
        model.WorkingStatus = this.workHourType;
        if (this.workHourType == 1) {
            this.selectedNonworkingStatus = [];
            this.selectedNonworkingStatus = this.nonWorkingStatus.map(o => o.id);
            this.selectedNonworkingStatus.push(0);
        }
        model.NonWorkingStatus = this.selectedNonworkingStatus.join();
        this.requestResourceWorkHours = model;
        //StartEnd date 
        var date = new Date();
        var tempStartDate = moment(date).startOf('month');
        var tempEndDate = moment(new Date(new Date().setMonth(new Date().getMonth() + 2))).format("YYYY-MM-DD");
        tempEndDate = moment(tempEndDate).endOf('month').format("YYYY-MM-DD");

        if (model.StartDate && model.EndDate) {
            var start = new Date(model.StartDate);
            var end = new Date(model.EndDate);
            tempStartDate = moment(model.StartDate).startOf('month');
            var endDate = moment(new Date(new Date(end).setMonth(new Date(end).getMonth() + 1))).format("YYYY-MM-DD");
            tempEndDate = moment(endDate).endOf('month').format("YYYY-MM-DD");
        }
        model.StartDate = moment(tempStartDate).format("YYYY-MM-DD");
        model.EndDate = moment(tempEndDate).format("YYYY-MM-DD");
        //end

        this.coWorkersService.setRequestParameterForCalender(model);
        this.coWorkersService.GetCoworkersWithWorkHours<ResponseBase>(model)
            .pipe(
                debounceTime(100), 
                distinctUntilChanged()
            )
            .subscribe((response: ResponseBase) => {
                if (response) {
                    this.showSpinner = false;
                    this.holidayList = response.result.holidayList;
                    localStorage.setItem("HolidayList", JSON.stringify(this.holidayList));
                    
                    if ((response.result.workHoursList && response.result.workHoursList.length > 0)
                        || (response.result.nonWorkHoursList && response.result.nonWorkHoursList.length > 0)) {
                        this.workHoursList = response.result.workHoursList;

                        // if (this.workHoursList.length > 0) {
                        //     this.projectservice.setBGImageForProjectCards(this.workHoursList);
                        // }
                        if (this.workHoursList) {
                            var groupByProject = [];
                            groupByProject = this.workHoursList.map((m) => m.projectId).filter((f, i, ar) => ar.indexOf(f) === i);
                            if (groupByProject.length > 0) {
                                for (var i = 0; i < groupByProject.length; i++) {
                                    var list = this.workHoursList.filter(o => o.projectId == groupByProject[i]);
                                    if (list) {
                                        list.filter(it => {
                                            it.EventColor = colors[i];
                                        });
                                    }
                                }
                            }

                            if (response.result.nonWorkHoursList && response.result.nonWorkHoursList.length > 0) {
                                var groupByInquiry = [];
                                groupByInquiry = response.result.nonWorkHoursList.map((m) => m.inquiryId).filter((f, i, ar) => ar.indexOf(f) === i);
                                if (groupByInquiry.length > 0) {
                                    for (var i = 0; i < groupByInquiry.length; i++) {
                                        var list = response.result.nonWorkHoursList.filter(o => o.inquiryId == groupByInquiry[i]);
                                        if (list) {
                                            list.filter(it => {
                                                it.EventColor = colors[i];
                                            });
                                        }
                                    }
                                }
                                this.workHoursList = this.workHoursList.concat(response.result.nonWorkHoursList.filter(it => it.status != 0));
                            }
                        }
                        else {
                            var groupByInquiry = [];
                            groupByInquiry = response.result.nonWorkHoursList.map((m) => m.inquiryId).filter((f, i, ar) => ar.indexOf(f) === i);
                            if (groupByInquiry.length > 0) {
                                for (var i = 0; i < groupByInquiry.length; i++) {
                                    var list = response.result.nonWorkHoursList.filter(o => o.inquiryId == groupByInquiry[i]);
                                    if (list) {
                                        list.filter(it => {
                                            it.EventColor = colors[i];
                                        });
                                    }
                                }
                            }
                            if (response.result.nonWorkHoursList && response.result.nonWorkHoursList.length > 0)
                                this.workHoursList = response.result.nonWorkHoursList.filter(it => it.status != 0);
                        }


                        var tempId = 0;
                        this.workHoursList.filter(it => {
                            var date = new Date(moment(it.date).format("YYYY-MM-DD"));
                            var day = date.getDate();
                            var month = date.getMonth();
                            var year = date.getFullYear();
                            var startHour = moment(it.from, "HH:mm").format("HH");
                            var startTime = moment(it.from, "HH:mm").format("mm");
                            // if (startTime != '00') {
                            //   var startmin: any;
                            //   startmin = startTime;
                            //   startmin = ((startmin * 100) / 60);
                            //   startTime = startmin.toString();
                            // }
                            var endHour = moment(it.to, "HH:mm").format("HH");
                            var endTime = moment(it.to, "HH:mm").format("mm");
                            // if (endTime != '00') {
                            //   var endmin: any;
                            //   endmin = endTime;
                            //   endmin = ((endmin * 100) / 60);
                            //   endTime = endmin.toString();
                            // }
                            it.CowrkerId = it.workerId;
                            if (it.isWorking) {
                                //it.Location = '<br/>Newyork City';
                                if (it.inquiryId == undefined || it.inquiryId == "" || it.inquiryId == null) {
                                    it.Subject = it.projectTitle + "<br />";//it.sagNummer ? it.sagNummer +  (it.opgaveTekst ? " - "+ it.opgaveTekst:"") : it.opgaveTekst;
                                    if (it.lift == 1) {
                                        it.Subject += "<img style='right: 40%;position: absolute;top: 32px;height: 30px;width: 30px;'src='assets/images/lift/lift.png'>";
                                    }
                                    if (it.isNotifiedToTheCustomer) {
                                        it.Subject += "<img style='right: 55%;position: absolute;top: 32px;height: 30px;width: 30px;'src='assets/images/tickMark/tick.png'>"
                                    }
                                    if (it.hoursBack != null) {
                                        it.Subject += "Timer tilbage:" + it.hoursBack; + "&nbsp;";
                                    }
                                    if (it.plannedTime) {
                                        // if (it.otherInfoForMobile) {
                                        //     var startDate = moment(moment(it.otherInfoForMobile.startDate).format('YYYY/MM/DD'));
                                        //     var endDate = moment(moment(it.otherInfoForMobile.endDate).format('YYYY/MM/DD'));
                                        //     it.days = Math.abs(startDate.diff(endDate, 'days'));
                                        // }
                                        it.Subject += "&nbsp;" + "Planlagt: " + it.plannedTime + "&nbsp;";
                                    }

                                    // if (it.estimatedTime != null && it.plannedTime != null) {
                                    //     if (it.hoursBack && it.plannedTime) {
                                    //         it.Subject += "<br />";
                                    //     }
                                    //     var remainingTime = ~~(it.estimatedTime - it.plannedTime);
                                    //     it.Subject += "Resterende tid: " + remainingTime + "&nbsp;";
                                    // }

                                    //const n: number = Math.floor(Math.random() * colors.length);
                                    if (it.timerTilbage != undefined && it.timerTilbage != null && it.timerTilbage <= 0) {
                                        it.EventColor = "#e3165b";
                                    }
                                    if (it.isHourlyWage) {
                                        it.EventColor = "#FF0000";
                                        it.Subject += "Timeløn";
                                    }
                                    if (it.requisitionStatus == 9)
                                        it.EventColor = "#008000";
                                }
                                else {
                                    it.Subject = it.inquiryNumber + " - " + it.inquiryName;
                                    it.Subject += (it.inquiryAddress ? "<br />" + it.inquiryAddress : "") + " " + (it.inquiryPostNumber ? it.inquiryPostNumber : "") + " " + (it.inquiryCity ? it.inquiryCity : "");
                                    it.Subject += (it.text ? "<br /> " + it.text : "");
                                    it.Subject += "<mat-icon class='nav-link-icon mat-icon notranslate material-icons mat-icon-no-color' role='img' style='color: rgb(0, 0, 0, 0.87);right: 0px;position: absolute;top: 35px;height: 30px;width: 30px;'>phone_in_talk</mat-icon>"
                                }
                                if (it.projectIsActive) {
                                    it.Subject += "<br /> <button style='position: absolute;top: 35px;height: 28px;width: 30px;background: transparent;border: none;margin-left: 58%;' onclick='window.projectComponent.zone.run(($event) =>{ window.projectComponent.component.gotoMaterial();})' type='button'><i class='nav-link-icon mat-icon notranslate material-icons mat-icon-no-color' style='color: rgb(255, 102, 36);'>invert_colors</mat-icon></button>"
                                    it.Subject += "<button style='position: absolute;top: 35px;height: 28px;width: 30px;background: transparent;border: none;margin-left: 45%;' onclick='window.projectComponent.zone.run(($event) =>{ window.projectComponent.component.gotoMap();})' type='button'><i class='nav-link-icon mat-icon notranslate material-icons mat-icon-no-color' style='color: rgb(255, 102, 36);'>location_on</mat-icon></button>"
                                }
                                if (it.isAddedWorkHours)
                                    it.Subject += "<mat-icon class='nav-link-icon mat-icon notranslate material-icons mat-icon-no-color' role='img' style='color: rgb(170, 103, 29);right: 0px;position: absolute;top: 35px;height: 30px;width: 30px;'>access_time</mat-icon>"

                            }
                            else {
                                var taskStatus = ""
                                if (it.leaveStatus == 1)
                                    taskStatus = "Ansøge";

                                else if (it.leaveStatus == 2)
                                    taskStatus = "Godkendt";

                                else if (it.leaveStatus == 3)
                                    taskStatus = "Afvist";

                                if (it.inquiryId == undefined || it.inquiryId == "" || it.inquiryId == null) {
                                    if (it.status == 8)
                                        it.Subject = it.text ? it.text : "&nbsp;" + taskStatus + (it.plannedTime != null ? it.plannedTime : "");
                                    else
                                        it.Subject = it.text ? this.getNonWorkHoursStatus(it.status, it.leaveStatus) + " - " + it.text : this.getNonWorkHoursStatus(it.status, it.isApproved) + "&nbsp;" + taskStatus + (it.plannedTime != null ? it.plannedTime : "");
                                    //it.Subject = this.getNonWorkHoursStatus(it.status, it.isApproved);
                                    // if (it.leaveStatus == 1) {
                                    //   it.Location = "<br/>Ansøge";
                                    // }
                                    // else if (it.leaveStatus == 2) {
                                    //   it.Location = "<br/>Godkendt";
                                    // }
                                    // else if (it.leaveStatus == 3) {
                                    //   it.Location = "<br/>Afvist";
                                    // }
                                    if (it.status == 8) {
                                        it.EventColor = "#222223"
                                    } else {
                                        it.EventColor = "#e53935";
                                    }
                                }
                                else {
                                    it.Subject = it.inquiryNumber + " - " + it.inquiryName;
                                    it.Subject += (it.inquiryAddress ? "<br />" + it.inquiryAddress : "") + " " + (it.inquiryPostNumber ? it.inquiryPostNumber : "") + " " + (it.inquiryCity ? it.inquiryCity : "");
                                    it.Subject += (it.text ? "<br /> " + it.text : "");
                                    it.Subject += "<mat-icon class='nav-link-icon mat-icon notranslate material-icons mat-icon-no-color' role='img' style='color: rgb(0, 0, 0, 0.87);right: 0px;position: absolute;top: 35px;height: 30px;width: 30px;'>phone_in_talk</mat-icon>"
                                }
                            }

                            tempId = tempId + 1;
                            it.Id = tempId;
                            it.StartTime = new Date(year, month, day, parseInt(startHour), parseInt(startTime));
                            it.EndTime = new Date(year, month, day, parseInt(endHour), parseInt(endTime));
                        });
                        this.eventSettings.dataSource = this.workHoursList;
                    }
                    var selectedCoworkerList = [];


                    let startDate: Date;
                    let endDate: Date;
                    if(this.requestResourceWorkHours && this.requestResourceWorkHours.Search)
                        this.selectedDate = new Date(this.workHoursList[0].date);
                    if (this.currentView == "TimelineMonth") {
                        var sdata = moment(new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), 1));
                        startDate = sdata["_d"];
                        var edate = moment(startDate).endOf('month')
                        endDate = edate["_d"];
                        //endDate= new Date(moment(startDate).endOf('month'));
                    }
                    else {
                        startDate = getWeekFirstDate(this.selectedDate, this.weekFirstDay as number);;
                        endDate = addDays(new Date(startDate.getTime()), 6);
                    }
                    startDate.setHours(0, 0, 0, 0);
                    endDate.setHours(0, 0, 0, 0);
                    var resultWorkingHoursData = this.workHoursList.filter(function (a) {
                        return new Date(a.date) >= startDate && new Date(a.date) <= endDate
                    });
                    // var resultNonworkingHoursData = this.workHoursList.filter(function (a) {
                    //     return a.isWorking == false && new Date(a.date) >= startDate && new Date(a.date) <= endDate
                    // });
                    for (var i = 0; i < this.selectedcoWorkerIds.length; i++) {
                        if (this.selectedcoWorkerIds[i] != "0") {
                            var coworker = this.coWokerList.find(o => o.id == this.selectedcoWorkerIds[i]);
                            if (coworker) {
                                var workhourscal = resultWorkingHoursData.filter(it => it.CowrkerId == this.selectedcoWorkerIds[i]).reduce((sum, current) => sum + current.hours, 0);
                                //var nonworkhourscal = resultNonworkingHoursData.filter(it => it.CowrkerId == this.selectedcoWorkerIds[i]).reduce((sum, current) => sum + current.hours, 0);
                                coworker.timer = workhourscal > 0 ? workhourscal : '';
                                //coworker.nonworkingTimer = nonworkhourscal > 0 ? nonworkhourscal : '';
                                selectedCoworkerList.push(coworker);
                            }
                        }
                    }
                    this.resourceDataSource = this.sortPipe.transform(selectedCoworkerList, "asc", "name");
                    this.showSpinner = false;
                }
            },
                error => (err) => { },
                () => {
                    this.showSpinner = false;
                });
    }

    viewSelectedWorkingHour() {
        //this.deleteEventOnScheduleObj();
        const firstDayOfWeek: Date = getWeekFirstDate(new Date(this.requestResourceWorkHours.StartDate), this.weekFirstDay as number);
        let startDate: Date = firstDayOfWeek;
        const endDate: Date = addDays(new Date(startDate.getTime()), 6);
        //var request = new RequestResourceWorkHours();
        this.requestResourceWorkHours.StartDate = moment(startDate).format("YYYY-MM-DD");
        this.requestResourceWorkHours.EndDate = moment(endDate).format("YYYY-MM-DD");
        //if ((moment(startDate).format("YYYY-MM") != moment(new Date()).format("YYYY-MM")) || (moment(endDate).format("YYYY-MM") != moment(new Date()).format("YYYY-MM"))) {
        this.showSpinner = true;
        var tempEventData = [];
        if (this.scheduleObj) {
            tempEventData = this.scheduleObj.eventsData;
            if (tempEventData.length > 0) {
                tempEventData.filter(item1 => {
                    this.scheduleObj.deleteEvent(item1.Id);
                })
            }
        }

        this.requestResourceWorkHours.WorkingStatus = this.workHourType;
        this.requestResourceWorkHours.NonWorkingStatus = this.selectedNonworkingStatus;
        this.getCalenderData(this.requestResourceWorkHours);
    }

    deleteEventOnScheduleObj() {
        this.showSpinner = true;
        var tempEventData = [];
        if (this.scheduleObj) {
            tempEventData = this.scheduleObj.eventsData;
            if (tempEventData.length > 0) {
                tempEventData.filter(item1 => {
                    this.scheduleObj.deleteEvent(item1.Id);
                })
            }
        }
        this.showSpinner = false;
    }

    getNonWorkHoursStatus(val, approved) {
        // var status = "Verserende";
        // if (approved == 2) {
        //     status = "Godkendt";
        // }
        // else if (approved == 3) {
        //     status = "Afvist";
        // }
        if (val == 1)
            return status + 'Ferie';
        if (val == 2)
            return status + 'Syg';
        if (val == 3)
            return status + 'Feriefridag';
        if (val == 4)
            return status + 'Fridag m.løn';
        if (val == 5)
            return status + 'Fridag u.løn';
        if (val == 6)
            return status + 'Kursus/skole';
        if (val == 7)
            return status + 'Afspadsering';
        if (val == 8)
            return status + 'Andet';
        if (val == 9)
            return status + 'Tilbud';
        //if (val == 1)
        //    return 'selectedWorkHourStatus.value1';
        //if (val == 2)
        //    return 'selectedWorkHourStatus.value2';
        //if (val == 3)
        //    return 'selectedWorkHourStatus.value3';
        //if (val == 4)
        //    return 'selectedWorkHourStatus.value4';
        //if (val == 5)
        //    return 'selectedWorkHourStatus.value5';
        //if (val == 6)
        //    return 'selectedWorkHourStatus.value6';
        //if (val == 7)
        //    return 'selectedWorkHourStatus.value7';
        //if (val == 8)
        //    return 'selectedWorkHourStatus.value8';
        //if (val == 9)
        //    return 'selectedWorkHourStatus.value9';
    }

    oneventRendered(args: EventRenderedArgs): void {
        const eventColor: string = args.data.EventColor as string;
        if (!args.element || !eventColor) {
            return;
        } else {
            args.element.style.backgroundColor = eventColor;
            if (args.data.isWorking) {
                if (args.data.projectIsActive)
                    args.element.className = args.element.className + " working indoorOutdoorStatusId_" + (args.data.requisitionStatus != 9 ? args.data.indoorOutdoorStatusId : "");
                else {
                    if (!args.data.inquiryId)
                        args.element.className = args.element.className + " working indoorOutdoorStatusId_InActive";
                }
            }
            else {
                if (!args.data.inquiryId)
                    args.element.className = args.element.className + " nonworking";
            }
        }
    }

    onNavigation(args) {
        setTimeout(() => {
            if (args.action == "view") {
                this.currentView = args.currentView;
                this.calculateWorkHors();
            }
            if ((args.name == "navigating")) {
                if (args.currentDate) {
                    const firstDayOfWeek: Date = getWeekFirstDate(args.currentDate, this.weekFirstDay as number);
                    let startDate: Date = firstDayOfWeek;
                    const endDate: Date = addDays(new Date(startDate.getTime()), 6);
                    //var request = new RequestResourceWorkHours();
                    this.requestResourceWorkHours.StartDate = moment(startDate).format("YYYY-MM-DD");
                    this.requestResourceWorkHours.EndDate = moment(endDate).format("YYYY-MM-DD");
                    this.selectedDate = args.currentDate;
                    this.selectedWeekNumber = moment(startDate).week().toString();
                    //if ((moment(startDate).format("YYYY-MM") != moment(new Date()).format("YYYY-MM")) || (moment(endDate).format("YYYY-MM") != moment(new Date()).format("YYYY-MM"))) {
                    this.showSpinner = true;
                    var tempEventData = [];
                    if (this.scheduleObj) {
                        tempEventData = this.scheduleObj.eventsData;
                        if (tempEventData.length > 0) {
                            tempEventData.filter(item1 => {
                                this.scheduleObj.deleteEvent(item1.Id);
                            })
                        }
                    }
                    this.requestResourceWorkHours.WorkingStatus = this.workHourType;
                    this.getCalenderData(this.requestResourceWorkHours);
                    //}
                }
                // else {
                //   var date = new Date(this.requestResourceWorkHours.StartDate);
                //   var FirstDayOfMonth = firstDateOfMonth(date);
                //   const endDate: Date = addDays(new Date(FirstDayOfMonth.getTime()), 6);
                //   this.requestResourceWorkHours.StartDate = moment(FirstDayOfMonth).format("YYYY-MM-DD");
                //   this.requestResourceWorkHours.EndDate = moment(endDate).format("YYYY-MM-DD");
                //   this.selectedWeekNumber = moment(FirstDayOfMonth).week().toString();
                //   var tempEventData = [];
                //   if (this.scheduleObj) {
                //     tempEventData = this.scheduleObj.eventsData;
                //     if (tempEventData.length > 0) {
                //       tempEventData.filter(item1 => {
                //         this.scheduleObj.deleteEvent(item1.Id);
                //       });
                //     }
                //   }
                //   this.getCalenderData(this.requestResourceWorkHours);
                // }
            }
        });
    }

    getEventDetails(data: Object) {
        return (this.instance.formatDate(new Date(data['StartTime']), { type: 'date', format: 'dd.MM.yyyy' }) +
            '(' + this.getString(new Date(data['StartTime']), 'Hm') + '-' + this.getString(new Date(data['EndTime']), 'Hm') + ')');
    }

    getString(value: Date, type: string) {
        return this.instance.formatDate(new Date(value), { type: 'dateTime', skeleton: type });
    }

    getBackGroundColor(data: any) {
        let color: string;
        let fontColor: string;
        fontColor = '#FFFFFF';
        color = data.EventColor;
        return { 'background-color': color, color: fontColor };
    }

    getHoliday: Function = (value: Date) => {
        var date = moment(value).format("DD/MM/YYYY")
        if (this.holidayList != null && this.holidayList.length > 0) {
            var result = this.holidayList.filter(item => {
                var holidayDate = moment(item.date).format("DD/MM/YYYY");
                if (holidayDate == date)
                    return item;
                else
                    return "";
            })
            if (result && result.length > 0)
                return '<div style=" background-color: #e53935;font-size: 13px;font-weight: 500;min-height: 30px;overflow: hidden;padding-top: 4px;text-overflow: ellipsis;color: #fff;cursor: default;padding: 4px 4px;">' + result[0].localName + '</div>';
            else
                return "";
        }
    }

    onRenderCell(args: RenderCellEventArgs): void {
        if (args.elementType === 'emptyCells' && args.element.classList.contains('e-resource-left-td')) {
            let target: HTMLElement = args.element.querySelector('.e-resource-text') as HTMLElement;
            target.innerHTML = '<div class="name">Medarbejder</div>';
        }
        if (args.elementType === 'workCells' || args.elementType === 'monthCells') {
            let ele: Element = document.createElement('div');
            ele.innerHTML = this.getHoliday(args.date);
            if (ele.innerHTML && ele.innerHTML != "undefined")
                (args.element).appendChild(ele.firstChild);
            if (args.elementType == 'monthCells') {
                this.showWeekend = false;
            } else {
                this.showWeekend = true;
            }
        }
    }

    onActionBegin(args: ActionEventArgs & ToolbarActionArgs) {
        if (args.requestType === 'eventChange') {
            var currentDate = moment(new Date()).format("YYYY-MM-DD");
            var changeRecord = JSON.parse(JSON.stringify(args.changedRecords[0]));

            var IsHoliday = this.getHoliday(changeRecord.StartTime);
            var days = moment(changeRecord.StartTime).day();
            var isPastDate = moment(changeRecord.StartTime).isBefore(currentDate);
            if (isPastDate) {
                this.translation.get("Message.PreventMoveToPastdays").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                        this.commonService.playFailedTone();
                        args.cancel = true;
                    },
                    error: err => {
                    }
                });
                return false;
            }
            if (IsHoliday) {
                this.translation.get("Message.PreventMoveToHolidays").subscribe({
                    next: (res) => {
                        this.notificationsService.error(res);
                        this.commonService.playFailedTone();
                        args.cancel = true;
                    },
                    error: err => {
                    }
                });
            }
            else {
                this.showSpinner = true;
                var nonWorkingHoursDates = ""
                var message = this.createTranslationMessage(changeRecord);
                changeRecord.Id = changeRecord.id;
                changeRecord.date = changeRecord.StartTime;
                changeRecord.workerId = changeRecord.CowrkerId;
                changeRecord.projectNumber = changeRecord.sagNummer;
                if (!changeRecord.isWorking) {
                    nonWorkingHoursDates = changeRecord.StartTime;
                }
                var buchIdForMoveRecords = "";
                var otherInfoForModeRecord = JSON.parse(changeRecord.otherInfo);
                buchIdForMoveRecords = otherInfoForModeRecord.BunchId;
                var selectedRecoredsForMove = [];
                for (var i = 0; i < this.workHoursList.length; i++) {
                    var curOtherInfoForModeRecord = JSON.parse(this.workHoursList[i].otherInfo);
                    var curBuchIdForMoveRecords = curOtherInfoForModeRecord.BunchId;
                    if (curBuchIdForMoveRecords == buchIdForMoveRecords) {
                        selectedRecoredsForMove.push(this.workHoursList[i]);
                    }
                }

                const dialogRefForMove = this.dialog.open(MovePlanningMessageModelComponent, {
                    data: {
                        Header: "Headers.MoveScheduleOption",
                        FirstButtonLabel: "Headers.AllSchedule",
                        SecondButtonLabel: "Headers.OneDay",
                        isNonWorking : changeRecord.isWorking
                    }
                });

                dialogRefForMove.afterClosed().subscribe(resultForMove => {
                    if (resultForMove == 1 || resultForMove == 2) {
                        //Confirm proj move start
                        const dialogRef = this.dialog.open(MovePlanningWithDateRangeComponent, {
                            data: {
                                Header: message,
                                PlanningDates: JSON.parse(changeRecord.otherInfo).PlanningDates,
                                DefaultSelectedDate: args.data["date"],//changeRecord.date,
                                NonWorkingHoursDates: nonWorkingHoursDates,
                                MoveScheduleOption: resultForMove,
                            }
                        });

                        dialogRef.afterClosed().subscribe(result => {
                            if (result) {
                                var daysResult = [];
                                if (resultForMove == 1)
                                    daysResult = result.dateList.filter(o => o.isChecked == true).map(p => moment(p.date).format("YYYY-MM-DD"));
                                else
                                    daysResult.push(moment(args.data["StartTime"]).format("YYYY-MM-DD"))
                                if (result.value == "move") {
                                    var moveObj = {
                                        "id": changeRecord.id,
                                        "MoveDates": daysResult,//result.dateList.filter(o => o.isChecked == true).map(p => moment(p.date).format("YYYY-MM-DD")),
                                        "MoveWorkerID": changeRecord.workerId
                                    }
                                    this.moveCoworkerPlan(moveObj);
                                } else if (result.value == "copy") {
                                    var copyObj = {
                                        "Id": changeRecord.id,
                                        "CopyDates": daysResult, //result.dateList.filter(o => o.isChecked == true).map(p => moment(p.date,"DD.MM.YYYY").format("YYYY-MM-DD")),
                                        "CopyWorkerID": changeRecord.workerId
                                    }
                                    this.CopyCoworkerPlan(copyObj);
                                }
                                this.showSpinner = false;
                            } else {
                                this.moveToScreen({ updateSchedule: true });
                                this.showSpinner = false;
                            }
                        });
                    }
                    else {
                        this.moveToScreen({ updateSchedule: true });
                        this.showSpinner = false;
                    }

                });


            }
        }
        if (args.requestType === 'toolbarItemRendering') {
            //args.items[2].click = this.onCalendarCreated.bind(this);
            if (Browser.isDevice) {
            } else {
                const specialistItem: ItemModel = { align: 'Center', cssClass: 'app-doctors' };
                args.items.unshift(specialistItem);
                args.items.splice(4, 2);

                //const exportItem: ItemModel = {
                //    align: 'Right', showTextOn: 'Both', prefixIcon: '',
                //    text: 'Print', cssClass: 'e-print', click: this.onPrintIconClick.bind(this)
                //};
                //args.items.push(exportItem);
            }
        }
    }

    createTranslationMessage(data) {
        var MESSAGE = "";
        var MovePlan = "";
        var From = "";
        var To = "";
        //moment.locale("da");
        //var FromDate = moment(data.date).format("DD.MM dddd") //this.datePipe.transform(data.date, "dd.MM EEEE");
        //var tempToDate =  moment(data.EndTime);
        //var ToDate = moment(data.EndTime).format("DD.MM dddd");//this.datePipe.transform(data.EndTime, "dd.MM EEEE");
        var CurrentCoworkerName = this.searchCoWokerList.find(o => o.id == data.workerId).name;
        var ChangedCoworkerName = this.searchCoWokerList.find(o => o.id == data.CowrkerId).name;
        if (data.isWorking) {
            this.translate.get("Message.MoveProject").subscribe({
                next: (res) => {
                    MovePlan = res

                }, error: err => { }
            });
        }
        else {
            this.translate.get("Message.MoveLeave").subscribe({
                next: (res) => {
                    MovePlan = res

                }, error: err => { }
            });
        }

        this.translate.get("Message.From").subscribe({
            next: (res) => {
                From = res

            }, error: err => { }
        });

        this.translate.get("Message.To").subscribe({
            next: (res) => {
                To = res

            }, error: err => { }
        });
        MESSAGE = MovePlan + " " + (data.isWorking ? data.projectTitle : data.Subject) + " " + From + " " +
            CurrentCoworkerName + " " + To + " " + ChangedCoworkerName
        return MESSAGE;
    }

    moveCoworkerPlan(changeRecord) {
        this.workhoursService.MovePlanWithDateRange<ResponseBase>(changeRecord)
            .subscribe({
                next: (response: ResponseBase) => {
                    var result = {
                        updateSchedule: true,
                    }
                    this.moveToScreen(result);
                    this.translation.get("Message.MovedSuccessfully").subscribe({
                        next: (res) => {
                            this.notificationsService.success(res);
                        },
                        error: err => {
                        }
                    });
                    if(result && changeRecord){
                        changeRecord.tabId = this.tabId;
                        changeRecord.startDate = changeRecord[0];
                        this.sendPlanningMessage(JSON.stringify([{...changeRecord}]))
                    }

                },
                error: err => {
                    this.translation.get("Message.CopyError").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                            this.commonService.playFailedTone();
                            var result = {
                                updateSchedule: true,
                            }
                            this.moveToScreen(result);
                            //args.cancel = true;
                        },
                        error: err => {
                            // args.cancel = true;
                        }
                    });
                },
                complete: () => {
                }
            });
    }

    onActionComplete(args: ActionEventArgs): void {
        //if (args.requestType === 'eventChanged')
        if (args.requestType === 'toolBarItemRendered') {
            if (Browser.isDevice) {
            } else {
                const doctorsElement: HTMLElement = this.scheduleObj.element.querySelector('.app-doctors') as HTMLElement;
                const listObj: DropDownList = new DropDownList({
                    cssClass: 'planner-dropdown',
                    placeholder: 'Uges nummer',
                    dataSource: this.weekNumbers,
                    fields: { text: 'text', value: 'value' },
                    popupHeight: '300px',
                    popupWidth: '115px',
                    showClearButton: false,
                    change: this.onWeekSelect.bind(this),
                    itemTemplate: '<div class="specialist-item"><div class="doctor-details"><div class="name">${value}</div></div></div>',
                    width: '115px',
                    value: this.selectedWeekNumber

                });
                listObj.appendTo(doctorsElement);
            }
        }
    }

    onPopupOpen(args: PopupOpenEventArgs): void {
        args.cancel = true;
        if (!this.isShowMap) {
            if (args.type === 'QuickInfo') {
                if (args.data) {
                    var data = JSON.parse(JSON.stringify(args.data));
                    this.coworkerDetailForDoubleClick = data;
                    var currentDate = moment(data.startTime).format("DD/MM/YYYY");
                    if (data.EventColor == undefined || data.EventColor == "") {
                        //var result = this.holidayList.filter(item => {
                        //    var holidayDate = moment(item.date).format("DD/MM/YYYY");
                        //    if (holidayDate == currentDate)
                        //        return item;
                        //    else
                        //        return "";
                        //});
                        //if (result.length == 0) {
                        //    const dialogRef = this.dialog.open(CalenderWorkingHourPopupComponent, {
                        //        height: '70%',
                        //        width: '70%',
                        //    });
                        //} else {
                        //    return;
                        //}
                        return;
                    }
                    else {
                        var shiftType = args.data["shiftType"];
                        var startHour = "07";
                        var startMinute = "00";
                        var endHour = "07";
                        var endMinute = "00";
                        var isPauseTime = false;
                        if (shiftType == 4) {
                            startHour = args.data["from"].toString().split(':')[0];
                            startMinute = args.data["from"].toString().split(':')[1];
                            endHour = args.data["to"].toString().split(':')[0];
                            endMinute = args.data["to"].toString().split(':')[1];
                            isPauseTime = args.data["pauseTimer"] > 0 ? true : false;
                        }
                        var projectInfo = {
                            sagNumber: args.data["sagNummer"],
                            projectTitle: args.data["projectTitle"],
                            startDate: args.data["date"],
                            endDate: args.data["date"],
                            sagId: args.data["projectId"],
                            coworkerId: args.data["workerId"],
                            shiftType: args.data["shiftType"],
                            workHourId: args.data["id"],
                            startHour,
                            startMinute,
                            endHour,
                            endMinute,
                            isPauseTime,
                            status: args.data["status"],
                            text: args.data["text"],
                            sagOpgaveID: args.data["sagOpgaveID"],
                            indoorOutdoorStatusId: args.data["indoorOutdoorStatusId"],
                            urgentOrNot: args.data["urgentOrNot"],
                            visit: args.data["visit"],
                            leaveStatus: args.data["leaveStatus"],
                            comment: args.data["comment"],
                            otherInfo: args.data["otherInfo"],
                            inquiryId: args.data["inquiryId"],
                            inquiryName: args.data["inquiryName"],
                            inquiryNumber: args.data["inquiryNumber"],
                            inquiryAddress: args.data["inquiryAddress"],
                            inquiryPostNumber: args.data["inquiryPostNumber"],
                            inquiryCity: args.data["inquiryCity"],
                            inquiryEmail: args.data["inquiryEmail"],
                            inquiryIndorOuterDoreStatus: args.data["inquiryIndorOuterDoreStatus"],
                            inquiryCoworkerComment: args.data["inquiryCoworkerComment"],
                            doNotSendNotification: args.data["doNotSendNotification"],
                            isNotifiedToTheCustomer: args.data["isNotifiedToTheCustomer"],
                            sagOpgaveNummer: args.data["sagOpgaveNummer"],
                            paymentOption: args.data["paymentOption"],
                            projectBasedPlanning: args.data["projectBasedPlanning"]
                        };

                        var paramData = {
                            projectInfo: projectInfo,
                            coworkerList: this.coWokerList,
                            isComingFromEditMode: true,
                            isGotoMaterial: this.isGotoMaterial
                        }

                        if (args.data["isWorking"]) {
                            if (args.data["inquiryId"] != undefined) {
                                var paramDataForInquiry = {
                                    inquiryInfo: projectInfo,
                                    coworkerList: this.coWokerList,
                                    holidayList: this.holidayList,
                                    isComingFromEditMode: true,
                                }
                                const dialogRef = this.dialog.open(CalenderInquiryWorkingHourPopupComponent, {
                                    height: '70%',
                                    width: '70%',
                                    data: paramDataForInquiry
                                });
                                dialogRef.afterClosed().subscribe(result => {
                                    if (result)
                                        this.moveToScreen(result);
                                    this.projectNumber = "";
                                    this.requisitionId = "";
                                    this.getCalenderData(this.requestResourceWorkHours);
                                });
                            }
                            else {
                                // if(data.projectIsActive){
                                const dialogRef = this.dialog.open(CalenderEditWorkingHourPopupComponent, {
                                    height: '90%',
                                    width: '90%',
                                    data: paramData
                                });
                                dialogRef.afterClosed().subscribe(result => {
                                    if (result)
                                        this.moveToScreen(result);
                                    else 
                                        this.moveToScreen({ updateSchedule: true });
                                    this.projectNumber = "";
                                    this.requisitionId = "";
                                    this.isGotoMaterial = false;
                                    //this.getCalenderData(this.requestResourceWorkHours);
                                    if(result && result.response){
                                        result.response[0].tabId = this.tabId;
                                        this.sendPlanningMessage(JSON.stringify(result.response))
                                    }
                                });
                                // }
                            }
                        }
                        else {
                            if (args.data["inquiryId"] != undefined) {
                                var paramDataForInquiry = {
                                    inquiryInfo: projectInfo,
                                    coworkerList: this.coWokerList,
                                    holidayList: this.holidayList,
                                    isComingFromEditMode: true,
                                }
                                const dialogRef = this.dialog.open(CalenderInquiryWorkingHourPopupComponent, {
                                    height: '70%',
                                    width: '70%',
                                    data: paramDataForInquiry
                                });
                                dialogRef.afterClosed().subscribe(result => {
                                    if (result)
                                        this.moveToScreen(result);
                                });
                            }
                            else {
                                const dialogRef = this.dialog.open(CalenderEditNonWorkingHourPopupComponent, {
                                    height: '90%',
                                    width: '70%',
                                    data: paramData
                                });
                                dialogRef.afterClosed().subscribe(result => {
                                    if (result)
                                        this.moveToScreen(result);
                                    if(result && result.deleteNonWorkingItems){
                                        result.deleteNonWorkingItems.tabId = this.tabId;
                                        result.deleteNonWorkingItems.startDate = paramData.projectInfo.startDate;
                                        this.sendPlanningMessage(JSON.stringify([{...result.deleteNonWorkingItems}]))
                                    }
                                });
                            }
                        }
                    }
                }
            }
            else if (args.type === 'EventContainer') {
            }
        }
        else {
            this.showDirection(args.data)
        }
        this.isShowMap = false;
    }

    moveToScreen(result) {
        if (result.weekNumber) {
            //let tempStartDate: Date = new Date(moment().week(result.weekNumber || 1).startOf("week").format("YYYY-MM-DD"));
            //let tempStartDate: Date = new Date(moment().year(result.dataYear || moment(new Date()).year().toString()).week(result.weekNumber || 1).startOf("week").add("d",1).format("YYYY-MM-DD"));
            let tempStartDate: Date = new Date(moment().year(result.dataYear || moment(new Date()).year().toString()).week(result.weekNumber || 1).startOf("week").format("YYYY-MM-DD"));
            this.selectedWeekNumber = result.weekNumber;
            this.selectedDate = tempStartDate;
            const endDate: Date = addDays(new Date(tempStartDate.getTime()), 6);
            this.requestResourceWorkHours.StartDate = moment(tempStartDate).format("YYYY-MM-DD");
            this.requestResourceWorkHours.EndDate = moment(endDate).format("YYYY-MM-DD");
        }
        if (result.updateSchedule) {
            this.workHourType = 1;
            this.popupCloseUpdateSchedule();
            // setTimeout(() => {
            //     this.getWorkingHourDetailsForPlanning(this.workingHourDetailsForPlanning.noOfEmployee);
            // }, 200);
        }
        else if (result.deleteSchedule) {
            this.workHourType = 1;
            this.deleteNonWorkingHourWithDateSlection(result.deleteNonWorkingItems);
            //this.deleteWorkHour(result.workHourId);
            // setTimeout(() => {
            //     this.getWorkingHourDetailsForPlanning(this.workingHourDetailsForPlanning.noOfEmployee);
            // }, 200);
        }
        else if (result.goToProject) {
            this.goToProject(result.projectId);
        }
    }

    goToProject(id) {
        this._router.navigate(['/projects/edit', id]);
    }

    deleteWorkHour(WorkHoursId) {
        this.showSpinner = true;
        this.workhoursService.DeleteForPlanning<ResponseBase>(WorkHoursId)
            .subscribe({
                next: (response: ResponseBase) => {
                    //this.scheduleObj.deleteEvent(Id)
                    var tempEventData = [];
                    if (this.scheduleObj) {
                        tempEventData = this.scheduleObj.eventsData;
                        if (tempEventData.length > 0) {
                            tempEventData.filter(item1 => {
                                this.scheduleObj.deleteEvent(item1.Id);
                            })
                        }
                    }
                    this.getCalenderData(this.requestResourceWorkHours);
                },
                error: err => { },
                complete: () => {

                }
            });
    }

    createEvent(data, value) {
        //this.drawerObj.toggle();
        if (data) {
            data.projectTitle = data.sagNumber + ' - ' + (data.address1 ? data.address1 : '') + (data.zipCode ? ' ' + data.zipCode : '') + (data.city ? ' ' + data.city : '');;
            data.shiftType = 3;
            data.indoorOutdoorStatusId = data.indoorOutdoorStatusId;
            data.urgentOrNot = data.urgentOrNot;
            data.visit = data.visit;
            data.leaveStatus = true;
            if (data.sagNumber == this.projectNumber) {
                data.sagOpgaveID = this.requisitionId;
            }
            var paramData = {
                projectInfo: data,
                coworkerList: this.coWokerList,
                holidayList: this.holidayList,
                isComingFromEditMode: false,
                projectWithWorkHoursList: this.scheduleObj.eventsData,
                defaultMaterialList: this.defaultMaterialList
            }
            const dialogRef = this.dialog.open(CalenderWorkingHourPopupComponent, {
                height: '90%',
                width: '90%',
                data: paramData
            });
            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    localStorage.removeItem("doubleClickCalanderDetail");
                    this.moveToScreen(result);
                    if (result.response){
                        result.response[0].tabId = this.tabId;
                        this.sendPlanningMessage(JSON.stringify(result.response))
                    }
                    this.refreshProjectList();
                }
                else
                    this.moveToScreen({ updateSchedule: true });
                this.projectNumber = "";
                this.requisitionId = "";
                localStorage.removeItem("doubleClickCalanderDetail");
            });
        }
        else {
            data = {};
            data.startDate = value ? value.startTime : new Date();
            data.endDate = value ? value.endTime : new Date();
            data.status = 1;
            data.shiftType = 2;
            // if (this.roleName == "Admin") {
            //     data.leaveStatus = true;
            // } else {
            //     data.leaveStatus = false;
            // }
            var paramData = {
                projectInfo: data,
                coworkerList: this.coWokerList,
                holidayList: this.holidayList,
                isComingFromEditMode: false,
                projectWithWorkHoursList: this.scheduleObj.eventsData,
                defaultMaterialList: this.defaultMaterialList
            }
            const dialogRef = this.dialog.open(CalenderAddNonWorkingHourPopupComponent, {
                height: '90%',
                width: '90%',
                data: paramData
            });
            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    localStorage.removeItem("doubleClickCalanderDetail");
                    this.moveToScreen(result)
                    if (result.response){
                        result.response[0].tabId = this.tabId;
                        this.sendPlanningMessage(JSON.stringify(result.response))
                    }
                    this.refreshProjectList();
                    
                } else {
                    localStorage.removeItem("doubleClickCalanderDetail");
                }
            });
        }
    }

    popupCloseUpdateSchedule() {
        this.showSpinner = true;
        var tempEventData = [];
        if (this.scheduleObj) {
            tempEventData = this.scheduleObj.eventsData;
            if (tempEventData.length > 0) {
                tempEventData.filter(item1 => {
                    this.scheduleObj.deleteEvent(item1.Id);
                })
            }
        }
        //this.getCalenderData(this.requestResourceWorkHours);
        let coWorker = new RequestCoWorker();
        coWorker.IsDeleted = false;
        this.getCoworkersWithHoliday(coWorker, false);
        this.drawerObj.close();
    }

    selectProjectEventHandler($event: any) {
        this.createEvent($event, null);
    }

    getTransator(value) {
        if (value == 1)
            return 'Lable.Yes'
        else
            return 'Lable.No'
    }

    getDateHeaderText: Function = (value: Date) => {
        // moment.locale("da");
        var tempDate = this.instance.formatDate(value, { type: 'date', format: 'dd.MMM' }) + ", " + moment(value).format('dddd');
        return tempDate;
    };

    // selectAllStatusForWorkHourselected() {
    //     if (this.allStatusSelectedForWorkHourselected.selected) {
    //         this.hourlyStatus = [];
    //         this.hourlyStatus.push(0);
    //         for (let i = 0; i < this.projectStatus.length; i++) {
    //             this.hourlyStatus.push(this.projectStatus[i].id);
    //         }
    //     }
    //     else {
    //         this.hourlyStatus = [];
    //     }
    //     this.getWorkingHourDetails();
    // }

    // selectStatusForWorkHourselected() {
    //     this.hourlyStatus = this.hourlyStatus.filter(o => o != 0);
    //     this.getWorkingHourDetails();
    // }

    // getWorkingHourDetails() {
    //     if (this.isShowWorkingHourDetail) {
    //         const statusStr = this.hourlyStatus.join().toString();
    //         this.projectservice.GetProjectsHoursDetailsWithStatusBase<ResponseBase>(this.workingHourDetails.noOfEmployee, statusStr, '0,1,2,3,4,5,6', this.workingHourDetails.averagePrice)
    //             .subscribe((response: ResponseBase) => {
    //                 if (response) {
    //                     this.workingHourDetails = response.result;
    //                     if (this.workingHourDetails && this.workingHourDetails.totalProjectDaysLeft) {
    //                         var days = Math.floor(this.workingHourDetails.totalProjectDaysLeft).toString().replace('-', '');
    //                         var endDate = momentBusiness(this.currentDate, 'YYYY-MM-DD').businessAdd(days)._d;
    //                         this.workingHourDetails.endDate = moment(endDate).format("DD.MM.YYYY")
    //                     }
    //                 }
    //             },
    //                 error => (err) => {
    //                 },
    //                 () => {
    //                 });
    //     }
    // }

    // getWorkingHourDetailsForPlanning(NoOfEmployee) {
    //     if (this.isShowWorkingHourDetail) {
    //         this.projectservice.GetProjectHoursDetailForPlanning<ResponseBase>(NoOfEmployee)
    //             .subscribe((response: ResponseBase) => {
    //                 if (response) {
    //                     this.workingHourDetailsForPlanning = response.result;
    //                     if (this.workingHourDetailsForPlanning && this.workingHourDetailsForPlanning.totalProjectDaysLeft) {
    //                         var days = Math.floor(this.workingHourDetailsForPlanning.totalProjectDaysLeft).toString().replace('-', '');
    //                         var endDate = momentBusiness(this.currentDate, 'YYYY-MM-DD').businessAdd(days)._d;
    //                         this.workingHourDetailsForPlanning.endDate = moment(endDate).format("DD.MM.YYYY")
    //                     }
    //                 }
    //             },
    //                 error => (err) => {
    //                 },
    //                 () => {
    //                 });
    //     }
    // }

    // DropDownNoOfEmployeeSelectForPlanning() {
    //     this.getWorkingHourDetailsForPlanning(this.workingHourDetailsForPlanning.noOfEmployee);
    // }

    onWeekSelect(args: any): void {
        if (args.value) {
            //var request = new RequestResourceWorkHours()
            // let endDate: Date = this.scheduleObj.activeView.endDate();
            // var weeknumber = moment(endDate, "MMDDYYYY").isoWeek();
            //alert(weeknumber);
            let tempStartDate: Date = new Date(moment().week(args.value || 1).startOf("week").add("d",1).format("YYYY-MM-DD"));
            //const endDate: Date = addDays(new Date(tempStartDate.getTime()), 4);
            //request.StartDate = moment().isoWeek(args.value || 1).startOf("week").format("YYYY-MM-DD");
            //request.EndDate = moment(endDate).format("YYYY-MM-DD");
            this.selectedWeekNumber = args.value;
            this.selectedDate = new Date(tempStartDate);
        }
    }

    addWeeksNumber() {
        this.weekNumbers = [];
        for (var i = 1; i < 54; i++) {
            this.weekNumbers.push({ value: i.toString(), text: 'Uge nr. - ' + i.toString() });
        }
    }

    onPrintIconClick(): void {
        this.scheduleObj.print();
    }

    // openRemoveNonWorkingHours() {
    //     var paramData = {
    //         coworkerList: this.coWokerList,
    //         selectedNonworkingStatus: this.selectedNonworkingStatus
    //     }
    //     const dialogRef = this.dialog.open(RemoveNonWorkingHourPopupComponent, {
    //         height: '70%',
    //         width: '70%',
    //         data: paramData
    //     });
    //     dialogRef.afterClosed().subscribe(result => {
    //         if (result) {
    //             this.moveToScreen(result);
    //         }
    //     });
    // }

    showPlanningTime(flag) {
        this.isShowTime = flag;
        if (this.isShowTime)
            document.documentElement.style.setProperty('--hide-show-time', 'block');
        else
            document.documentElement.style.setProperty('--hide-show-time', 'none');
    }

    //END
    reset() {
        this.idle.watch();
        this.idleState = 'Started.';
        this.timedOut = false;
    }

    ngOnDestroy() {
        this._fuseSidebarService.getSidebar('navbar').unfold();
    }

    showList(value) {
        if (value == "projectList") {
            this.isShowInquiryList = false;
            this.isShowProjectList = true;
        }
        else {
            this.isShowInquiryList = true;
            this.isShowProjectList = false;
        }
    }

    selectInquiryEventHandler($event: any) {
        this.createInquiryEvent($event);
    }

    createInquiryEvent(data) {
        var inquiryId = data;
        data = {};
        data.startDate = new Date();
        data.endDate = new Date();
        data.status = 1;
        data.shiftType = 4;
        data.startHour = "14";
        data.startMinute = "00";
        data.endHour = "15";
        data.endMinute = "00";
        data.isPauseTime = false;
        data.inquiryId = inquiryId;
        // if (this.roleName == "Admin") {
        //     data.leaveStatus = true;
        // } else {
        //     data.leaveStatus = false;
        // }
        var paramData = {
            inquiryInfo: data,
            coworkerList: this.coWokerList,
            holidayList: this.holidayList,
            isComingFromEditMode: false,
        }
        const dialogRef = this.dialog.open(CalenderInquiryWorkingHourPopupComponent, {
            height: '70%',
            width: '70%',
            data: paramData
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.moveToScreen(result)
            }
        });
    }

    OpenQuestionSetting() {
        const dialogRef = this.dialog.open(QuestionPopupComponent, {
            height: '70%',
            width: '60%',
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {

            }
        });
    }

    calculateWorkHors() {
        let startDate: Date = this.scheduleObj.activeView.startDate();
        let endDate: Date = this.scheduleObj.activeView.endDate();
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(0, 0, 0, 0);
        var resultWorkingHoursData = this.workHoursList.filter(function (a) {
            return new Date(a.date) >= startDate && new Date(a.date) <= endDate
        });
        // var resultNonworkingHoursData = this.workHoursList.filter(function (a) {
        //     return a.isWorking == false && new Date(a.date) >= startDate && new Date(a.date) <= endDate
        // });
        for (var i = 0; i < this.resourceDataSource.length; i++) {

            if (this.resourceDataSource[i].id && this.resourceDataSource[i].id != "0") {
                var workhourscal = resultWorkingHoursData.filter(it => it.CowrkerId == this.selectedcoWorkerIds[i]).reduce((sum, current) => sum + current.hours, 0);
                //var nonworkhourscal = resultNonworkingHoursData.filter(it => it.CowrkerId == this.selectedcoWorkerIds[i]).reduce((sum, current) => sum + current.hours, 0);
                this.resourceDataSource[i].timer = workhourscal > 0 ? workhourscal.to : '';
                //this.resourceDataSource[i].nonworkingTimer = nonworkhourscal > 0 ? nonworkhourscal : '';
            }
        }

    }

    getMonthDateRange(year, month) {
        var startDate = moment([year, month - 1]);
        var endDate = moment(startDate).endOf('month');
        return { start: startDate, end: endDate };
    }
    gotoMaterial() {
        this.isGotoMaterial = true;
    }

    defaultCoWorkerPopup() {
        const dialogRef = this.dialog.open(CalendarDefaultCoworkerComponent, {
            height: '80%',
            width: '80%',
        });
        dialogRef.afterClosed().subscribe(result => {
            // if (result != null && result != '') {
            // }
            var request = new RequestResourceWorkHours();
            request = this.coWorkersService.getRequestParameterForCalender();
            this.getDefaultCoworkers(request);
        });
    }

    getDefaultCoworkers(request) {
        var coWorkerIds = this.authService.getCoWorkerId();
        this.defaultCoworkerRequest.CoWorkerId = coWorkerIds;
        this.calanderService.GetRequisitionPlanningDefaultCoworker(this.defaultCoworkerRequest)
            .subscribe({
                next: (response: ResponseBase) => {
                    if (response && response.result) {
                        var defaultCoworkerObj = response.result;
                        if (defaultCoworkerObj && defaultCoworkerObj.length > 0) {
                            this.currentselectedcoWorkerObj.id = [];
                            for (let i = 0; i < defaultCoworkerObj.length; i++) {
                                this.currentselectedcoWorkerObj.id.push(defaultCoworkerObj[i].selectedID);
                            }

                            let loggedInId = this.currentselectedcoWorkerObj.id.filter(x => x == coWorkerIds);
                            if (loggedInId.length == 0) {
                                this.currentselectedcoWorkerObj.id.push(coWorkerIds);
                            }
                            this.selectedcoWorkerIds = this.currentselectedcoWorkerObj.id;
                        }
                    }
                    this.getCalenderData(request);
                },
                error: err => { this.showSpinner = false },
                complete: () => { }
            });
    }

    onCellDoubleClick(event) {
        var ispastday = false;
        if (new Date().setHours(0, 0, 0, 0) == new Date(event.startTime).setHours(0, 0, 0, 0))
            ispastday = false;
        else
            ispastday = moment(event.startTime, "YYYY-MM-DD").isBefore();
        if (!ispastday) {
            const dialogRef = this.dialog.open(ClickPlanPopupComponent, {
                height: "18%",
                width: "18%",
                data: event
            });

            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    if (result == 1) {
                        var coWorker = {
                            coWorkerId: this.coworkerDetailForDoubleClick.CowrkerId[0],
                        }
                        localStorage.setItem("doubleClickCalanderDetail", JSON.stringify(coWorker));
                        this.createEvent(null, event);
                    } else if (result == 2) {
                        var details = {
                            coWorkerId: this.coworkerDetailForDoubleClick.CowrkerId[0],
                            startDate: event.startTime,
                            endDate: event.endTime
                        }
                        localStorage.setItem("doubleClickCalanderDetail", JSON.stringify(details));
                        this.drawerObj.toggle();
                    } else {

                    }
                }
            })
        }
    }

    CopyCoworkerPlan(changeRecord) {
        this.workhoursService.CopyPlanWithDateRange<ResponseBase>(changeRecord)
            .subscribe({
                next: (response: ResponseBase) => {
                    var result = {
                        updateSchedule: true,
                    }
                    this.moveToScreen(result);
                    this.translation.get("Message.CopySuccessfully").subscribe({
                        next: (res) => {
                            this.notificationsService.success(res);
                        },
                        error: err => {
                        }
                    });
                    if(result && changeRecord){
                        changeRecord.tabId = this.tabId;
                        changeRecord.startDate = changeRecord[0];
                        this.sendPlanningMessage(JSON.stringify([{...changeRecord}]))
                    }


                },
                error: err => {
                    this.translation.get("Message.CopyError").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                            this.commonService.playFailedTone();
                            //args.cancel = true;
                        },
                        error: err => {
                            // args.cancel = true;
                        }
                    });
                },
                complete: () => {
                }
            });
    }

    deleteNonWorkingHourWithDateSlection(workHoursItem) {
        //this.showSpinner = true;
        this.workhoursService.deleteNonWorkingHourWithDateSlection<ResponseBase>(workHoursItem)
            .subscribe({
                next: (response: ResponseBase) => {
                    //this.scheduleObj.deleteEvent(Id)
                    var tempEventData = [];
                    if (this.scheduleObj) {
                        tempEventData = this.scheduleObj.eventsData;
                        if (tempEventData.length > 0) {
                            tempEventData.filter(item1 => {
                                this.scheduleObj.deleteEvent(item1.Id);
                            })
                        }
                    }
                    setTimeout(() => {
                        this.getCalenderData(this.requestResourceWorkHours);
                    }, 100);
                },
                error: err => { },
                complete: () => {

                }
            });
    }

    refreshCalendarData() {
        var request = new RequestResourceWorkHours();
        request = this.coWorkersService.getRequestParameterForCalender();
        moment.locale('da');
        let tempDate = this.planningStartDate ? new Date(moment(this.planningStartDate).format("YYYY-MM-DD")) : new Date();
        const firstDayOfWeek: Date = getWeekFirstDate(tempDate, this.weekFirstDay as number);
        let startDate: Date = firstDayOfWeek;
        this.selectedWeekNumber = moment(firstDayOfWeek).week().toString();
        const endDate: Date = addDays(new Date(startDate.getTime()), 6);
        request.StartDate = moment(startDate).format("YYYY-MM-DD");
        request.EndDate = moment(endDate).format("YYYY-MM-DD");
        this.selectedDate = new Date(startDate);
    }

    sendPlanningMessage(data: any): void {
        this.signalRService.sendPlanningMessage(data);
    }
    private subscribeToEvents(): void {
        this.signalRService.planningMessageReceived.subscribe((data: any) => {
            this._ngZone.run(() => {
                if (data && this._router.url == "/planning") {
                    const tempData = JSON.parse(data);
                    //var coWorkerId = this.authService.getCoWorkerId();
                    //var addedPlaning = tempData.filter(x => x.workerId == coWorkerId);
                    //addedPlaning[0].createdBy != coWorkerId
                    if (tempData && tempData.length > 0 && (this.tabId != tempData[0].tabId)) {
                        // var refreshMessage = ""
                        // this.translation.get("Message.RefreshPlanningMessage").subscribe({
                        //     next: (res) => {
                        //         refreshMessage = res.replace("@StartDate", moment(tempData[0].startDate).format("DD-MM-YYYY")).replace("@EndDate", moment(tempData[0].endDate).format("DD-MM-YYYY"));
                        //     }, error: err => { }
                        // });
                        // const dialogRef = this.dialog.open(AlertMessageModelComponent, {
                        //     height: '200px',
                        //     data: { Header: refreshMessage }
                        // });
                        // dialogRef.afterClosed().subscribe(result => {
                        //     if (result) {
                        //         this.planningStartDate = moment(tempData[0].startDate).format("YYYY-MM-DD")
                        //         this.refreshCalendarData();
                        //     }
                        // });

                        this.planningStartDate = moment(tempData[0].startDate).format("YYYY-MM-DD")
                        if(tempData[0].action && tempData[0].action == "delete"){
                            this.requestResourceWorkHours.EndDate = this.requestResourceWorkHours.StartDate;
                            this.getCalenderData(this.requestResourceWorkHours);
                        }
                        else
                            this.refreshCalendarData();
                    }
                }
            });
        });
    }

    gotoMap() {
        this.isShowMap = true;
    }

    GetCompanyInformation() {
        this.companyInfoService.GetCompanyInformationData<ResponseBase>()
            .subscribe((response: ResponseBase) => {
                if (response) {
                    this.companyInfoData = response.result;
                }
            },
                (err: any) => {
                    this.translate.get("MESSAGES.Failed").subscribe({
                        next: (res) => {
                            this.notificationsService.error(res);
                        }, error: err => { }
                    });
                },
                () => {
                });
    }

    showDirection(data) {
        //open popup
        const dialogRef = this.dialog.open(GoogleMapDirectionComponent, {
            width: '80%',
            height: '80%',
            disableClose: true,
            data: {
                Address: data.reqAddress,
                ZipCode: data.reqPostNumber,
                City: data.reqCity,
                SourceAdd: this.companyInfoData,
                autoSearchOnOpen: true,
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            this.isShowMap = false;
        });
    }

    // onCalendarCreated () {
    //         setTimeout((e) => {
    //         let container = document.querySelector(".e-schedule-toolbar-container");
    //         let calendar = container.querySelector(".e-calendar");
    //         if (calendar) {
    //             document.querySelector(
    //             ".e-calendar"
    //             ).ej2_instances[0].weekNumber = true;
    //             document.querySelector(".e-calendar").ej2_instances[0].weekRule =
    //             "FirstFourDayWeek";
    //         }
    //     }, 1);
    // }

    refreshProjectList() {
        this.projectservice.ProjectListChange.next(true);
    }

    openSwapCowrkerPopup(){
        const dialogRef = this.dialog.open(MovePlanningFromOneCowrkerToAnother, {
            width: '60%',
            height: '80%',
            disableClose: false,
            data: {
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result){
                //this.refreshCalendarData();
                this.moveToScreen(result)
            }
        });

    }

    bulkProjectPlanning(){
       const data = {
            startDate :  new Date(),
            endDate :  new Date(),
            status : 1,
            shiftType : 2
        }
        var paramData = {
            projectInfo: data,
            coworkerList: this.coWokerList,
            holidayList: this.holidayList,
            isComingFromEditMode: false,
            projectWithWorkHoursList: (this.scheduleObj && this.scheduleObj.eventsData ? this.scheduleObj.eventsData : null),
        }
        const dialogRef = this.dialog.open(BulkProjectPlanPopupComponent, {
            height: '80%',
            width: '80%',
            data: paramData
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result)
                this.moveToScreen(result);
            if(result && result.response){
                result.response[0].tabId = this.tabId;
                this.sendPlanningMessage(JSON.stringify(result.response))
            }
        });
    }

    getCustomerDefaultMaterials() {
        this.requestModel.Index = 1;
        this.requestModel.Limit = 100;
        this.requestModel.IsActive = false;
        this.requestModel.OrderBy = "ModifyDate DESC";
        //this.showSpinner = true;
        this.settingsService.GetCustomerDefaultMaterial<ResponseBase>(this.requestModel)
            .subscribe({
                next: (response: ResponseBase) => {
                    //this.showSpinner = false;
                    if (response != null && response.result) {
                        this.defaultMaterialList = response.result.map(m => m.vareID);
                    }
                    else {
                        this.defaultMaterialList = [];
                    }
                },
                error: err => {
                    //this.showSpinner = false;
                    this.defaultMaterialList = [];
                },
                complete: () => { }
            });
    }

    // testSignalR(){
    //     this.sendPlanningMessage('[{"id":"20240812114815342409","requisitionId":"20240628124646446992","workerId":"20190523114002219XXX","date":"2024-08-13T00:00:00","from":"07:00","to":"15:00","hours":7.5,"projectNumber":"225825","status":0,"projectId":"20240628124646378067","isWorking":true,"shiftType":3,"sagOpgaveID":"20240628124646446992","tid":8,"pauseTimer":0.5,"days":0,"newAddedCoworkerIds":"20190523114002219XXX","leaveStatus":0,"otherInfo":"{\\"IsNotifyToCustomer\\":false,\\"StartDate\\":\\"2024-08-13T00:00:00\\",\\"EndDate\\":\\"2024-08-13T00:00:00\\",\\"SagOpgaveID\\":\\"20240628124646446992\\",\\"SagID\\":\\"20240628124646378067\\",\\"BunchId\\":\\"20240812114815109270\\",\\"PlanningDates\\":\\"2024-08-13\\",\\"WeekendId\\":1}","createdBy":"20240805130536508488","isAddedToPlanning":false,"customerId":"2024062812455586788X","startDate":"2024-08-13T00:00:00","endDate":"2024-08-13T00:00:00","message":"Vi forventer at komme og male hos jer 13.08.2024 kl. 07:00","sendType":0,"isNotifyToCustomer":false,"isChecked":true,"projectBasedPlanning":false}]')
    // }
    search() {
        if(!this.requestResourceWorkHours.Search)
            this.selectedDate = new Date();       
        this.statusChangeCallCalenderData();
    }

}