export const locale = {
    lang: 'en',
    data: {
        'FILTERS': {
            'Scan': 'Scan',
            'All': 'ALL',
            "Materialtype": 'Material type',
        },
        'FIELDS': {
            'Supplier': 'Supplier',
            'None': 'None',
            'Material': 'Material',
            'Name': 'Name',
            'SupplierName': 'Supplier name',
            'ColorCode': 'Color code',
            'HoldingsQuantity': 'Holding Quantity',
            'SRNO': 'Sr nr.',
            "Delete": 'Delete',
            'Remark': 'Bemærkning',
            'Not-Found-Material': 'No material found',
            "Materialtype": 'Material type',
            'All': 'All',
            'Scan': 'Scan Barcode',
        },
        'BUTTONS': {
            'Add': 'Add',
            'BACK': 'Back',
            'Ok': 'Save',
        },
        'MESSAGES': {
            'Barcode': 'Barcode already exist.',
            'NORECORD': 'No Material Found',
            'NoBarcodeAttachedToMaterial': 'No barcode attached to any material.',
        },
    }
};