import { NgModule } from "@angular/core";
import { CarInformationsListComponent } from './car-informations.component';
import { NewCarInformationComponent } from './new-car-information/new-car-information.component';
import { CarInformationsService } from './car-informations.service';
import { SharedModule } from 'app/core/modules/shared.module';
import { RouterModule } from '@angular/router';
import { NgxMaskModule } from 'ngx-mask';
import { DatePickerModule, DateTimePickerModule } from '@syncfusion/ej2-angular-calendars';
import { SetReminderModelComponent } from "./set-reminder-model/set-reminder-model.component";
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts'; 
import { CustomCurrencyMaskConfig } from "app/core/constant/CurrencyMaskConfiguartion";
import { NgxCurrencyModule } from "ngx-currency";

const routes = [
    {
        path: 'carinformations',
        component: CarInformationsListComponent
    },
    {
        path: 'carinformations/add',
        component: NewCarInformationComponent,
    },
    {
        path: 'carinformations/edit/:id',
        component: NewCarInformationComponent,
    }
];

@NgModule({
    declarations: [
        CarInformationsListComponent,
        NewCarInformationComponent,
        SetReminderModelComponent
    ],
    imports: [
        SharedModule,
        DatePickerModule,
        DateTimePickerModule,
        RouterModule.forChild(routes),
        NgxMaskModule.forRoot(),
        KeyboardShortcutsModule.forRoot(),
        NgxCurrencyModule.forRoot(CustomCurrencyMaskConfig),
    ],
    entryComponents: [
        CarInformationsListComponent,
        SetReminderModelComponent,
    ],
    exports: [
        NewCarInformationComponent,
        SetReminderModelComponent
    ],
    providers: [
        CarInformationsService
    ]
})

export class CarInformationsModule {

}